'use strict';


// **********************************************************
//
// Error design
//
// for errSeverity modelled off https://www.tutorialspoint.com/log4j/log4j_logging_levels.htm :
// 
// Debug (for debugging details),
// Info (users login/out, and other metrics),
// Warn (foreseeable error type such as invalid username/password when signing in),
// Error(unexpected failure, maybe due to data already deleted, or user already exists when expected it not to.  etc..),
// Fatal(cannot continue such as app misconfiguration, or no network, or invalid data decoding, and tell user as much))
//
//Errors in sendToElm: {code: "InvalidParameters", severity: "Error", message: "Invalid parameters", details: "user 123 with password 5456 failed with original errCode from aws-amplify of abc"}
// **********************************************************


export const ERROR_TYPE = {
    severity: {
	Fatal: "Fatal",
	Error: "Error",
	Warn: "Warn",
	Info: "Info",
	Debug: "Debug",
    }
 };

export const LIMIT_HSTRY_LENGTH = 5000
export const LIMIT_SCHOOLS_LENGTH = 9999


export const INTEROP_TAGS = {


    //JTE = A message from Javascript To Elm
    //ETJ = a message from ElmToJavascript
    	//EntriesChangedJTE: "EntriesChangedJTE",
    SignInJTE: "SignInJTE",
    SignInETJ: "SignInETJ",
    //
    ForgotPasswordJTE: "ForgotPasswordJTE",
    ForgotPasswordETJ: "ForgotPasswordETJ",
    //
    ForgotPasswordSubmitJTE: "ForgotPasswordSubmitJTE",
    ForgotPasswordSubmitETJ: "ForgotPasswordSubmitETJ",
    //
    SignOutJTE: "SignOutJTE",
    SignOutETJ: "SignOutETJ",
    //
    MeJTE: "MeJTE",
    MeETJ: "MeETJ",
    //
    GetVariantSelection_Mjd5_JTE: "GetVariantSelection_Mjd5_JTE",
    GetVariantSelection_Mjd5_ETJ: "GetVariantSelection_Mjd5_ETJ",
    //
    GetVariantSelection_ShowVariant_JTE: "GetVariantSelection_ShowVariant_JTE",
    GetVariantSelection_ShowVariant_ETJ: "GetVariantSelection_ShowVariant_ETJ",
    //
    GetVariantSelection_ShowWorksheets_JTE: "GetVariantSelection_ShowWorksheets_JTE",
    GetVariantSelection_ShowWorksheets_ETJ: "GetVariantSelection_ShowWorksheets_ETJ",
    //
    GetVariantIdsJTE: "GetVariantIdsJTE",
    GetVariantIdsETJ: "GetVariantIdsETJ",
    //
    ConstructNodeForSyllabusJTE: "ConstructNodeForSyllabusJTE",
    ConstructNodeForSyllabusETJ: "ConstructNodeForSyllabusETJ",
    //
    GetSyllabusesJTE: "GetSyllabusesJTE",
    GetSyllabusesETJ: "GetSyllabusesETJ",
    //UpdateTeacherJTE: "UpdateTeacherJTE",
    //MeETJ: "MeETJ",
    //
    InteropFailureJTE: "InteropFailureJTE",
    //
    LogErrorETJ: "LogErrorETJ",

}
