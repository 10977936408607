'use strict';

if (ISDEBUG) { console.log("napOnlBackendAccess.js: Top of script"); }

import * as S from 'sanctuary';
import * as $ from 'sanctuary-def';

import {chain} from 'fluture/index.js'


import { ERROR_TYPE, INTEROP_TAGS } from './Constants.js'
import { signIn, forgotPassword, forgotPasswordSubmit, signOut } from './SignInOut.js'
import { getMe } from './Me.js'
import { getVariantIds, getVariantSelection, constructNodeForSyllabus, getSyllabuses } from './PursBundleInterop.js'

//import { getMe2 } from '../../output/InteropPurs.GraphQL';



//msg.tag is valid if this is called
const logError = (msg) => {

    //This may be used to send to amplify analytics debug, warn, etc.. info.
    
    if (ISDEBUG) {console.log(`logError: tag: ${msg.tag} with payload:`, msg.payload)}
    
    //app.ports.infoForElm.send({ tag: "EntriesChanged", payload: [{id: 1, content: "my content for 1"}] });    

    //msg.data is expected to be a straight string
    // let msgToLog = S.pipe([
    // 	S.get (S.is ($.String)) ('data'), //Maybe String
    // 	S.fromMaybe ("No further details were found in msg.data") //String
    // ])(msg);

    // console.log(`logError: tag: ${msg.tag} with data:`, msgToLog);
}

const handleMsgsReceivedFromElm = sendToElm => msg => {
    if (ISDEBUG) { console.log("NapOnlBackendAccess.js: handleMsgsReceivedFromElm: ", msg); }

    // const tagToFnDecode = {
    // 	"LogError": logError,
    // 	"SignIn": signIn
    // }

    let tagToFnDecode = {}
    tagToFnDecode[INTEROP_TAGS.LogErrorETJ] = logError
    tagToFnDecode[INTEROP_TAGS.SignInETJ] = signIn (sendToElm(INTEROP_TAGS.SignInJTE))
    tagToFnDecode[INTEROP_TAGS.ForgotPasswordETJ] = forgotPassword (sendToElm(INTEROP_TAGS.ForgotPasswordJTE))
    tagToFnDecode[INTEROP_TAGS.ForgotPasswordSubmitETJ] = forgotPasswordSubmit (sendToElm(INTEROP_TAGS.ForgotPasswordSubmitJTE))
    tagToFnDecode[INTEROP_TAGS.SignOutETJ] = signOut (sendToElm(INTEROP_TAGS.SignOutJTE))
    tagToFnDecode[INTEROP_TAGS.MeETJ] = getMe (sendToElm(INTEROP_TAGS.MeJTE))
    tagToFnDecode[INTEROP_TAGS.GetVariantSelection_Mjd5_ETJ] = getVariantSelection (sendToElm(INTEROP_TAGS.GetVariantSelection_Mjd5_JTE))
    tagToFnDecode[INTEROP_TAGS.GetVariantSelection_ShowVariant_ETJ] = getVariantSelection (sendToElm(INTEROP_TAGS.GetVariantSelection_ShowVariant_JTE))
    tagToFnDecode[INTEROP_TAGS.GetVariantSelection_ShowWorksheets_ETJ] = getVariantSelection (sendToElm(INTEROP_TAGS.GetVariantSelection_ShowWorksheets_JTE))
    tagToFnDecode[INTEROP_TAGS.GetVariantIdsETJ] = getVariantIds (sendToElm(INTEROP_TAGS.GetVariantIdsJTE))
    tagToFnDecode[INTEROP_TAGS.ConstructNodeForSyllabusETJ] = constructNodeForSyllabus (sendToElm(INTEROP_TAGS.ConstructNodeForSyllabusJTE))
    tagToFnDecode[INTEROP_TAGS.GetSyllabusesETJ] = getSyllabuses (sendToElm(INTEROP_TAGS.GetSyllabusesJTE))

    const msgIsValidFormat = (msg_) => {
	let hasTag = S.isJust (S.get (S.is ($.String)) ('tag') (msg_));
	let hasPayload = S.isJust (S.get (S.is ($.Any)) ('payload') (msg_));
	return (S.and (hasTag) (hasPayload));
    }

    const handleDataHandlerNotImplemented = ({tag: tag, payload: payload}) => {
	sendToElm (INTEROP_TAGS.JTE.InteropFailureJTE) ({
	    code: "InteropHandlerNotImplementedInJs",
	    severity: ERROR_TYPE.severity.Fatal,
	    message: "There is a problem with the application configuration that means we cannot log you in.  Please report this message to SmarterMaths (support@smartermaths.com.au) and we'll get it fixed asap!",
	    details: `A handler for the tag ${tag} is not implemented in the js side of the app with payload: ${JSON.stringify(payload)}`
	});
    }
    
    const dataHandlerFn = (msg_) => {
	// using msg_ to get the tag, find the function to run, and then run it, again passing msg_ for function to use the data!
	const fnToRun = S.pipe([
	    S.get (S.is ($.String)) ('tag'), //Maybe String
	    S.chain (_ => S.get (S.is ($.AnyFunction)) (_) (tagToFnDecode)), //Maybe Fn
	    S.fromMaybe (handleDataHandlerNotImplemented) //Fn
	]) (msg_)

	return fnToRun(msg_)
    }

    const invalidMsgFields = (msg) => sendToElm (INTEROP_TAGS.JTE.InteropFailureJTE) ({
	code: "InvalidMessageFieldsPassedToJs",
	severity: ERROR_TYPE.severity.Fatal,
	message: "There is a problem with the application configuration that means we cannot log you in.  Please report this message to SmarterMaths (support@smartermaths.com.au) and we'll get it fixed asap!",
	details: `An invalid request was made from elm to js with the following message format [${JSON.stringify(msg)}]`
    });

    
    //The input msg params (should contain a tag and a data field) will be validated here once, so when call functions, can know msg.payload exists
    S.ifElse (msgIsValidFormat) (dataHandlerFn) (invalidMsgFields) (msg);        
}


export const initNapOnlBackendAccess = app => {
    if (ISDEBUG) { console.log("napOnlBackendAccess.js: inside initNapOnlBackendAcess"); }

    const sendToElm = tag => payload => {
	// {tag: INTEROP_TAG.JTE.xyz, payload: ... }
	app.ports.infoForElm.send({tag: tag, payload: payload });
    }

    app.ports.infoForOutside.subscribe(handleMsgsReceivedFromElm (sendToElm));
}
