// Generated by purs bundle 0.14.9
var PS = {};
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Alt"] = $PS["Control.Alt"] || {};
  var exports = $PS["Control.Alt"];
  var alt = function (dict) {
      return dict.alt;
  };
  exports["alt"] = alt;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayApply = function (fs) {
    return function (xs) {
      var l = fs.length;
      var k = xs.length;
      var result = new Array(l*k);
      var n = 0;
      for (var i = 0; i < l; i++) {
        var f = fs[i];
        for (var j = 0; j < k; j++) {
          result[n++] = f(xs[j]);
        }
      }
      return result;
    };
  };
})(PS["Control.Apply"] = PS["Control.Apply"] || {});
(function(exports) {
  "use strict";

  exports.arrayMap = function (f) {
    return function (arr) {
      var l = arr.length;
      var result = new Array(l);
      for (var i = 0; i < l; i++) {
        result[i] = f(arr[i]);
      }
      return result;
    };
  };
})(PS["Data.Functor"] = PS["Data.Functor"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Function"] = $PS["Data.Function"] || {};
  var exports = $PS["Data.Function"];
  var flip = function (f) {
      return function (b) {
          return function (a) {
              return f(a)(b);
          };
      };
  };
  var $$const = function (a) {
      return function (v) {
          return a;
      };
  };
  exports["flip"] = flip;
  exports["const"] = $$const;
})(PS);
(function(exports) {
  "use strict";

  exports.unit = {};
})(PS["Data.Unit"] = PS["Data.Unit"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Unit"] = $PS["Data.Unit"] || {};
  var exports = $PS["Data.Unit"];
  var $foreign = $PS["Data.Unit"];
  exports["unit"] = $foreign.unit;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Functor"] = $PS["Data.Functor"] || {};
  var exports = $PS["Data.Functor"];
  var $foreign = $PS["Data.Functor"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];                  
  var map = function (dict) {
      return dict.map;
  };
  var $$void = function (dictFunctor) {
      return map(dictFunctor)(Data_Function["const"](Data_Unit.unit));
  };
  var functorArray = {
      map: $foreign.arrayMap
  };
  exports["map"] = map;
  exports["void"] = $$void;
  exports["functorArray"] = functorArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Apply"] = $PS["Control.Apply"] || {};
  var exports = $PS["Control.Apply"];
  var $foreign = $PS["Control.Apply"];
  var Data_Functor = $PS["Data.Functor"];
  var applyArray = {
      apply: $foreign.arrayApply,
      Functor0: function () {
          return Data_Functor.functorArray;
      }
  };
  var apply = function (dict) {
      return dict.apply;
  };
  exports["apply"] = apply;
  exports["applyArray"] = applyArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Applicative"] = $PS["Control.Applicative"] || {};
  var exports = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Unit = $PS["Data.Unit"];                  
  var pure = function (dict) {
      return dict.pure;
  };
  var when = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (v) {
                  return v1;
              };
              if (!v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 61, column 1 - line 61, column 63): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var liftA1 = function (dictApplicative) {
      return function (f) {
          return function (a) {
              return Control_Apply.apply(dictApplicative.Apply0())(pure(dictApplicative)(f))(a);
          };
      };
  };
  exports["pure"] = pure;
  exports["liftA1"] = liftA1;
  exports["when"] = when;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayBind = function (arr) {
    return function (f) {
      var result = [];
      for (var i = 0, l = arr.length; i < l; i++) {
        Array.prototype.push.apply(result, f(arr[i]));
      }
      return result;
    };
  };
})(PS["Control.Bind"] = PS["Control.Bind"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Bind"] = $PS["Control.Bind"] || {};
  var exports = $PS["Control.Bind"];
  var $foreign = $PS["Control.Bind"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Function = $PS["Data.Function"];
  var bindArray = {
      bind: $foreign.arrayBind,
      Apply0: function () {
          return Control_Apply.applyArray;
      }
  };
  var bind = function (dict) {
      return dict.bind;
  };
  var bindFlipped = function (dictBind) {
      return Data_Function.flip(bind(dictBind));
  };
  var composeKleisliFlipped = function (dictBind) {
      return function (f) {
          return function (g) {
              return function (a) {
                  return bindFlipped(dictBind)(f)(g(a));
              };
          };
      };
  };
  exports["bind"] = bind;
  exports["bindFlipped"] = bindFlipped;
  exports["composeKleisliFlipped"] = composeKleisliFlipped;
  exports["bindArray"] = bindArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Semigroupoid"] = $PS["Control.Semigroupoid"] || {};
  var exports = $PS["Control.Semigroupoid"];
  var semigroupoidFn = {
      compose: function (f) {
          return function (g) {
              return function (x) {
                  return f(g(x));
              };
          };
      }
  };
  var compose = function (dict) {
      return dict.compose;
  };
  exports["compose"] = compose;
  exports["semigroupoidFn"] = semigroupoidFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Category"] = $PS["Control.Category"] || {};
  var exports = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];                
  var identity = function (dict) {
      return dict.identity;
  };
  var categoryFn = {
      identity: function (x) {
          return x;
      },
      Semigroupoid0: function () {
          return Control_Semigroupoid.semigroupoidFn;
      }
  };
  exports["identity"] = identity;
  exports["categoryFn"] = categoryFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Monad"] = $PS["Control.Monad"] || {};
  var exports = $PS["Control.Monad"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var ap = function (dictMonad) {
      return function (f) {
          return function (a) {
              return Control_Bind.bind(dictMonad.Bind1())(f)(function (f$prime) {
                  return Control_Bind.bind(dictMonad.Bind1())(a)(function (a$prime) {
                      return Control_Applicative.pure(dictMonad.Applicative0())(f$prime(a$prime));
                  });
              });
          };
      };
  };
  exports["ap"] = ap;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Monad.Error.Class"] = $PS["Control.Monad.Error.Class"] || {};
  var exports = $PS["Control.Monad.Error.Class"];                
  var throwError = function (dict) {
      return dict.throwError;
  };
  exports["throwError"] = throwError;
})(PS);
(function(exports) {
  "use strict";

  exports.showIntImpl = function (n) {
    return n.toString();
  };

  exports.showStringImpl = function (s) {
    var l = s.length;
    return "\"" + s.replace(
      /[\0-\x1F\x7F"\\]/g, // eslint-disable-line no-control-regex
      function (c, i) {
        switch (c) {
          case "\"":
          case "\\":
            return "\\" + c;
          case "\x07": return "\\a";
          case "\b": return "\\b";
          case "\f": return "\\f";
          case "\n": return "\\n";
          case "\r": return "\\r";
          case "\t": return "\\t";
          case "\v": return "\\v";
        }
        var k = i + 1;
        var empty = k < l && s[k] >= "0" && s[k] <= "9" ? "\\&" : "";
        return "\\" + c.charCodeAt(0).toString(10) + empty;
      }
    ) + "\"";
  };

  exports.cons = function (head) {
    return function (tail) {
      return [head].concat(tail);
    };
  };

  exports.join = function (separator) {
    return function (xs) {
      return xs.join(separator);
    };
  };
})(PS["Data.Show"] = PS["Data.Show"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Symbol"] = $PS["Data.Symbol"] || {};
  var exports = $PS["Data.Symbol"];                  
  var SProxy = (function () {
      function SProxy() {

      };
      SProxy.value = new SProxy();
      return SProxy;
  })();
  var reflectSymbol = function (dict) {
      return dict.reflectSymbol;
  };
  exports["reflectSymbol"] = reflectSymbol;
  exports["SProxy"] = SProxy;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeGet = function (label) {
    return function (rec) {
      return rec[label];
    };
  };
})(PS["Record.Unsafe"] = PS["Record.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Record.Unsafe"] = $PS["Record.Unsafe"] || {};
  var exports = $PS["Record.Unsafe"];
  var $foreign = $PS["Record.Unsafe"];
  exports["unsafeGet"] = $foreign.unsafeGet;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Type.Proxy"] = $PS["Type.Proxy"] || {};
  var exports = $PS["Type.Proxy"];
  var $$Proxy = (function () {
      function $$Proxy() {

      };
      $$Proxy.value = new $$Proxy();
      return $$Proxy;
  })();
  exports["Proxy"] = $$Proxy;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Show"] = $PS["Data.Show"] || {};
  var exports = $PS["Data.Show"];
  var $foreign = $PS["Data.Show"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var showString = {
      show: $foreign.showStringImpl
  };
  var showRecordFieldsNil = {
      showRecordFields: function (v) {
          return function (v1) {
              return [  ];
          };
      }
  };
  var showRecordFields = function (dict) {
      return dict.showRecordFields;
  };
  var showRecord = function () {
      return function (dictShowRecordFields) {
          return {
              show: function (record) {
                  var v = showRecordFields(dictShowRecordFields)(Type_Proxy["Proxy"].value)(record);
                  if (v.length === 0) {
                      return "{}";
                  };
                  return $foreign.join(" ")([ "{", $foreign.join(", ")(v), "}" ]);
              }
          };
      };
  };
  var showInt = {
      show: $foreign.showIntImpl
  };
  var show = function (dict) {
      return dict.show;
  };
  var showRecordFieldsCons = function (dictIsSymbol) {
      return function (dictShowRecordFields) {
          return function (dictShow) {
              return {
                  showRecordFields: function (v) {
                      return function (record) {
                          var tail = showRecordFields(dictShowRecordFields)(Type_Proxy["Proxy"].value)(record);
                          var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                          var focus = Record_Unsafe.unsafeGet(key)(record);
                          return $foreign.cons($foreign.join(": ")([ key, show(dictShow)(focus) ]))(tail);
                      };
                  }
              };
          };
      };
  };
  exports["show"] = show;
  exports["showInt"] = showInt;
  exports["showString"] = showString;
  exports["showRecord"] = showRecord;
  exports["showRecordFieldsNil"] = showRecordFieldsNil;
  exports["showRecordFieldsCons"] = showRecordFieldsCons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Maybe"] = $PS["Data.Maybe"] || {};
  var exports = $PS["Data.Maybe"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Show = $PS["Data.Show"];                
  var Nothing = (function () {
      function Nothing() {

      };
      Nothing.value = new Nothing();
      return Nothing;
  })();
  var Just = (function () {
      function Just(value0) {
          this.value0 = value0;
      };
      Just.create = function (value0) {
          return new Just(value0);
      };
      return Just;
  })();
  var showMaybe = function (dictShow) {
      return {
          show: function (v) {
              if (v instanceof Just) {
                  return "(Just " + (Data_Show.show(dictShow)(v.value0) + ")");
              };
              if (v instanceof Nothing) {
                  return "Nothing";
              };
              throw new Error("Failed pattern match at Data.Maybe (line 216, column 1 - line 218, column 28): " + [ v.constructor.name ]);
          }
      };
  };
  var maybe = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Nothing) {
                  return v;
              };
              if (v2 instanceof Just) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Maybe (line 230, column 1 - line 230, column 51): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var isNothing = maybe(true)(Data_Function["const"](false));
  var isJust = maybe(false)(Data_Function["const"](true));
  var functorMaybe = {
      map: function (v) {
          return function (v1) {
              if (v1 instanceof Just) {
                  return new Just(v(v1.value0));
              };
              return Nothing.value;
          };
      }
  };
  var fromMaybe = function (a) {
      return maybe(a)(Control_Category.identity(Control_Category.categoryFn));
  };
  var fromJust = function () {
      return function (v) {
          if (v instanceof Just) {
              return v.value0;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 281, column 1 - line 281, column 46): " + [ v.constructor.name ]);
      };
  };
  var applyMaybe = {
      apply: function (v) {
          return function (v1) {
              if (v instanceof Just) {
                  return Data_Functor.map(functorMaybe)(v.value0)(v1);
              };
              if (v instanceof Nothing) {
                  return Nothing.value;
              };
              throw new Error("Failed pattern match at Data.Maybe (line 68, column 1 - line 70, column 30): " + [ v.constructor.name, v1.constructor.name ]);
          };
      },
      Functor0: function () {
          return functorMaybe;
      }
  };
  var bindMaybe = {
      bind: function (v) {
          return function (v1) {
              if (v instanceof Just) {
                  return v1(v.value0);
              };
              if (v instanceof Nothing) {
                  return Nothing.value;
              };
              throw new Error("Failed pattern match at Data.Maybe (line 126, column 1 - line 128, column 28): " + [ v.constructor.name, v1.constructor.name ]);
          };
      },
      Apply0: function () {
          return applyMaybe;
      }
  };
  var applicativeMaybe = {
      pure: Just.create,
      Apply0: function () {
          return applyMaybe;
      }
  };
  var altMaybe = {
      alt: function (v) {
          return function (v1) {
              if (v instanceof Nothing) {
                  return v1;
              };
              return v;
          };
      },
      Functor0: function () {
          return functorMaybe;
      }
  };
  exports["Nothing"] = Nothing;
  exports["Just"] = Just;
  exports["maybe"] = maybe;
  exports["fromMaybe"] = fromMaybe;
  exports["isJust"] = isJust;
  exports["isNothing"] = isNothing;
  exports["fromJust"] = fromJust;
  exports["functorMaybe"] = functorMaybe;
  exports["applicativeMaybe"] = applicativeMaybe;
  exports["altMaybe"] = altMaybe;
  exports["bindMaybe"] = bindMaybe;
  exports["showMaybe"] = showMaybe;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Either"] = $PS["Data.Either"] || {};
  var exports = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var Left = (function () {
      function Left(value0) {
          this.value0 = value0;
      };
      Left.create = function (value0) {
          return new Left(value0);
      };
      return Left;
  })();
  var Right = (function () {
      function Right(value0) {
          this.value0 = value0;
      };
      Right.create = function (value0) {
          return new Right(value0);
      };
      return Right;
  })();
  var functorEither = {
      map: function (f) {
          return function (m) {
              if (m instanceof Left) {
                  return new Left(m.value0);
              };
              if (m instanceof Right) {
                  return new Right(f(m.value0));
              };
              throw new Error("Failed pattern match at Data.Either (line 31, column 1 - line 31, column 52): " + [ m.constructor.name ]);
          };
      }
  };
  var either = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Left) {
                  return v(v2.value0);
              };
              if (v2 instanceof Right) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Either (line 208, column 1 - line 208, column 64): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var hush = either(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
  var applyEither = {
      apply: function (v) {
          return function (v1) {
              if (v instanceof Left) {
                  return new Left(v.value0);
              };
              if (v instanceof Right) {
                  return Data_Functor.map(functorEither)(v.value0)(v1);
              };
              throw new Error("Failed pattern match at Data.Either (line 70, column 1 - line 72, column 30): " + [ v.constructor.name, v1.constructor.name ]);
          };
      },
      Functor0: function () {
          return functorEither;
      }
  };
  var applicativeEither = {
      pure: Right.create,
      Apply0: function () {
          return applyEither;
      }
  };
  exports["Left"] = Left;
  exports["Right"] = Right;
  exports["either"] = either;
  exports["hush"] = hush;
  exports["functorEither"] = functorEither;
  exports["applyEither"] = applyEither;
  exports["applicativeEither"] = applicativeEither;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Monad.Except.Trans"] = $PS["Control.Monad.Except.Trans"] || {};
  var exports = $PS["Control.Monad.Except.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];                
  var ExceptT = function (x) {
      return x;
  };
  var withExceptT = function (dictFunctor) {
      return function (f) {
          return function (v) {
              var mapLeft = function (v1) {
                  return function (v2) {
                      if (v2 instanceof Data_Either.Right) {
                          return new Data_Either.Right(v2.value0);
                      };
                      if (v2 instanceof Data_Either.Left) {
                          return new Data_Either.Left(v1(v2.value0));
                      };
                      throw new Error("Failed pattern match at Control.Monad.Except.Trans (line 43, column 3 - line 43, column 32): " + [ v1.constructor.name, v2.constructor.name ]);
                  };
              };
              return Data_Functor.map(dictFunctor)(mapLeft(f))(v);
          };
      };
  };
  var runExceptT = function (v) {
      return v;
  };
  var mapExceptT = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var functorExceptT = function (dictFunctor) {
      return {
          map: function (f) {
              return mapExceptT(Data_Functor.map(dictFunctor)(Data_Functor.map(Data_Either.functorEither)(f)));
          }
      };
  };
  var monadExceptT = function (dictMonad) {
      return {
          Applicative0: function () {
              return applicativeExceptT(dictMonad);
          },
          Bind1: function () {
              return bindExceptT(dictMonad);
          }
      };
  };
  var bindExceptT = function (dictMonad) {
      return {
          bind: function (v) {
              return function (k) {
                  return Control_Bind.bind(dictMonad.Bind1())(v)(Data_Either.either((function () {
                      var $91 = Control_Applicative.pure(dictMonad.Applicative0());
                      return function ($92) {
                          return $91(Data_Either.Left.create($92));
                      };
                  })())(function (a) {
                      var v1 = k(a);
                      return v1;
                  }));
              };
          },
          Apply0: function () {
              return applyExceptT(dictMonad);
          }
      };
  };
  var applyExceptT = function (dictMonad) {
      return {
          apply: Control_Monad.ap(monadExceptT(dictMonad)),
          Functor0: function () {
              return functorExceptT(((dictMonad.Bind1()).Apply0()).Functor0());
          }
      };
  };
  var applicativeExceptT = function (dictMonad) {
      return {
          pure: (function () {
              var $93 = Control_Applicative.pure(dictMonad.Applicative0());
              return function ($94) {
                  return ExceptT($93(Data_Either.Right.create($94)));
              };
          })(),
          Apply0: function () {
              return applyExceptT(dictMonad);
          }
      };
  };
  var monadThrowExceptT = function (dictMonad) {
      return {
          throwError: (function () {
              var $103 = Control_Applicative.pure(dictMonad.Applicative0());
              return function ($104) {
                  return ExceptT($103(Data_Either.Left.create($104)));
              };
          })(),
          Monad0: function () {
              return monadExceptT(dictMonad);
          }
      };
  };
  exports["ExceptT"] = ExceptT;
  exports["runExceptT"] = runExceptT;
  exports["withExceptT"] = withExceptT;
  exports["mapExceptT"] = mapExceptT;
  exports["functorExceptT"] = functorExceptT;
  exports["applicativeExceptT"] = applicativeExceptT;
  exports["bindExceptT"] = bindExceptT;
  exports["monadThrowExceptT"] = monadThrowExceptT;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Identity"] = $PS["Data.Identity"] || {};
  var exports = $PS["Data.Identity"];              
  var Identity = function (x) {
      return x;
  };
  var functorIdentity = {
      map: function (f) {
          return function (m) {
              return f(m);
          };
      }
  };
  var applyIdentity = {
      apply: function (v) {
          return function (v1) {
              return v(v1);
          };
      },
      Functor0: function () {
          return functorIdentity;
      }
  };
  var bindIdentity = {
      bind: function (v) {
          return function (f) {
              return f(v);
          };
      },
      Apply0: function () {
          return applyIdentity;
      }
  };
  var applicativeIdentity = {
      pure: Identity,
      Apply0: function () {
          return applyIdentity;
      }
  };
  var monadIdentity = {
      Applicative0: function () {
          return applicativeIdentity;
      },
      Bind1: function () {
          return bindIdentity;
      }
  };
  exports["Identity"] = Identity;
  exports["functorIdentity"] = functorIdentity;
  exports["applicativeIdentity"] = applicativeIdentity;
  exports["bindIdentity"] = bindIdentity;
  exports["monadIdentity"] = monadIdentity;
})(PS);
(function(exports) {
  "use strict";

  // module Unsafe.Coerce

  exports.unsafeCoerce = function (x) {
    return x;
  };
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Unsafe.Coerce"] = $PS["Unsafe.Coerce"] || {};
  var exports = $PS["Unsafe.Coerce"];
  var $foreign = $PS["Unsafe.Coerce"];
  exports["unsafeCoerce"] = $foreign.unsafeCoerce;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Safe.Coerce"] = $PS["Safe.Coerce"] || {};
  var exports = $PS["Safe.Coerce"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var coerce = function () {
      return Unsafe_Coerce.unsafeCoerce;
  };
  exports["coerce"] = coerce;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Newtype"] = $PS["Data.Newtype"] || {};
  var exports = $PS["Data.Newtype"];
  var Safe_Coerce = $PS["Safe.Coerce"];
  var unwrap = Safe_Coerce.coerce;
  exports["unwrap"] = unwrap;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Monad.Except"] = $PS["Control.Monad.Except"] || {};
  var exports = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Newtype = $PS["Data.Newtype"];                
  var withExcept = Control_Monad_Except_Trans.withExceptT(Data_Identity.functorIdentity);
  var runExcept = (function () {
      var $0 = Data_Newtype.unwrap();
      return function ($1) {
          return $0(Control_Monad_Except_Trans.runExceptT($1));
      };
  })();
  exports["runExcept"] = runExcept;
  exports["withExcept"] = withExcept;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Monad.Reader"] = $PS["Control.Monad.Reader"] || {};
  var exports = $PS["Control.Monad.Reader"];
  var Data_Newtype = $PS["Data.Newtype"];                 
  var runReader = function (v) {
      var $2 = Data_Newtype.unwrap();
      return function ($3) {
          return $2(v($3));
      };
  };
  exports["runReader"] = runReader;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Monad.Reader.Class"] = $PS["Control.Monad.Reader.Class"] || {};
  var exports = $PS["Control.Monad.Reader.Class"];
  var ask = function (dict) {
      return dict.ask;
  };
  exports["ask"] = ask;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Monad.Reader.Trans"] = $PS["Control.Monad.Reader.Trans"] || {};
  var exports = $PS["Control.Monad.Reader.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];                
  var ReaderT = function (x) {
      return x;
  };
  var runReaderT = function (v) {
      return v;
  };
  var monadTransReaderT = {
      lift: function (dictMonad) {
          return function ($64) {
              return ReaderT(Data_Function["const"]($64));
          };
      }
  };
  var mapReaderT = function (f) {
      return function (v) {
          return function ($65) {
              return f(v($65));
          };
      };
  };
  var functorReaderT = function (dictFunctor) {
      return {
          map: (function () {
              var $66 = Data_Functor.map(dictFunctor);
              return function ($67) {
                  return mapReaderT($66($67));
              };
          })()
      };
  };
  var applyReaderT = function (dictApply) {
      return {
          apply: function (v) {
              return function (v1) {
                  return function (r) {
                      return Control_Apply.apply(dictApply)(v(r))(v1(r));
                  };
              };
          },
          Functor0: function () {
              return functorReaderT(dictApply.Functor0());
          }
      };
  };
  var bindReaderT = function (dictBind) {
      return {
          bind: function (v) {
              return function (k) {
                  return function (r) {
                      return Control_Bind.bind(dictBind)(v(r))(function (a) {
                          var v1 = k(a);
                          return v1(r);
                      });
                  };
              };
          },
          Apply0: function () {
              return applyReaderT(dictBind.Apply0());
          }
      };
  };
  var applicativeReaderT = function (dictApplicative) {
      return {
          pure: (function () {
              var $71 = Control_Applicative.pure(dictApplicative);
              return function ($72) {
                  return ReaderT(Data_Function["const"]($71($72)));
              };
          })(),
          Apply0: function () {
              return applyReaderT(dictApplicative.Apply0());
          }
      };
  };
  var monadReaderT = function (dictMonad) {
      return {
          Applicative0: function () {
              return applicativeReaderT(dictMonad.Applicative0());
          },
          Bind1: function () {
              return bindReaderT(dictMonad.Bind1());
          }
      };
  };
  var monadAskReaderT = function (dictMonad) {
      return {
          ask: Control_Applicative.pure(dictMonad.Applicative0()),
          Monad0: function () {
              return monadReaderT(dictMonad);
          }
      };
  };
  exports["runReaderT"] = runReaderT;
  exports["functorReaderT"] = functorReaderT;
  exports["applicativeReaderT"] = applicativeReaderT;
  exports["bindReaderT"] = bindReaderT;
  exports["monadTransReaderT"] = monadTransReaderT;
  exports["monadAskReaderT"] = monadAskReaderT;
})(PS);
(function(exports) {
  "use strict";

  exports.map_ = function (f) {
    return function (a) {
      return function () {
        return f(a());
      };
    };
  };

  exports.pure_ = function (a) {
    return function () {
      return a;
    };
  };

  exports.bind_ = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };

  exports.foreach = function (as) {
    return function (f) {
      return function () {
        for (var i = 0, l = as.length; i < l; i++) {
          f(as[i])();
        }
      };
    };
  };
})(PS["Control.Monad.ST.Internal"] = PS["Control.Monad.ST.Internal"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Monad.ST.Internal"] = $PS["Control.Monad.ST.Internal"] || {};
  var exports = $PS["Control.Monad.ST.Internal"];
  var $foreign = $PS["Control.Monad.ST.Internal"];
  var Control_Monad = $PS["Control.Monad"];
  var functorST = {
      map: $foreign.map_
  };
  var monadST = {
      Applicative0: function () {
          return applicativeST;
      },
      Bind1: function () {
          return bindST;
      }
  };
  var bindST = {
      bind: $foreign.bind_,
      Apply0: function () {
          return applyST;
      }
  };
  var applyST = {
      apply: Control_Monad.ap(monadST),
      Functor0: function () {
          return functorST;
      }
  };
  var applicativeST = {
      pure: $foreign.pure_,
      Apply0: function () {
          return applyST;
      }
  };
  exports["functorST"] = functorST;
  exports["applicativeST"] = applicativeST;
  exports["foreach"] = $foreign.foreach;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Monad.Trans.Class"] = $PS["Control.Monad.Trans.Class"] || {};
  var exports = $PS["Control.Monad.Trans.Class"];
  var lift = function (dict) {
      return dict.lift;
  };
  exports["lift"] = lift;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Control.Plus"] = $PS["Control.Plus"] || {};
  var exports = $PS["Control.Plus"];
  var empty = function (dict) {
      return dict.empty;
  };
  exports["empty"] = empty;
})(PS);
(function(exports) {
  "use strict";

  //------------------------------------------------------------------------------
  // Array creation --------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.range = function (start) {
    return function (end) {
      var step = start > end ? -1 : 1;
      var result = new Array(step * (end - start) + 1);
      var i = start, n = 0;
      while (i !== end) {
        result[n++] = i;
        i += step;
      }
      result[n] = i;
      return result;
    };
  };   

  //------------------------------------------------------------------------------
  // Array size ------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.length = function (xs) {
    return xs.length;
  };

  //------------------------------------------------------------------------------
  // Non-indexed reads -----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.unconsImpl = function (empty) {
    return function (next) {
      return function (xs) {
        return xs.length === 0 ? empty({}) : next(xs[0])(xs.slice(1));
      };
    };
  };

  //------------------------------------------------------------------------------
  // Indexed operations ----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.indexImpl = function (just) {
    return function (nothing) {
      return function (xs) {
        return function (i) {
          return i < 0 || i >= xs.length ? nothing :  just(xs[i]);
        };
      };
    };
  };

  exports.findMapImpl = function (nothing) {
    return function (isJust) {
      return function (f) {
        return function (xs) {
          for (var i = 0; i < xs.length; i++) {
            var result = f(xs[i]);
            if (isJust(result)) return result;
          }
          return nothing;
        };
      };
    };
  };

  exports.findIndexImpl = function (just) {
    return function (nothing) {
      return function (f) {
        return function (xs) {
          for (var i = 0, l = xs.length; i < l; i++) {
            if (f(xs[i])) return just(i);
          }
          return nothing;
        };
      };
    };
  };

  exports._updateAt = function (just) {
    return function (nothing) {
      return function (i) {
        return function (a) {
          return function (l) {
            if (i < 0 || i >= l.length) return nothing;
            var l1 = l.slice();
            l1[i] = a;
            return just(l1);
          };
        };
      };
    };
  };

  //------------------------------------------------------------------------------
  // Transformations -------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.reverse = function (l) {
    return l.slice().reverse();
  };

  exports.concat = function (xss) {
    if (xss.length <= 10000) {
      // This method is faster, but it crashes on big arrays.
      // So we use it when can and fallback to simple variant otherwise.
      return Array.prototype.concat.apply([], xss);
    }

    var result = [];
    for (var i = 0, l = xss.length; i < l; i++) {
      var xs = xss[i];
      for (var j = 0, m = xs.length; j < m; j++) {
        result.push(xs[j]);
      }
    }
    return result;
  };

  exports.filter = function (f) {
    return function (xs) {
      return xs.filter(f);
    };
  };

  exports.partition = function (f) {
    return function (xs) {
      var yes = [];
      var no  = [];
      for (var i = 0; i < xs.length; i++) {
        var x = xs[i];
        if (f(x))
          yes.push(x);
        else
          no.push(x);
      }
      return { yes: yes, no: no };
    };
  };

  //------------------------------------------------------------------------------
  // Sorting ---------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.sortByImpl = (function () {
    function mergeFromTo(compare, fromOrdering, xs1, xs2, from, to) {
      var mid;
      var i;
      var j;
      var k;
      var x;
      var y;
      var c;

      mid = from + ((to - from) >> 1);
      if (mid - from > 1) mergeFromTo(compare, fromOrdering, xs2, xs1, from, mid);
      if (to - mid > 1) mergeFromTo(compare, fromOrdering, xs2, xs1, mid, to);

      i = from;
      j = mid;
      k = from;
      while (i < mid && j < to) {
        x = xs2[i];
        y = xs2[j];
        c = fromOrdering(compare(x)(y));
        if (c > 0) {
          xs1[k++] = y;
          ++j;
        }
        else {
          xs1[k++] = x;
          ++i;
        }
      }
      while (i < mid) {
        xs1[k++] = xs2[i++];
      }
      while (j < to) {
        xs1[k++] = xs2[j++];
      }
    }

    return function (compare) {
      return function (fromOrdering) {
        return function (xs) {
          var out;

          if (xs.length < 2) return xs;

          out = xs.slice(0);
          mergeFromTo(compare, fromOrdering, out, xs.slice(0), 0, xs.length);

          return out;
        };
      };
    };
  })();

  //------------------------------------------------------------------------------
  // Subarrays -------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.slice = function (s) {
    return function (e) {
      return function (l) {
        return l.slice(s, e);
      };
    };
  };

  //------------------------------------------------------------------------------
  // Zipping ---------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.zipWith = function (f) {
    return function (xs) {
      return function (ys) {
        var l = xs.length < ys.length ? xs.length : ys.length;
        var result = new Array(l);
        for (var i = 0; i < l; i++) {
          result[i] = f(xs[i])(ys[i]);
        }
        return result;
      };
    };
  };

  //------------------------------------------------------------------------------
  // Partial ---------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.unsafeIndexImpl = function (xs) {
    return function (n) {
      return xs[n];
    };
  };
})(PS["Data.Array"] = PS["Data.Array"] || {});
(function(exports) {
  "use strict";

  exports.pushAll = function (as) {
    return function (xs) {
      return function () {
        return xs.push.apply(xs, as);
      };
    };
  };

  exports.unsafeFreeze = function (xs) {
    return function () {
      return xs;
    };
  };

  exports.unsafeThaw = function (xs) {
    return function () {
      return xs;
    };
  };
})(PS["Data.Array.ST"] = PS["Data.Array.ST"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Array.ST"] = $PS["Data.Array.ST"] || {};
  var exports = $PS["Data.Array.ST"];
  var $foreign = $PS["Data.Array.ST"];
  var push = function (a) {
      return $foreign.pushAll([ a ]);
  };
  exports["push"] = push;
  exports["unsafeFreeze"] = $foreign.unsafeFreeze;
  exports["unsafeThaw"] = $foreign.unsafeThaw;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Boolean"] = $PS["Data.Boolean"] || {};
  var exports = $PS["Data.Boolean"];
  var otherwise = true;
  exports["otherwise"] = otherwise;
})(PS);
(function(exports) {
  "use strict";

  var refEq = function (r1) {
    return function (r2) {
      return r1 === r2;
    };
  };

  exports.eqBooleanImpl = refEq;
  exports.eqIntImpl = refEq;   
  exports.eqCharImpl = refEq;
  exports.eqStringImpl = refEq;
})(PS["Data.Eq"] = PS["Data.Eq"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Eq"] = $PS["Data.Eq"] || {};
  var exports = $PS["Data.Eq"];
  var $foreign = $PS["Data.Eq"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var Type_Proxy = $PS["Type.Proxy"];
  var eqString = {
      eq: $foreign.eqStringImpl
  };
  var eqRowNil = {
      eqRecord: function (v) {
          return function (v1) {
              return function (v2) {
                  return true;
              };
          };
      }
  };
  var eqRecord = function (dict) {
      return dict.eqRecord;
  };
  var eqRec = function () {
      return function (dictEqRecord) {
          return {
              eq: eqRecord(dictEqRecord)(Type_Proxy["Proxy"].value)
          };
      };
  };
  var eqInt = {
      eq: $foreign.eqIntImpl
  };
  var eqChar = {
      eq: $foreign.eqCharImpl
  };
  var eqBoolean = {
      eq: $foreign.eqBooleanImpl
  };
  var eq = function (dict) {
      return dict.eq;
  };
  var eqRowCons = function (dictEqRecord) {
      return function () {
          return function (dictIsSymbol) {
              return function (dictEq) {
                  return {
                      eqRecord: function (v) {
                          return function (ra) {
                              return function (rb) {
                                  var tail = eqRecord(dictEqRecord)(Type_Proxy["Proxy"].value)(ra)(rb);
                                  var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                                  var get = Record_Unsafe.unsafeGet(key);
                                  return eq(dictEq)(get(ra))(get(rb)) && tail;
                              };
                          };
                      }
                  };
              };
          };
      };
  };
  var notEq = function (dictEq) {
      return function (x) {
          return function (y) {
              return eq(eqBoolean)(eq(dictEq)(x)(y))(false);
          };
      };
  };
  exports["eq"] = eq;
  exports["notEq"] = notEq;
  exports["eqInt"] = eqInt;
  exports["eqChar"] = eqChar;
  exports["eqString"] = eqString;
  exports["eqRec"] = eqRec;
  exports["eqRowNil"] = eqRowNil;
  exports["eqRowCons"] = eqRowCons;
})(PS);
(function(exports) {
  "use strict";

  exports.foldrArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = len - 1; i >= 0; i--) {
          acc = f(xs[i])(acc);
        }
        return acc;
      };
    };
  };

  exports.foldlArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = 0; i < len; i++) {
          acc = f(acc)(xs[i]);
        }
        return acc;
      };
    };
  };
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Monoid"] = $PS["Data.Monoid"] || {};
  var exports = $PS["Data.Monoid"];
  var mempty = function (dict) {
      return dict.mempty;
  };
  exports["mempty"] = mempty;
})(PS);
(function(exports) {
  "use strict";

  exports.concatArray = function (xs) {
    return function (ys) {
      if (xs.length === 0) return ys;
      if (ys.length === 0) return xs;
      return xs.concat(ys);
    };
  };
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Semigroup"] = $PS["Data.Semigroup"] || {};
  var exports = $PS["Data.Semigroup"];
  var $foreign = $PS["Data.Semigroup"];
  var semigroupArray = {
      append: $foreign.concatArray
  };
  var append = function (dict) {
      return dict.append;
  };
  exports["append"] = append;
  exports["semigroupArray"] = semigroupArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Foldable"] = $PS["Data.Foldable"] || {};
  var exports = $PS["Data.Foldable"];
  var $foreign = $PS["Data.Foldable"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];      
  var foldr = function (dict) {
      return dict.foldr;
  };
  var foldl = function (dict) {
      return dict.foldl;
  };
  var foldMapDefaultR = function (dictFoldable) {
      return function (dictMonoid) {
          return function (f) {
              return foldr(dictFoldable)(function (x) {
                  return function (acc) {
                      return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(acc);
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      };
  };
  var foldableArray = {
      foldr: $foreign.foldrArray,
      foldl: $foreign.foldlArray,
      foldMap: function (dictMonoid) {
          return foldMapDefaultR(foldableArray)(dictMonoid);
      }
  };
  exports["foldr"] = foldr;
  exports["foldl"] = foldl;
  exports["foldableArray"] = foldableArray;
})(PS);
(function(exports) {
  "use strict";

  var unsafeCompareImpl = function (lt) {
    return function (eq) {
      return function (gt) {
        return function (x) {
          return function (y) {
            return x < y ? lt : x === y ? eq : gt;
          };
        };
      };
    };
  };                                         
  exports.ordIntImpl = unsafeCompareImpl;   
  exports.ordStringImpl = unsafeCompareImpl;
  exports.ordCharImpl = unsafeCompareImpl;
})(PS["Data.Ord"] = PS["Data.Ord"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Ordering"] = $PS["Data.Ordering"] || {};
  var exports = $PS["Data.Ordering"];
  var LT = (function () {
      function LT() {

      };
      LT.value = new LT();
      return LT;
  })();
  var GT = (function () {
      function GT() {

      };
      GT.value = new GT();
      return GT;
  })();
  var EQ = (function () {
      function EQ() {

      };
      EQ.value = new EQ();
      return EQ;
  })();
  var eqOrdering = {
      eq: function (v) {
          return function (v1) {
              if (v instanceof LT && v1 instanceof LT) {
                  return true;
              };
              if (v instanceof GT && v1 instanceof GT) {
                  return true;
              };
              if (v instanceof EQ && v1 instanceof EQ) {
                  return true;
              };
              return false;
          };
      }
  };
  exports["LT"] = LT;
  exports["GT"] = GT;
  exports["EQ"] = EQ;
  exports["eqOrdering"] = eqOrdering;
})(PS);
(function(exports) {
  "use strict";

  exports.intSub = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x - y | 0;
    };
  };
})(PS["Data.Ring"] = PS["Data.Ring"] || {});
(function(exports) {
  "use strict";

  exports.intAdd = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x + y | 0;
    };
  };

  exports.intMul = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x * y | 0;
    };
  };
})(PS["Data.Semiring"] = PS["Data.Semiring"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Semiring"] = $PS["Data.Semiring"] || {};
  var exports = $PS["Data.Semiring"];
  var $foreign = $PS["Data.Semiring"];
  var zero = function (dict) {
      return dict.zero;
  };
  var semiringInt = {
      add: $foreign.intAdd,
      zero: 0,
      mul: $foreign.intMul,
      one: 1
  };
  var add = function (dict) {
      return dict.add;
  };
  exports["add"] = add;
  exports["zero"] = zero;
  exports["semiringInt"] = semiringInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Ring"] = $PS["Data.Ring"] || {};
  var exports = $PS["Data.Ring"];
  var $foreign = $PS["Data.Ring"];
  var Data_Semiring = $PS["Data.Semiring"];
  var sub = function (dict) {
      return dict.sub;
  };
  var ringInt = {
      sub: $foreign.intSub,
      Semiring0: function () {
          return Data_Semiring.semiringInt;
      }
  };
  var negate = function (dictRing) {
      return function (a) {
          return sub(dictRing)(Data_Semiring.zero(dictRing.Semiring0()))(a);
      };
  };
  exports["negate"] = negate;
  exports["ringInt"] = ringInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Ord"] = $PS["Data.Ord"] || {};
  var exports = $PS["Data.Ord"];
  var $foreign = $PS["Data.Ord"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Ring = $PS["Data.Ring"];
  var Data_Semiring = $PS["Data.Semiring"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var Type_Proxy = $PS["Type.Proxy"];
  var ordString = {
      compare: $foreign.ordStringImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
      Eq0: function () {
          return Data_Eq.eqString;
      }
  };
  var ordRecordNil = {
      compareRecord: function (v) {
          return function (v1) {
              return function (v2) {
                  return Data_Ordering.EQ.value;
              };
          };
      },
      EqRecord0: function () {
          return Data_Eq.eqRowNil;
      }
  };
  var ordInt = {
      compare: $foreign.ordIntImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
      Eq0: function () {
          return Data_Eq.eqInt;
      }
  };
  var ordChar = {
      compare: $foreign.ordCharImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
      Eq0: function () {
          return Data_Eq.eqChar;
      }
  };
  var compareRecord = function (dict) {
      return dict.compareRecord;
  };
  var ordRecord = function () {
      return function (dictOrdRecord) {
          return {
              compare: compareRecord(dictOrdRecord)(Type_Proxy["Proxy"].value),
              Eq0: function () {
                  return Data_Eq.eqRec()(dictOrdRecord.EqRecord0());
              }
          };
      };
  };
  var compare = function (dict) {
      return dict.compare;
  };
  var comparing = function (dictOrd) {
      return function (f) {
          return function (x) {
              return function (y) {
                  return compare(dictOrd)(f(x))(f(y));
              };
          };
      };
  };
  var greaterThanOrEq = function (dictOrd) {
      return function (a1) {
          return function (a2) {
              var v = compare(dictOrd)(a1)(a2);
              if (v instanceof Data_Ordering.LT) {
                  return false;
              };
              return true;
          };
      };
  };
  var max = function (dictOrd) {
      return function (x) {
          return function (y) {
              var v = compare(dictOrd)(x)(y);
              if (v instanceof Data_Ordering.LT) {
                  return y;
              };
              if (v instanceof Data_Ordering.EQ) {
                  return x;
              };
              if (v instanceof Data_Ordering.GT) {
                  return x;
              };
              throw new Error("Failed pattern match at Data.Ord (line 176, column 3 - line 179, column 12): " + [ v.constructor.name ]);
          };
      };
  };
  var min = function (dictOrd) {
      return function (x) {
          return function (y) {
              var v = compare(dictOrd)(x)(y);
              if (v instanceof Data_Ordering.LT) {
                  return x;
              };
              if (v instanceof Data_Ordering.EQ) {
                  return x;
              };
              if (v instanceof Data_Ordering.GT) {
                  return y;
              };
              throw new Error("Failed pattern match at Data.Ord (line 167, column 3 - line 170, column 12): " + [ v.constructor.name ]);
          };
      };
  };
  var ordRecordCons = function (dictOrdRecord) {
      return function () {
          return function (dictIsSymbol) {
              return function (dictOrd) {
                  return {
                      compareRecord: function (v) {
                          return function (ra) {
                              return function (rb) {
                                  var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                                  var left = compare(dictOrd)(Record_Unsafe.unsafeGet(key)(ra))(Record_Unsafe.unsafeGet(key)(rb));
                                  var $56 = Data_Eq.notEq(Data_Ordering.eqOrdering)(left)(Data_Ordering.EQ.value);
                                  if ($56) {
                                      return left;
                                  };
                                  return compareRecord(dictOrdRecord)(Type_Proxy["Proxy"].value)(ra)(rb);
                              };
                          };
                      },
                      EqRecord0: function () {
                          return Data_Eq.eqRowCons(dictOrdRecord.EqRecord0())()(dictIsSymbol)(dictOrd.Eq0());
                      }
                  };
              };
          };
      };
  };
  var clamp = function (dictOrd) {
      return function (low) {
          return function (hi) {
              return function (x) {
                  return min(dictOrd)(hi)(max(dictOrd)(low)(x));
              };
          };
      };
  };
  var abs = function (dictOrd) {
      return function (dictRing) {
          return function (x) {
              var $60 = greaterThanOrEq(dictOrd)(x)(Data_Semiring.zero(dictRing.Semiring0()));
              if ($60) {
                  return x;
              };
              return Data_Ring.negate(dictRing)(x);
          };
      };
  };
  exports["compare"] = compare;
  exports["comparing"] = comparing;
  exports["min"] = min;
  exports["max"] = max;
  exports["clamp"] = clamp;
  exports["abs"] = abs;
  exports["ordInt"] = ordInt;
  exports["ordString"] = ordString;
  exports["ordChar"] = ordChar;
  exports["ordRecordNil"] = ordRecordNil;
  exports["ordRecordCons"] = ordRecordCons;
  exports["ordRecord"] = ordRecord;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Tuple"] = $PS["Data.Tuple"] || {};
  var exports = $PS["Data.Tuple"];                 
  var Tuple = (function () {
      function Tuple(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Tuple.create = function (value0) {
          return function (value1) {
              return new Tuple(value0, value1);
          };
      };
      return Tuple;
  })();
  var snd = function (v) {
      return v.value1;
  };
  var fst = function (v) {
      return v.value0;
  };
  exports["Tuple"] = Tuple;
  exports["fst"] = fst;
  exports["snd"] = snd;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Array"] = $PS["Data.Array"] || {};
  var exports = $PS["Data.Array"];
  var $foreign = $PS["Data.Array"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_ST_Internal = $PS["Control.Monad.ST.Internal"];
  var Data_Array_ST = $PS["Data.Array.ST"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Tuple = $PS["Data.Tuple"];
  var updateAt = $foreign["_updateAt"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var unsafeIndex = function () {
      return $foreign.unsafeIndexImpl;
  };
  var uncons = $foreign.unconsImpl(Data_Function["const"](Data_Maybe.Nothing.value))(function (x) {
      return function (xs) {
          return new Data_Maybe.Just({
              head: x,
              tail: xs
          });
      };
  });
  var take = function (n) {
      return function (xs) {
          var $58 = n < 1;
          if ($58) {
              return [  ];
          };
          return $foreign.slice(0)(n)(xs);
      };
  };
  var sortBy = function (comp) {
      return $foreign.sortByImpl(comp)(function (v) {
          if (v instanceof Data_Ordering.GT) {
              return 1;
          };
          if (v instanceof Data_Ordering.EQ) {
              return 0;
          };
          if (v instanceof Data_Ordering.LT) {
              return -1 | 0;
          };
          throw new Error("Failed pattern match at Data.Array (line 831, column 31 - line 834, column 11): " + [ v.constructor.name ]);
      });
  };
  var sortWith = function (dictOrd) {
      return function (f) {
          return sortBy(Data_Ord.comparing(dictOrd)(f));
      };
  };
  var singleton = function (a) {
      return [ a ];
  };
  var $$null = function (xs) {
      return $foreign.length(xs) === 0;
  };
  var mapWithIndex = function (f) {
      return function (xs) {
          return $foreign.zipWith(f)($foreign.range(0)($foreign.length(xs) - 1 | 0))(xs);
      };
  };                                                                                     
  var init = function (xs) {
      if ($$null(xs)) {
          return Data_Maybe.Nothing.value;
      };
      if (Data_Boolean.otherwise) {
          return new Data_Maybe.Just($foreign.slice(0)($foreign.length(xs) - 1 | 0)(xs));
      };
      throw new Error("Failed pattern match at Data.Array (line 340, column 1 - line 340, column 45): " + [ xs.constructor.name ]);
  };
  var index = $foreign.indexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var last = function (xs) {
      return index(xs)($foreign.length(xs) - 1 | 0);
  };
  var modifyAt = function (i) {
      return function (f) {
          return function (xs) {
              var go = function (x) {
                  return updateAt(i)(f(x))(xs);
              };
              return Data_Maybe.maybe(Data_Maybe.Nothing.value)(go)(index(xs)(i));
          };
      };
  };
  var span = function (p) {
      return function (arr) {
          var go = function ($copy_i) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(i) {
                  var v = index(arr)(i);
                  if (v instanceof Data_Maybe.Just) {
                      var $65 = p(v.value0);
                      if ($65) {
                          $copy_i = i + 1 | 0;
                          return;
                      };
                      $tco_done = true;
                      return new Data_Maybe.Just(i);
                  };
                  if (v instanceof Data_Maybe.Nothing) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  throw new Error("Failed pattern match at Data.Array (line 966, column 5 - line 968, column 25): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_i);
              };
              return $tco_result;
          };
          var breakIndex = go(0);
          if (breakIndex instanceof Data_Maybe.Just && breakIndex.value0 === 0) {
              return {
                  init: [  ],
                  rest: arr
              };
          };
          if (breakIndex instanceof Data_Maybe.Just) {
              return {
                  init: $foreign.slice(0)(breakIndex.value0)(arr),
                  rest: $foreign.slice(breakIndex.value0)($foreign.length(arr))(arr)
              };
          };
          if (breakIndex instanceof Data_Maybe.Nothing) {
              return {
                  init: arr,
                  rest: [  ]
              };
          };
          throw new Error("Failed pattern match at Data.Array (line 953, column 3 - line 959, column 30): " + [ breakIndex.constructor.name ]);
      };
  };
  var takeWhile = function (p) {
      return function (xs) {
          return (span(p)(xs)).init;
      };
  };
  var head = function (xs) {
      return index(xs)(0);
  };
  var nubBy = function (comp) {
      return function (xs) {
          var indexedAndSorted = sortBy(function (x) {
              return function (y) {
                  return comp(Data_Tuple.snd(x))(Data_Tuple.snd(y));
              };
          })(mapWithIndex(Data_Tuple.Tuple.create)(xs));
          var v = head(indexedAndSorted);
          if (v instanceof Data_Maybe.Nothing) {
              return [  ];
          };
          if (v instanceof Data_Maybe.Just) {
              return Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd)(sortWith(Data_Ord.ordInt)(Data_Tuple.fst)((function __do() {
                  var result = Data_Array_ST.unsafeThaw(singleton(v.value0))();
                  Control_Monad_ST_Internal.foreach(indexedAndSorted)(function (v1) {
                      return function __do() {
                          var lst = Data_Functor.map(Control_Monad_ST_Internal.functorST)((function () {
                              var $93 = (function () {
                                  var $95 = Data_Maybe.fromJust();
                                  return function ($96) {
                                      return $95(last($96));
                                  };
                              })();
                              return function ($94) {
                                  return Data_Tuple.snd($93($94));
                              };
                          })())(Data_Array_ST.unsafeFreeze(result))();
                          return Control_Applicative.when(Control_Monad_ST_Internal.applicativeST)(Data_Eq.notEq(Data_Ordering.eqOrdering)(comp(lst)(v1.value1))(Data_Ordering.EQ.value))(Data_Functor["void"](Control_Monad_ST_Internal.functorST)(Data_Array_ST.push(v1)(result)))();
                      };
                  })();
                  return Data_Array_ST.unsafeFreeze(result)();
              })()));
          };
          throw new Error("Failed pattern match at Data.Array (line 1050, column 17 - line 1058, column 29): " + [ v.constructor.name ]);
      };
  };
  var nub = function (dictOrd) {
      return nubBy(Data_Ord.compare(dictOrd));
  };                                                           
  var foldl = Data_Foldable.foldl(Data_Foldable.foldableArray);
  var findMap = $foreign.findMapImpl(Data_Maybe.Nothing.value)(Data_Maybe.isJust);
  var findIndex = $foreign.findIndexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var find = function (f) {
      return function (xs) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(unsafeIndex()(xs))(findIndex(f)(xs));
      };
  };
  var elemIndex = function (dictEq) {
      return function (x) {
          return findIndex(function (v) {
              return Data_Eq.eq(dictEq)(v)(x);
          });
      };
  };
  var notElem = function (dictEq) {
      return function (a) {
          return function (arr) {
              return Data_Maybe.isNothing(elemIndex(dictEq)(a)(arr));
          };
      };
  };
  var elem = function (dictEq) {
      return function (a) {
          return function (arr) {
              return Data_Maybe.isJust(elemIndex(dictEq)(a)(arr));
          };
      };
  };
  var drop = function (n) {
      return function (xs) {
          var $80 = n < 1;
          if ($80) {
              return xs;
          };
          return $foreign.slice(n)($foreign.length(xs))(xs);
      };
  };
  var takeEnd = function (n) {
      return function (xs) {
          return drop($foreign.length(xs) - n | 0)(xs);
      };
  };
  var concatMap = Data_Function.flip(Control_Bind.bind(Control_Bind.bindArray));
  var mapMaybe = function (f) {
      return concatMap((function () {
          var $100 = Data_Maybe.maybe([  ])(singleton);
          return function ($101) {
              return $100(f($101));
          };
      })());
  };
  var catMaybes = mapMaybe(Control_Category.identity(Control_Category.categoryFn));
  exports["null"] = $$null;
  exports["head"] = head;
  exports["last"] = last;
  exports["uncons"] = uncons;
  exports["index"] = index;
  exports["elem"] = elem;
  exports["notElem"] = notElem;
  exports["find"] = find;
  exports["findMap"] = findMap;
  exports["findIndex"] = findIndex;
  exports["modifyAt"] = modifyAt;
  exports["concatMap"] = concatMap;
  exports["mapMaybe"] = mapMaybe;
  exports["catMaybes"] = catMaybes;
  exports["mapWithIndex"] = mapWithIndex;
  exports["foldl"] = foldl;
  exports["sortWith"] = sortWith;
  exports["take"] = take;
  exports["takeEnd"] = takeEnd;
  exports["takeWhile"] = takeWhile;
  exports["nub"] = nub;
  exports["length"] = $foreign.length;
  exports["reverse"] = $foreign.reverse;
  exports["concat"] = $foreign.concat;
  exports["filter"] = $foreign.filter;
  exports["partition"] = $foreign.partition;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Bifunctor"] = $PS["Data.Bifunctor"] || {};
  var exports = $PS["Data.Bifunctor"];
  var Control_Category = $PS["Control.Category"];
  var Data_Either = $PS["Data.Either"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var bimap = function (dict) {
      return dict.bimap;
  };
  var lmap = function (dictBifunctor) {
      return function (f) {
          return bimap(dictBifunctor)(f)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  var bifunctorTuple = {
      bimap: function (f) {
          return function (g) {
              return function (v) {
                  return new Data_Tuple.Tuple(f(v.value0), g(v.value1));
              };
          };
      }
  };
  var bifunctorEither = {
      bimap: function (v) {
          return function (v1) {
              return function (v2) {
                  if (v2 instanceof Data_Either.Left) {
                      return new Data_Either.Left(v(v2.value0));
                  };
                  if (v2 instanceof Data_Either.Right) {
                      return new Data_Either.Right(v1(v2.value0));
                  };
                  throw new Error("Failed pattern match at Data.Bifunctor (line 32, column 1 - line 34, column 36): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
              };
          };
      }
  };
  exports["lmap"] = lmap;
  exports["bifunctorEither"] = bifunctorEither;
  exports["bifunctorTuple"] = bifunctorTuple;
})(PS);
(function(exports) {
  "use strict";

  exports.topInt = 2147483647;
  exports.bottomInt = -2147483648;

  exports.topChar = String.fromCharCode(65535);
  exports.bottomChar = String.fromCharCode(0);
})(PS["Data.Bounded"] = PS["Data.Bounded"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Bounded"] = $PS["Data.Bounded"] || {};
  var exports = $PS["Data.Bounded"];
  var $foreign = $PS["Data.Bounded"];
  var Data_Ord = $PS["Data.Ord"];
  var top = function (dict) {
      return dict.top;
  };
  var boundedInt = {
      top: $foreign.topInt,
      bottom: $foreign.bottomInt,
      Ord0: function () {
          return Data_Ord.ordInt;
      }
  };
  var boundedChar = {
      top: $foreign.topChar,
      bottom: $foreign.bottomChar,
      Ord0: function () {
          return Data_Ord.ordChar;
      }
  };
  var bottom = function (dict) {
      return dict.bottom;
  };
  exports["bottom"] = bottom;
  exports["top"] = top;
  exports["boundedInt"] = boundedInt;
  exports["boundedChar"] = boundedChar;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.CommutativeRing"] = $PS["Data.CommutativeRing"] || {};
  var exports = $PS["Data.CommutativeRing"];
  var Data_Ring = $PS["Data.Ring"];
  var commutativeRingInt = {
      Ring0: function () {
          return Data_Ring.ringInt;
      }
  };
  exports["commutativeRingInt"] = commutativeRingInt;
})(PS);
(function(exports) {
  "use strict";

  exports.toCharCode = function (c) {
    return c.charCodeAt(0);
  };

  exports.fromCharCode = function (c) {
    return String.fromCharCode(c);
  };
})(PS["Data.Enum"] = PS["Data.Enum"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Enum"] = $PS["Data.Enum"] || {};
  var exports = $PS["Data.Enum"];
  var $foreign = $PS["Data.Enum"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var toEnum = function (dict) {
      return dict.toEnum;
  };
  var fromEnum = function (dict) {
      return dict.fromEnum;
  };
  var toEnumWithDefaults = function (dictBoundedEnum) {
      return function (low) {
          return function (high) {
              return function (x) {
                  var v = toEnum(dictBoundedEnum)(x);
                  if (v instanceof Data_Maybe.Just) {
                      return v.value0;
                  };
                  if (v instanceof Data_Maybe.Nothing) {
                      var $54 = x < fromEnum(dictBoundedEnum)(Data_Bounded.bottom(dictBoundedEnum.Bounded0()));
                      if ($54) {
                          return low;
                      };
                      return high;
                  };
                  throw new Error("Failed pattern match at Data.Enum (line 158, column 33 - line 160, column 62): " + [ v.constructor.name ]);
              };
          };
      };
  };
  var defaultSucc = function (toEnum$prime) {
      return function (fromEnum$prime) {
          return function (a) {
              return toEnum$prime(fromEnum$prime(a) + 1 | 0);
          };
      };
  };
  var defaultPred = function (toEnum$prime) {
      return function (fromEnum$prime) {
          return function (a) {
              return toEnum$prime(fromEnum$prime(a) - 1 | 0);
          };
      };
  };
  var charToEnum = function (v) {
      if (v >= Data_Bounded.bottom(Data_Bounded.boundedInt) && v <= Data_Bounded.top(Data_Bounded.boundedInt)) {
          return new Data_Maybe.Just($foreign.fromCharCode(v));
      };
      return Data_Maybe.Nothing.value;
  };
  var enumChar = {
      succ: defaultSucc(charToEnum)($foreign.toCharCode),
      pred: defaultPred(charToEnum)($foreign.toCharCode),
      Ord0: function () {
          return Data_Ord.ordChar;
      }
  };
  var boundedEnumChar = {
      cardinality: $foreign.toCharCode(Data_Bounded.top(Data_Bounded.boundedChar)) - $foreign.toCharCode(Data_Bounded.bottom(Data_Bounded.boundedChar)) | 0,
      toEnum: charToEnum,
      fromEnum: $foreign.toCharCode,
      Bounded0: function () {
          return Data_Bounded.boundedChar;
      },
      Enum1: function () {
          return enumChar;
      }
  };
  exports["fromEnum"] = fromEnum;
  exports["toEnumWithDefaults"] = toEnumWithDefaults;
  exports["boundedEnumChar"] = boundedEnumChar;
})(PS);
(function(exports) {
  "use strict";

  exports.intDegree = function (x) {
    return Math.min(Math.abs(x), 2147483647);
  };

  // See the Euclidean definition in
  // https://en.m.wikipedia.org/wiki/Modulo_operation.
  exports.intDiv = function (x) {
    return function (y) {
      if (y === 0) return 0;
      return y > 0 ? Math.floor(x / y) : -Math.floor(x / -y);
    };
  };

  exports.intMod = function (x) {
    return function (y) {
      if (y === 0) return 0;
      var yy = Math.abs(y);
      return ((x % yy) + yy) % yy;
    };
  };
})(PS["Data.EuclideanRing"] = PS["Data.EuclideanRing"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.EuclideanRing"] = $PS["Data.EuclideanRing"] || {};
  var exports = $PS["Data.EuclideanRing"];
  var $foreign = $PS["Data.EuclideanRing"];
  var Data_CommutativeRing = $PS["Data.CommutativeRing"];  
  var mod = function (dict) {
      return dict.mod;
  };
  var euclideanRingInt = {
      degree: $foreign.intDegree,
      div: $foreign.intDiv,
      mod: $foreign.intMod,
      CommutativeRing0: function () {
          return Data_CommutativeRing.commutativeRingInt;
      }
  };
  var div = function (dict) {
      return dict.div;
  };
  exports["div"] = div;
  exports["mod"] = mod;
  exports["euclideanRingInt"] = euclideanRingInt;
})(PS);
(function(exports) {
  "use strict";

  exports.mapWithIndexArray = function (f) {
    return function (xs) {
      var l = xs.length;
      var result = Array(l);
      for (var i = 0; i < l; i++) {
        result[i] = f(i)(xs[i]);
      }
      return result;
    };
  };
})(PS["Data.FunctorWithIndex"] = PS["Data.FunctorWithIndex"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.FunctorWithIndex"] = $PS["Data.FunctorWithIndex"] || {};
  var exports = $PS["Data.FunctorWithIndex"];
  var $foreign = $PS["Data.FunctorWithIndex"];
  var Data_Functor = $PS["Data.Functor"];          
  var mapWithIndex = function (dict) {
      return dict.mapWithIndex;
  };
  var functorWithIndexArray = {
      mapWithIndex: $foreign.mapWithIndexArray,
      Functor0: function () {
          return Data_Functor.functorArray;
      }
  };
  exports["mapWithIndex"] = mapWithIndex;
  exports["functorWithIndexArray"] = functorWithIndexArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.FoldableWithIndex"] = $PS["Data.FoldableWithIndex"] || {};
  var exports = $PS["Data.FoldableWithIndex"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_FunctorWithIndex = $PS["Data.FunctorWithIndex"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];              
  var foldrWithIndex = function (dict) {
      return dict.foldrWithIndex;
  };
  var foldMapWithIndexDefaultR = function (dictFoldableWithIndex) {
      return function (dictMonoid) {
          return function (f) {
              return foldrWithIndex(dictFoldableWithIndex)(function (i) {
                  return function (x) {
                      return function (acc) {
                          return Data_Semigroup.append(dictMonoid.Semigroup0())(f(i)(x))(acc);
                      };
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      };
  };
  var foldableWithIndexArray = {
      foldrWithIndex: function (f) {
          return function (z) {
              var $167 = Data_Foldable.foldr(Data_Foldable.foldableArray)(function (v) {
                  return function (y) {
                      return f(v.value0)(v.value1)(y);
                  };
              })(z);
              var $168 = Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray)(Data_Tuple.Tuple.create);
              return function ($169) {
                  return $167($168($169));
              };
          };
      },
      foldlWithIndex: function (f) {
          return function (z) {
              var $170 = Data_Foldable.foldl(Data_Foldable.foldableArray)(function (y) {
                  return function (v) {
                      return f(v.value0)(y)(v.value1);
                  };
              })(z);
              var $171 = Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray)(Data_Tuple.Tuple.create);
              return function ($172) {
                  return $170($171($172));
              };
          };
      },
      foldMapWithIndex: function (dictMonoid) {
          return foldMapWithIndexDefaultR(foldableWithIndexArray)(dictMonoid);
      },
      Foldable0: function () {
          return Data_Foldable.foldableArray;
      }
  };
  exports["foldableWithIndexArray"] = foldableWithIndexArray;
})(PS);
(function(exports) {
  "use strict";

  exports.boolConj = function (b1) {
    return function (b2) {
      return b1 && b2;
    };
  };

  exports.boolDisj = function (b1) {
    return function (b2) {
      return b1 || b2;
    };
  };

  exports.boolNot = function (b) {
    return !b;
  };
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.HeytingAlgebra"] = $PS["Data.HeytingAlgebra"] || {};
  var exports = $PS["Data.HeytingAlgebra"];
  var $foreign = $PS["Data.HeytingAlgebra"];
  var tt = function (dict) {
      return dict.tt;
  };
  var not = function (dict) {
      return dict.not;
  };
  var implies = function (dict) {
      return dict.implies;
  };
  var ff = function (dict) {
      return dict.ff;
  };
  var disj = function (dict) {
      return dict.disj;
  };
  var heytingAlgebraBoolean = {
      ff: false,
      tt: true,
      implies: function (a) {
          return function (b) {
              return disj(heytingAlgebraBoolean)(not(heytingAlgebraBoolean)(a))(b);
          };
      },
      conj: $foreign.boolConj,
      disj: $foreign.boolDisj,
      not: $foreign.boolNot
  };
  var conj = function (dict) {
      return dict.conj;
  };
  var heytingAlgebraFunction = function (dictHeytingAlgebra) {
      return {
          ff: function (v) {
              return ff(dictHeytingAlgebra);
          },
          tt: function (v) {
              return tt(dictHeytingAlgebra);
          },
          implies: function (f) {
              return function (g) {
                  return function (a) {
                      return implies(dictHeytingAlgebra)(f(a))(g(a));
                  };
              };
          },
          conj: function (f) {
              return function (g) {
                  return function (a) {
                      return conj(dictHeytingAlgebra)(f(a))(g(a));
                  };
              };
          },
          disj: function (f) {
              return function (g) {
                  return function (a) {
                      return disj(dictHeytingAlgebra)(f(a))(g(a));
                  };
              };
          },
          not: function (f) {
              return function (a) {
                  return not(dictHeytingAlgebra)(f(a));
              };
          }
      };
  };
  exports["conj"] = conj;
  exports["not"] = not;
  exports["heytingAlgebraBoolean"] = heytingAlgebraBoolean;
  exports["heytingAlgebraFunction"] = heytingAlgebraFunction;
})(PS);
(function(exports) {
  "use strict";

  exports.fromNumberImpl = function (just) {
    return function (nothing) {
      return function (n) {
        /* jshint bitwise: false */
        return (n | 0) === n ? just(n) : nothing;
      };
    };
  };

  exports.toNumber = function (n) {
    return n;
  };
})(PS["Data.Int"] = PS["Data.Int"] || {});
(function(exports) {
  /* globals exports */
  "use strict";               

  exports.isFinite = isFinite;
})(PS["Data.Number"] = PS["Data.Number"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Number"] = $PS["Data.Number"] || {};
  var exports = $PS["Data.Number"];
  var $foreign = $PS["Data.Number"];
  exports["isFinite"] = $foreign["isFinite"];
})(PS);
(function(exports) {
  "use strict";

  exports.pow = function (n) {
    return function (p) {
      return Math.pow(n, p);
    };
  };

  exports.round = Math.round;
})(PS["Math"] = PS["Math"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Math"] = $PS["Math"] || {};
  var exports = $PS["Math"];
  var $foreign = $PS["Math"];
  exports["pow"] = $foreign.pow;
  exports["round"] = $foreign.round;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Int"] = $PS["Data.Int"] || {};
  var exports = $PS["Data.Int"];
  var $foreign = $PS["Data.Int"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Number = $PS["Data.Number"];
  var $$Math = $PS["Math"];         
  var fromNumber = $foreign.fromNumberImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var unsafeClamp = function (x) {
      if (!Data_Number["isFinite"](x)) {
          return 0;
      };
      if (x >= $foreign.toNumber(Data_Bounded.top(Data_Bounded.boundedInt))) {
          return Data_Bounded.top(Data_Bounded.boundedInt);
      };
      if (x <= $foreign.toNumber(Data_Bounded.bottom(Data_Bounded.boundedInt))) {
          return Data_Bounded.bottom(Data_Bounded.boundedInt);
      };
      if (Data_Boolean.otherwise) {
          return Data_Maybe.fromMaybe(0)(fromNumber(x));
      };
      throw new Error("Failed pattern match at Data.Int (line 66, column 1 - line 66, column 29): " + [ x.constructor.name ]);
  };
  var round = function ($23) {
      return unsafeClamp($$Math.round($23));
  };
  exports["fromNumber"] = fromNumber;
  exports["round"] = round;
  exports["toNumber"] = $foreign.toNumber;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.NonEmpty"] = $PS["Data.NonEmpty"] || {};
  var exports = $PS["Data.NonEmpty"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Functor = $PS["Data.Functor"];                      
  var NonEmpty = (function () {
      function NonEmpty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      NonEmpty.create = function (value0) {
          return function (value1) {
              return new NonEmpty(value0, value1);
          };
      };
      return NonEmpty;
  })();
  var singleton = function (dictPlus) {
      return function (a) {
          return new NonEmpty(a, Control_Plus.empty(dictPlus));
      };
  };
  var functorNonEmpty = function (dictFunctor) {
      return {
          map: function (f) {
              return function (m) {
                  return new NonEmpty(f(m.value0), Data_Functor.map(dictFunctor)(f)(m.value1));
              };
          }
      };
  };
  exports["NonEmpty"] = NonEmpty;
  exports["singleton"] = singleton;
  exports["functorNonEmpty"] = functorNonEmpty;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.List.Types"] = $PS["Data.List.Types"] || {};
  var exports = $PS["Data.List.Types"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var Data_Semigroup = $PS["Data.Semigroup"];        
  var Nil = (function () {
      function Nil() {

      };
      Nil.value = new Nil();
      return Nil;
  })();
  var Cons = (function () {
      function Cons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Cons.create = function (value0) {
          return function (value1) {
              return new Cons(value0, value1);
          };
      };
      return Cons;
  })();
  var NonEmptyList = function (x) {
      return x;
  };
  var toList = function (v) {
      return new Cons(v.value0, v.value1);
  };
  var listMap = function (f) {
      var chunkedRevMap = function ($copy_chunksAcc) {
          return function ($copy_v) {
              var $tco_var_chunksAcc = $copy_chunksAcc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(chunksAcc, v) {
                  if (v instanceof Cons && (v.value1 instanceof Cons && v.value1.value1 instanceof Cons)) {
                      $tco_var_chunksAcc = new Cons(v, chunksAcc);
                      $copy_v = v.value1.value1.value1;
                      return;
                  };
                  var unrolledMap = function (v1) {
                      if (v1 instanceof Cons && (v1.value1 instanceof Cons && v1.value1.value1 instanceof Nil)) {
                          return new Cons(f(v1.value0), new Cons(f(v1.value1.value0), Nil.value));
                      };
                      if (v1 instanceof Cons && v1.value1 instanceof Nil) {
                          return new Cons(f(v1.value0), Nil.value);
                      };
                      return Nil.value;
                  };
                  var reverseUnrolledMap = function ($copy_v1) {
                      return function ($copy_acc) {
                          var $tco_var_v1 = $copy_v1;
                          var $tco_done1 = false;
                          var $tco_result;
                          function $tco_loop(v1, acc) {
                              if (v1 instanceof Cons && (v1.value0 instanceof Cons && (v1.value0.value1 instanceof Cons && v1.value0.value1.value1 instanceof Cons))) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_acc = new Cons(f(v1.value0.value0), new Cons(f(v1.value0.value1.value0), new Cons(f(v1.value0.value1.value1.value0), acc)));
                                  return;
                              };
                              $tco_done1 = true;
                              return acc;
                          };
                          while (!$tco_done1) {
                              $tco_result = $tco_loop($tco_var_v1, $copy_acc);
                          };
                          return $tco_result;
                      };
                  };
                  $tco_done = true;
                  return reverseUnrolledMap(chunksAcc)(unrolledMap(v));
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_chunksAcc, $copy_v);
              };
              return $tco_result;
          };
      };
      return chunkedRevMap(Nil.value);
  };
  var functorList = {
      map: listMap
  };
  var functorNonEmptyList = Data_NonEmpty.functorNonEmpty(functorList);
  var foldableList = {
      foldr: function (f) {
          return function (b) {
              var rev = (function () {
                  var go = function ($copy_acc) {
                      return function ($copy_v) {
                          var $tco_var_acc = $copy_acc;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(acc, v) {
                              if (v instanceof Nil) {
                                  $tco_done = true;
                                  return acc;
                              };
                              if (v instanceof Cons) {
                                  $tco_var_acc = new Cons(v.value0, acc);
                                  $copy_v = v.value1;
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.List.Types (line 108, column 7 - line 108, column 23): " + [ acc.constructor.name, v.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_acc, $copy_v);
                          };
                          return $tco_result;
                      };
                  };
                  return go(Nil.value);
              })();
              var $205 = Data_Foldable.foldl(foldableList)(Data_Function.flip(f))(b);
              return function ($206) {
                  return $205(rev($206));
              };
          };
      },
      foldl: function (f) {
          var go = function ($copy_b) {
              return function ($copy_v) {
                  var $tco_var_b = $copy_b;
                  var $tco_done1 = false;
                  var $tco_result;
                  function $tco_loop(b, v) {
                      if (v instanceof Nil) {
                          $tco_done1 = true;
                          return b;
                      };
                      if (v instanceof Cons) {
                          $tco_var_b = f(b)(v.value0);
                          $copy_v = v.value1;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.List.Types (line 112, column 12 - line 114, column 30): " + [ v.constructor.name ]);
                  };
                  while (!$tco_done1) {
                      $tco_result = $tco_loop($tco_var_b, $copy_v);
                  };
                  return $tco_result;
              };
          };
          return go;
      },
      foldMap: function (dictMonoid) {
          return function (f) {
              return Data_Foldable.foldl(foldableList)(function (acc) {
                  var $207 = Data_Semigroup.append(dictMonoid.Semigroup0())(acc);
                  return function ($208) {
                      return $207(f($208));
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      }
  };
  var semigroupList = {
      append: function (xs) {
          return function (ys) {
              return Data_Foldable.foldr(foldableList)(Cons.create)(ys)(xs);
          };
      }
  };
  var semigroupNonEmptyList = {
      append: function (v) {
          return function (as$prime) {
              return new Data_NonEmpty.NonEmpty(v.value0, Data_Semigroup.append(semigroupList)(v.value1)(toList(as$prime)));
          };
      }
  };
  var applyList = {
      apply: function (v) {
          return function (v1) {
              if (v instanceof Nil) {
                  return Nil.value;
              };
              if (v instanceof Cons) {
                  return Data_Semigroup.append(semigroupList)(Data_Functor.map(functorList)(v.value0)(v1))(Control_Apply.apply(applyList)(v.value1)(v1));
              };
              throw new Error("Failed pattern match at Data.List.Types (line 158, column 1 - line 160, column 48): " + [ v.constructor.name, v1.constructor.name ]);
          };
      },
      Functor0: function () {
          return functorList;
      }
  };
  var applyNonEmptyList = {
      apply: function (v) {
          return function (v1) {
              return new Data_NonEmpty.NonEmpty(v.value0(v1.value0), Data_Semigroup.append(semigroupList)(Control_Apply.apply(applyList)(v.value1)(new Cons(v1.value0, Nil.value)))(Control_Apply.apply(applyList)(new Cons(v.value0, v.value1))(v1.value1)));
          };
      },
      Functor0: function () {
          return functorNonEmptyList;
      }
  };
  var altList = {
      alt: Data_Semigroup.append(semigroupList),
      Functor0: function () {
          return functorList;
      }
  };
  var plusList = {
      empty: Nil.value,
      Alt0: function () {
          return altList;
      }
  };
  var applicativeNonEmptyList = {
      pure: (function () {
          var $236 = Data_NonEmpty.singleton(plusList);
          return function ($237) {
              return NonEmptyList($236($237));
          };
      })(),
      Apply0: function () {
          return applyNonEmptyList;
      }
  };
  exports["NonEmptyList"] = NonEmptyList;
  exports["plusList"] = plusList;
  exports["functorNonEmptyList"] = functorNonEmptyList;
  exports["applicativeNonEmptyList"] = applicativeNonEmptyList;
  exports["semigroupNonEmptyList"] = semigroupNonEmptyList;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.List.NonEmpty"] = $PS["Data.List.NonEmpty"] || {};
  var exports = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var singleton = (function () {
      var $171 = Data_NonEmpty.singleton(Data_List_Types.plusList);
      return function ($172) {
          return Data_List_Types.NonEmptyList($171($172));
      };
  })();
  var head = function (v) {
      return v.value0;
  };
  exports["singleton"] = singleton;
  exports["head"] = head;
})(PS);
(function(exports) {
  "use strict";
  /* global Symbol */

  var hasArrayFrom = typeof Array.from === "function";
  var hasStringIterator =
    typeof Symbol !== "undefined" &&
    Symbol != null &&
    typeof Symbol.iterator !== "undefined" &&
    typeof String.prototype[Symbol.iterator] === "function";
  var hasFromCodePoint = typeof String.prototype.fromCodePoint === "function";
  var hasCodePointAt = typeof String.prototype.codePointAt === "function";

  exports._unsafeCodePointAt0 = function (fallback) {
    return hasCodePointAt
      ? function (str) { return str.codePointAt(0); }
      : fallback;
  };

  exports._singleton = function (fallback) {
    return hasFromCodePoint ? String.fromCodePoint : fallback;
  };

  exports._take = function (fallback) {
    return function (n) {
      if (hasStringIterator) {
        return function (str) {
          var accum = "";
          var iter = str[Symbol.iterator]();
          for (var i = 0; i < n; ++i) {
            var o = iter.next();
            if (o.done) return accum;
            accum += o.value;
          }
          return accum;
        };
      }
      return fallback(n);
    };
  };

  exports._toCodePointArray = function (fallback) {
    return function (unsafeCodePointAt0) {
      if (hasArrayFrom) {
        return function (str) {
          return Array.from(str, unsafeCodePointAt0);
        };
      }
      return fallback;
    };
  };
})(PS["Data.String.CodePoints"] = PS["Data.String.CodePoints"] || {});
(function(exports) {
  "use strict";

  exports.singleton = function (c) {
    return c;
  };

  exports.length = function (s) {
    return s.length;
  };

  exports.drop = function (n) {
    return function (s) {
      return s.substring(n);
    };
  };
})(PS["Data.String.CodeUnits"] = PS["Data.String.CodeUnits"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.String.CodeUnits"] = $PS["Data.String.CodeUnits"] || {};
  var exports = $PS["Data.String.CodeUnits"];
  var $foreign = $PS["Data.String.CodeUnits"];
  exports["singleton"] = $foreign.singleton;
  exports["length"] = $foreign.length;
  exports["drop"] = $foreign.drop;
})(PS);
(function(exports) {
  "use strict";

  exports.charAt = function (i) {
    return function (s) {
      if (i >= 0 && i < s.length) return s.charAt(i);
      throw new Error("Data.String.Unsafe.charAt: Invalid index.");
    };
  };
})(PS["Data.String.Unsafe"] = PS["Data.String.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.String.Unsafe"] = $PS["Data.String.Unsafe"] || {};
  var exports = $PS["Data.String.Unsafe"];
  var $foreign = $PS["Data.String.Unsafe"];
  exports["charAt"] = $foreign.charAt;
})(PS);
(function(exports) {
  "use strict";

  exports.unfoldrArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;
              while (true) { // eslint-disable-line no-constant-condition
                var maybe = f(value);
                if (isNothing(maybe)) return result;
                var tuple = fromJust(maybe);
                result.push(fst(tuple));
                value = snd(tuple);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable"] = PS["Data.Unfoldable"] || {});
(function(exports) {
  "use strict";

  exports.unfoldr1ArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;
              while (true) { // eslint-disable-line no-constant-condition
                var tuple = f(value);
                result.push(fst(tuple));
                var maybe = snd(tuple);
                if (isNothing(maybe)) return result;
                value = fromJust(maybe);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable1"] = PS["Data.Unfoldable1"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Unfoldable1"] = $PS["Data.Unfoldable1"] || {};
  var exports = $PS["Data.Unfoldable1"];
  var $foreign = $PS["Data.Unfoldable1"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var unfoldable1Array = {
      unfoldr1: $foreign.unfoldr1ArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd)
  };
  exports["unfoldable1Array"] = unfoldable1Array;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Unfoldable"] = $PS["Data.Unfoldable"] || {};
  var exports = $PS["Data.Unfoldable"];
  var $foreign = $PS["Data.Unfoldable"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable1 = $PS["Data.Unfoldable1"];  
  var unfoldr = function (dict) {
      return dict.unfoldr;
  };
  var unfoldableArray = {
      unfoldr: $foreign.unfoldrArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd),
      Unfoldable10: function () {
          return Data_Unfoldable1.unfoldable1Array;
      }
  };
  exports["unfoldr"] = unfoldr;
  exports["unfoldableArray"] = unfoldableArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.String.CodePoints"] = $PS["Data.String.CodePoints"] || {};
  var exports = $PS["Data.String.CodePoints"];
  var $foreign = $PS["Data.String.CodePoints"];
  var Data_Array = $PS["Data.Array"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Enum = $PS["Data.Enum"];
  var Data_EuclideanRing = $PS["Data.EuclideanRing"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Data_String_Unsafe = $PS["Data.String.Unsafe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var unsurrogate = function (lead) {
      return function (trail) {
          return (((lead - 55296 | 0) * 1024 | 0) + (trail - 56320 | 0) | 0) + 65536 | 0;
      };
  };
  var isTrail = function (cu) {
      return 56320 <= cu && cu <= 57343;
  };
  var isLead = function (cu) {
      return 55296 <= cu && cu <= 56319;
  };
  var uncons = function (s) {
      var v = Data_String_CodeUnits.length(s);
      if (v === 0) {
          return Data_Maybe.Nothing.value;
      };
      if (v === 1) {
          return new Data_Maybe.Just({
              head: Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s)),
              tail: ""
          });
      };
      var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
      var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
      var $21 = isLead(cu0) && isTrail(cu1);
      if ($21) {
          return new Data_Maybe.Just({
              head: unsurrogate(cu0)(cu1),
              tail: Data_String_CodeUnits.drop(2)(s)
          });
      };
      return new Data_Maybe.Just({
          head: cu0,
          tail: Data_String_CodeUnits.drop(1)(s)
      });
  };
  var unconsButWithTuple = function (s) {
      return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
          return new Data_Tuple.Tuple(v.head, v.tail);
      })(uncons(s));
  };
  var toCodePointArrayFallback = function (s) {
      return Data_Unfoldable.unfoldr(Data_Unfoldable.unfoldableArray)(unconsButWithTuple)(s);
  };
  var unsafeCodePointAt0Fallback = function (s) {
      var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
      var $25 = isLead(cu0) && Data_String_CodeUnits.length(s) > 1;
      if ($25) {
          var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
          var $26 = isTrail(cu1);
          if ($26) {
              return unsurrogate(cu0)(cu1);
          };
          return cu0;
      };
      return cu0;
  };
  var unsafeCodePointAt0 = $foreign["_unsafeCodePointAt0"](unsafeCodePointAt0Fallback);
  var toCodePointArray = $foreign["_toCodePointArray"](toCodePointArrayFallback)(unsafeCodePointAt0);
  var length = function ($52) {
      return Data_Array.length(toCodePointArray($52));
  };
  var fromCharCode = (function () {
      var $53 = Data_Enum.toEnumWithDefaults(Data_Enum.boundedEnumChar)(Data_Bounded.bottom(Data_Bounded.boundedChar))(Data_Bounded.top(Data_Bounded.boundedChar));
      return function ($54) {
          return Data_String_CodeUnits.singleton($53($54));
      };
  })();
  var singletonFallback = function (v) {
      if (v <= 65535) {
          return fromCharCode(v);
      };
      var lead = Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 55296 | 0;
      var trail = Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 56320 | 0;
      return fromCharCode(lead) + fromCharCode(trail);
  };                                                                          
  var singleton = $foreign["_singleton"](singletonFallback);
  var takeFallback = function (n) {
      return function (v) {
          if (n < 1) {
              return "";
          };
          var v1 = uncons(v);
          if (v1 instanceof Data_Maybe.Just) {
              return singleton(v1.value0.head) + takeFallback(n - 1 | 0)(v1.value0.tail);
          };
          return v;
      };
  };
  var take = $foreign["_take"](takeFallback);
  var drop = function (n) {
      return function (s) {
          return Data_String_CodeUnits.drop(Data_String_CodeUnits.length(take(n)(s)))(s);
      };
  };
  exports["length"] = length;
  exports["take"] = take;
})(PS);
(function(exports) {
  "use strict";

  exports.replace = function (s1) {
    return function (s2) {
      return function (s3) {
        return s3.replace(s1, s2);
      };
    };
  };
})(PS["Data.String.Common"] = PS["Data.String.Common"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.String.Common"] = $PS["Data.String.Common"] || {};
  var exports = $PS["Data.String.Common"];
  var $foreign = $PS["Data.String.Common"];
  exports["replace"] = $foreign.replace;
})(PS);
(function(exports) {
  "use strict";

  // jshint maxparams: 3

  exports.traverseArrayImpl = (function () {
    function array1(a) {
      return [a];
    }

    function array2(a) {
      return function (b) {
        return [a, b];
      };
    }

    function array3(a) {
      return function (b) {
        return function (c) {
          return [a, b, c];
        };
      };
    }

    function concat2(xs) {
      return function (ys) {
        return xs.concat(ys);
      };
    }

    return function (apply) {
      return function (map) {
        return function (pure) {
          return function (f) {
            return function (array) {
              function go(bot, top) {
                switch (top - bot) {
                case 0: return pure([]);
                case 1: return map(array1)(f(array[bot]));
                case 2: return apply(map(array2)(f(array[bot])))(f(array[bot + 1]));
                case 3: return apply(apply(map(array3)(f(array[bot])))(f(array[bot + 1])))(f(array[bot + 2]));
                default:
                  // This slightly tricky pivot selection aims to produce two
                  // even-length partitions where possible.
                  var pivot = bot + Math.floor((top - bot) / 4) * 2;
                  return apply(map(concat2)(go(bot, pivot)))(go(pivot, top));
                }
              }
              return go(0, array.length);
            };
          };
        };
      };
    };
  })();
})(PS["Data.Traversable"] = PS["Data.Traversable"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.Traversable"] = $PS["Data.Traversable"] || {};
  var exports = $PS["Data.Traversable"];
  var $foreign = $PS["Data.Traversable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];            
  var traverse = function (dict) {
      return dict.traverse;
  };
  var sequenceDefault = function (dictTraversable) {
      return function (dictApplicative) {
          return traverse(dictTraversable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  var traversableArray = {
      traverse: function (dictApplicative) {
          return $foreign.traverseArrayImpl(Control_Apply.apply(dictApplicative.Apply0()))(Data_Functor.map((dictApplicative.Apply0()).Functor0()))(Control_Applicative.pure(dictApplicative));
      },
      sequence: function (dictApplicative) {
          return sequenceDefault(traversableArray)(dictApplicative);
      },
      Functor0: function () {
          return Data_Functor.functorArray;
      },
      Foldable1: function () {
          return Data_Foldable.foldableArray;
      }
  };
  var sequence = function (dict) {
      return dict.sequence;
  };
  exports["traverse"] = traverse;
  exports["sequence"] = sequence;
  exports["traversableArray"] = traversableArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Data.TraversableWithIndex"] = $PS["Data.TraversableWithIndex"] || {};
  var exports = $PS["Data.TraversableWithIndex"];
  var Data_FoldableWithIndex = $PS["Data.FoldableWithIndex"];
  var Data_FunctorWithIndex = $PS["Data.FunctorWithIndex"];
  var Data_Traversable = $PS["Data.Traversable"];  
  var traverseWithIndexDefault = function (dictTraversableWithIndex) {
      return function (dictApplicative) {
          return function (f) {
              var $64 = Data_Traversable.sequence(dictTraversableWithIndex.Traversable2())(dictApplicative);
              var $65 = Data_FunctorWithIndex.mapWithIndex(dictTraversableWithIndex.FunctorWithIndex0())(f);
              return function ($66) {
                  return $64($65($66));
              };
          };
      };
  };
  var traverseWithIndex = function (dict) {
      return dict.traverseWithIndex;
  };
  var traversableWithIndexArray = {
      traverseWithIndex: function (dictApplicative) {
          return traverseWithIndexDefault(traversableWithIndexArray)(dictApplicative);
      },
      FunctorWithIndex0: function () {
          return Data_FunctorWithIndex.functorWithIndexArray;
      },
      FoldableWithIndex1: function () {
          return Data_FoldableWithIndex.foldableWithIndexArray;
      },
      Traversable2: function () {
          return Data_Traversable.traversableArray;
      }
  };
  exports["traverseWithIndex"] = traverseWithIndex;
  exports["traversableWithIndexArray"] = traversableWithIndexArray;
})(PS);
(function(exports) {
  "use strict";

  exports.pureE = function (a) {
    return function () {
      return a;
    };
  };

  exports.bindE = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Effect"] = PS["Effect"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Effect"] = $PS["Effect"] || {};
  var exports = $PS["Effect"];
  var $foreign = $PS["Effect"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Monad = $PS["Control.Monad"];                  
  var monadEffect = {
      Applicative0: function () {
          return applicativeEffect;
      },
      Bind1: function () {
          return bindEffect;
      }
  };
  var bindEffect = {
      bind: $foreign.bindE,
      Apply0: function () {
          return applyEffect;
      }
  };
  var applyEffect = {
      apply: Control_Monad.ap(monadEffect),
      Functor0: function () {
          return functorEffect;
      }
  };
  var applicativeEffect = {
      pure: $foreign.pureE,
      Apply0: function () {
          return applyEffect;
      }
  };
  var functorEffect = {
      map: Control_Applicative.liftA1(applicativeEffect)
  };
  exports["functorEffect"] = functorEffect;
  exports["applicativeEffect"] = applicativeEffect;
  exports["bindEffect"] = bindEffect;
  exports["monadEffect"] = monadEffect;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Effect.Class"] = $PS["Effect.Class"] || {};
  var exports = $PS["Effect.Class"];
  var Control_Category = $PS["Control.Category"];
  var Effect = $PS["Effect"];                
  var monadEffectEffect = {
      liftEffect: Control_Category.identity(Control_Category.categoryFn),
      Monad0: function () {
          return Effect.monadEffect;
      }
  };
  var liftEffect = function (dict) {
      return dict.liftEffect;
  };
  exports["liftEffect"] = liftEffect;
  exports["monadEffectEffect"] = monadEffectEffect;
})(PS);
(function(exports) {
  "use strict";

  exports.log = function (s) {
    return function () {
      console.log(s);
    };
  };
})(PS["Effect.Console"] = PS["Effect.Console"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Effect.Console"] = $PS["Effect.Console"] || {};
  var exports = $PS["Effect.Console"];
  var $foreign = $PS["Effect.Console"];
  exports["log"] = $foreign.log;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Effect.Class.Console"] = $PS["Effect.Class.Console"] || {};
  var exports = $PS["Effect.Class.Console"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var log = function (dictMonadEffect) {
      var $30 = Effect_Class.liftEffect(dictMonadEffect);
      return function ($31) {
          return $30(Effect_Console.log($31));
      };
  };
  exports["log"] = log;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Context"] = $PS["Model.Context"] || {};
  var exports = $PS["Model.Context"];
  var Assess = (function () {
      function Assess() {

      };
      Assess.value = new Assess();
      return Assess;
  })();
  var Practice = (function () {
      function Practice() {

      };
      Practice.value = new Practice();
      return Practice;
  })();
  var fromString = function (v) {
      if (v === "practice") {
          return Practice.value;
      };
      if (v === "assess") {
          return Assess.value;
      };
      return Practice.value;
  };
  var eqContext = function (v) {
      return function (v1) {
          if (v instanceof Assess && v1 instanceof Assess) {
              return true;
          };
          if (v instanceof Practice && v1 instanceof Practice) {
              return true;
          };
          return false;
      };
  };
  var eqContext$prime = {
      eq: eqContext
  };
  exports["fromString"] = fromString;
  exports["eqContext'"] = eqContext$prime;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Focus"] = $PS["Model.Focus"] || {};
  var exports = $PS["Model.Focus"];
  var Challenge = (function () {
      function Challenge() {

      };
      Challenge.value = new Challenge();
      return Challenge;
  })();
  var Weakness = (function () {
      function Weakness() {

      };
      Weakness.value = new Weakness();
      return Weakness;
  })();
  var fromString = function (v) {
      if (v === "Weakness") {
          return Weakness.value;
      };
      if (v === "Challenge") {
          return Challenge.value;
      };
      if (v === "default") {
          return Challenge.value;
      };
      return Challenge.value;
  };
  exports["fromString"] = fromString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Activity"] = $PS["Model.Activity"] || {};
  var exports = $PS["Model.Activity"];
  var Model_Context = $PS["Model.Context"];
  var Model_Focus = $PS["Model.Focus"];
  var jsToPurs = function (amJs) {
      return {
          targetNodeId: amJs.targetNodeId,
          initialDifficulty: amJs.initialDifficulty,
          nodePercentages: amJs.nodePercentages,
          context: Model_Context.fromString(amJs.context),
          focus: Model_Focus.fromString(amJs.focus)
      };
  };
  exports["jsToPurs"] = jsToPurs;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Category"] = $PS["Model.Category"] || {};
  var exports = $PS["Model.Category"];
  var Data_Array = $PS["Data.Array"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Maybe = $PS["Data.Maybe"];                  
  var Root = (function () {
      function Root(value0) {
          this.value0 = value0;
      };
      Root.create = function (value0) {
          return new Root(value0);
      };
      return Root;
  })();
  var Branch = (function () {
      function Branch(value0) {
          this.value0 = value0;
      };
      Branch.create = function (value0) {
          return new Branch(value0);
      };
      return Branch;
  })();
  var Leaf = (function () {
      function Leaf(value0) {
          this.value0 = value0;
      };
      Leaf.create = function (value0) {
          return new Leaf(value0);
      };
      return Leaf;
  })();
  var getRoots = function (catNodes) {
      var justRootOrNothing = function (v) {
          if (v instanceof Root) {
              return new Data_Maybe.Just(v.value0);
          };
          return Data_Maybe.Nothing.value;
      };
      return Data_Array.mapMaybe(justRootOrNothing)(catNodes);
  };
  var getNodeId = function (v) {
      if (v instanceof Root) {
          return v.value0.id;
      };
      if (v instanceof Branch) {
          return v.value0.id;
      };
      if (v instanceof Leaf) {
          return v.value0.id;
      };
      throw new Error("Failed pattern match at Model.Category (line 36, column 1 - line 36, column 31): " + [ v.constructor.name ]);
  };
  var getNode = function (cNodes) {
      return function (nodeId) {
          return Data_Maybe.fromMaybe(new Leaf({
              id: "Node_Not_Found_For_Id:" + nodeId,
              title: "",
              desc: ""
          }))(Data_Array.find((function () {
              var $48 = Data_Eq.eq(Data_Eq.eqString)(nodeId);
              return function ($49) {
                  return $48(getNodeId($49));
              };
          })())(cNodes));
      };
  };
  exports["Root"] = Root;
  exports["Branch"] = Branch;
  exports["Leaf"] = Leaf;
  exports["getNode"] = getNode;
  exports["getRoots"] = getRoots;
})(PS);
(function(exports) {
  "use strict";

  exports.typeOf = function (value) {
    return typeof value;
  };

  exports.tagOf = function (value) {
    return Object.prototype.toString.call(value).slice(8, -1);
  };

  exports.isNull = function (value) {
    return value === null;
  };

  exports.isUndefined = function (value) {
    return value === undefined;
  };

  exports.isArray = Array.isArray || function (value) {
    return Object.prototype.toString.call(value) === "[object Array]";
  };
})(PS["Foreign"] = PS["Foreign"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Foreign"] = $PS["Foreign"] || {};
  var exports = $PS["Foreign"];
  var $foreign = $PS["Foreign"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Show = $PS["Data.Show"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var ForeignError = (function () {
      function ForeignError(value0) {
          this.value0 = value0;
      };
      ForeignError.create = function (value0) {
          return new ForeignError(value0);
      };
      return ForeignError;
  })();
  var TypeMismatch = (function () {
      function TypeMismatch(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      TypeMismatch.create = function (value0) {
          return function (value1) {
              return new TypeMismatch(value0, value1);
          };
      };
      return TypeMismatch;
  })();
  var ErrorAtIndex = (function () {
      function ErrorAtIndex(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ErrorAtIndex.create = function (value0) {
          return function (value1) {
              return new ErrorAtIndex(value0, value1);
          };
      };
      return ErrorAtIndex;
  })();
  var ErrorAtProperty = (function () {
      function ErrorAtProperty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ErrorAtProperty.create = function (value0) {
          return function (value1) {
              return new ErrorAtProperty(value0, value1);
          };
      };
      return ErrorAtProperty;
  })();                                            
  var unsafeFromForeign = Unsafe_Coerce.unsafeCoerce;
  var showForeignError = {
      show: function (v) {
          if (v instanceof ForeignError) {
              return "(ForeignError " + (Data_Show.show(Data_Show.showString)(v.value0) + ")");
          };
          if (v instanceof ErrorAtIndex) {
              return "(ErrorAtIndex " + (Data_Show.show(Data_Show.showInt)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
          };
          if (v instanceof ErrorAtProperty) {
              return "(ErrorAtProperty " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
          };
          if (v instanceof TypeMismatch) {
              return "(TypeMismatch " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(Data_Show.showString)(v.value1) + ")")));
          };
          throw new Error("Failed pattern match at Foreign (line 64, column 1 - line 68, column 89): " + [ v.constructor.name ]);
      }
  };
  var fail = function (dictMonad) {
      var $118 = Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(dictMonad));
      return function ($119) {
          return $118(Data_List_NonEmpty.singleton($119));
      };
  };
  var readArray = function (dictMonad) {
      return function (value) {
          if ($foreign.isArray(value)) {
              return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(dictMonad))(unsafeFromForeign(value));
          };
          if (Data_Boolean.otherwise) {
              return fail(dictMonad)(new TypeMismatch("array", $foreign.tagOf(value)));
          };
          throw new Error("Failed pattern match at Foreign (line 151, column 1 - line 151, column 66): " + [ value.constructor.name ]);
      };
  };
  var unsafeReadTagged = function (dictMonad) {
      return function (tag) {
          return function (value) {
              if ($foreign.tagOf(value) === tag) {
                  return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(dictMonad))(unsafeFromForeign(value));
              };
              if (Data_Boolean.otherwise) {
                  return fail(dictMonad)(new TypeMismatch(tag, $foreign.tagOf(value)));
              };
              throw new Error("Failed pattern match at Foreign (line 110, column 1 - line 110, column 71): " + [ tag.constructor.name, value.constructor.name ]);
          };
      };
  };
  var readNumber = function (dictMonad) {
      return unsafeReadTagged(dictMonad)("Number");
  };
  var readInt = function (dictMonad) {
      return function (value) {
          var error = new Data_Either.Left(Data_List_NonEmpty.singleton(new TypeMismatch("Int", $foreign.tagOf(value))));
          var fromNumber = (function () {
              var $120 = Data_Maybe.maybe(error)(Control_Applicative.pure(Data_Either.applicativeEither));
              return function ($121) {
                  return $120(Data_Int.fromNumber($121));
              };
          })();
          return Control_Monad_Except_Trans.mapExceptT(Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())(Data_Either.either(Data_Function["const"](error))(fromNumber)))(readNumber(dictMonad)(value));
      };
  };
  var readString = function (dictMonad) {
      return unsafeReadTagged(dictMonad)("String");
  };
  exports["ForeignError"] = ForeignError;
  exports["TypeMismatch"] = TypeMismatch;
  exports["ErrorAtIndex"] = ErrorAtIndex;
  exports["ErrorAtProperty"] = ErrorAtProperty;
  exports["readString"] = readString;
  exports["readInt"] = readInt;
  exports["readArray"] = readArray;
  exports["fail"] = fail;
  exports["showForeignError"] = showForeignError;
  exports["typeOf"] = $foreign.typeOf;
  exports["isNull"] = $foreign.isNull;
  exports["isUndefined"] = $foreign.isUndefined;
})(PS);
(function(exports) {
  exports._parseJSON = JSON.parse;
})(PS["Simple.JSON"] = PS["Simple.JSON"] || {});
(function(exports) {
  "use strict";

  exports.message = function (e) {
    return e.message;
  };

  exports.catchException = function (c) {
    return function (t) {
      return function () {
        try {
          return t();
        } catch (e) {
          if (e instanceof Error || Object.prototype.toString.call(e) === "[object Error]") {
            return c(e)();
          } else {
            return c(new Error(e.toString()))();
          }
        }
      };
    };
  };
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Effect.Exception"] = $PS["Effect.Exception"] || {};
  var exports = $PS["Effect.Exception"];
  var $foreign = $PS["Effect.Exception"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];                
  var $$try = function (action) {
      return $foreign.catchException((function () {
          var $0 = Control_Applicative.pure(Effect.applicativeEffect);
          return function ($1) {
              return $0(Data_Either.Left.create($1));
          };
      })())(Data_Functor.map(Effect.functorEffect)(Data_Either.Right.create)(action));
  };
  exports["try"] = $$try;
  exports["message"] = $foreign.message;
})(PS);
(function(exports) {
  "use strict";

  exports.runEffectFn1 = function runEffectFn1(fn) {
    return function(a) {
      return function() {
        return fn(a);
      };
    };
  };
})(PS["Effect.Uncurried"] = PS["Effect.Uncurried"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Effect.Uncurried"] = $PS["Effect.Uncurried"] || {};
  var exports = $PS["Effect.Uncurried"];
  var $foreign = $PS["Effect.Uncurried"];
  exports["runEffectFn1"] = $foreign.runEffectFn1;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafePerformEffect = function (f) {
    return f();
  };
})(PS["Effect.Unsafe"] = PS["Effect.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Effect.Unsafe"] = $PS["Effect.Unsafe"] || {};
  var exports = $PS["Effect.Unsafe"];
  var $foreign = $PS["Effect.Unsafe"];
  exports["unsafePerformEffect"] = $foreign.unsafePerformEffect;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeReadPropImpl = function (f, s, key, value) {
    return value == null ? f : s(value[key]);
  };
})(PS["Foreign.Index"] = PS["Foreign.Index"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Foreign.Index"] = $PS["Foreign.Index"] || {};
  var exports = $PS["Foreign.Index"];
  var $foreign = $PS["Foreign.Index"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Foreign = $PS["Foreign"];                
  var unsafeReadProp = function (dictMonad) {
      return function (k) {
          return function (value) {
              return $foreign.unsafeReadPropImpl(Foreign.fail(dictMonad)(new Foreign.TypeMismatch("object", Foreign.typeOf(value))), Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(dictMonad)), k, value);
          };
      };
  };
  var readProp = function (dictMonad) {
      return unsafeReadProp(dictMonad);
  };
  exports["readProp"] = readProp;
})(PS);
(function(exports) {
  "use strict";

  exports.copyRecord = function(rec) {
    var copy = {};
    for (var key in rec) {
      if ({}.hasOwnProperty.call(rec, key)) {
        copy[key] = rec[key];
      }
    }
    return copy;
  };

  exports.unsafeInsert = function(l) {
    return function(a) {
      return function(rec) {
        rec[l] = a;
        return rec;
      };
    };
  };
})(PS["Record.Builder"] = PS["Record.Builder"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Record.Builder"] = $PS["Record.Builder"] || {};
  var exports = $PS["Record.Builder"];
  var $foreign = $PS["Record.Builder"];
  var Control_Category = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Symbol = $PS["Data.Symbol"];
  var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;
  var insert = function () {
      return function () {
          return function (dictIsSymbol) {
              return function (l) {
                  return function (a) {
                      return function (r1) {
                          return $foreign.unsafeInsert(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r1);
                      };
                  };
              };
          };
      };
  };
  var categoryBuilder = Control_Category.categoryFn;
  var build = function (v) {
      return function (r1) {
          return v($foreign.copyRecord(r1));
      };
  };
  exports["build"] = build;
  exports["insert"] = insert;
  exports["semigroupoidBuilder"] = semigroupoidBuilder;
  exports["categoryBuilder"] = categoryBuilder;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Simple.JSON"] = $PS["Simple.JSON"] || {};
  var exports = $PS["Simple.JSON"];
  var $foreign = $PS["Simple.JSON"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Except = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_TraversableWithIndex = $PS["Data.TraversableWithIndex"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Uncurried = $PS["Effect.Uncurried"];
  var Effect_Unsafe = $PS["Effect.Unsafe"];
  var Foreign = $PS["Foreign"];
  var Foreign_Index = $PS["Foreign.Index"];
  var Record_Builder = $PS["Record.Builder"];
  var Type_Proxy = $PS["Type.Proxy"];
  var readString = {
      readImpl: Foreign.readString(Data_Identity.monadIdentity)
  };
  var readInt = {
      readImpl: Foreign.readInt(Data_Identity.monadIdentity)
  };
  var readImpl = function (dict) {
      return dict.readImpl;
  };
  var readMaybe = function (dictReadForeign) {
      return {
          readImpl: (function () {
              var readNullOrUndefined = function (v) {
                  return function (value) {
                      if (Foreign.isNull(value) || Foreign.isUndefined(value)) {
                          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
                      };
                      return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Maybe.Just.create)(v(value));
                  };
              };
              return readNullOrUndefined(readImpl(dictReadForeign));
          })()
      };
  };
  var readFieldsNil = {
      getFields: function (v) {
          return function (v1) {
              return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Control_Category.identity(Record_Builder.categoryBuilder));
          };
      }
  };
  var readArray = function (dictReadForeign) {
      return {
          readImpl: (function () {
              var readAtIdx = function (i) {
                  return function (f) {
                      return Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Foreign.ErrorAtIndex.create(i)))(readImpl(dictReadForeign)(f));
                  };
              };
              return Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Data_TraversableWithIndex.traverseWithIndex(Data_TraversableWithIndex.traversableWithIndexArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(readAtIdx))(Foreign.readArray(Data_Identity.monadIdentity));
          })()
      };
  };
  var parseJSON = (function () {
      var $97 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)((function () {
          var $100 = Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList);
          return function ($101) {
              return $100(Foreign.ForeignError.create(Effect_Exception.message($101)));
          };
      })());
      var $98 = Effect_Uncurried.runEffectFn1($foreign["_parseJSON"]);
      return function ($99) {
          return Control_Monad_Except_Trans.ExceptT(Data_Identity.Identity($97(Effect_Unsafe.unsafePerformEffect(Effect_Exception["try"]($98($99))))));
      };
  })();
  var readJSON = function (dictReadForeign) {
      var $102 = Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictReadForeign))(parseJSON);
      return function ($103) {
          return Control_Monad_Except.runExcept($102($103));
      };
  };
  var getFields = function (dict) {
      return dict.getFields;
  };
  var readRecord = function () {
      return function (dictReadForeignFields) {
          return {
              readImpl: function (o) {
                  return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Function.flip(Record_Builder.build)({}))(getFields(dictReadForeignFields)(Type_Proxy["Proxy"].value)(o));
              }
          };
      };
  };
  var applyEither = function (dictSemigroup) {
      return function (v) {
          return function (v1) {
              if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Right) {
                  return new Data_Either.Left(v.value0);
              };
              if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                  return new Data_Either.Left(Data_Semigroup.append(dictSemigroup)(v.value0)(v1.value0));
              };
              if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Left) {
                  return new Data_Either.Left(v1.value0);
              };
              if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Right) {
                  return new Data_Either.Right(v.value0(v1.value0));
              };
              throw new Error("Failed pattern match at Simple.JSON (line 241, column 1 - line 241, column 90): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var exceptTApply = function (dictSemigroup) {
      return function (dictApplicative) {
          return function (fun) {
              return function (a) {
                  return Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(applyEither(dictSemigroup))(Control_Monad_Except_Trans.runExceptT(fun)))(Control_Monad_Except_Trans.runExceptT(a));
              };
          };
      };
  };
  var readFieldsCons = function (dictIsSymbol) {
      return function (dictReadForeign) {
          return function (dictReadForeignFields) {
              return function () {
                  return function () {
                      return {
                          getFields: function (v) {
                              return function (obj) {
                                  var rest = getFields(dictReadForeignFields)(Type_Proxy["Proxy"].value)(obj);
                                  var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                                  var withExcept$prime = Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Foreign.ErrorAtProperty.create(name)));
                                  var first = Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(withExcept$prime(Control_Bind.bindFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictReadForeign))(Foreign_Index.readProp(Data_Identity.monadIdentity)(name)(obj))))(function (value) {
                                      return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(value));
                                  });
                                  return exceptTApply(Data_List_Types.semigroupNonEmptyList)(Data_Identity.applicativeIdentity)(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder))(first))(rest);
                              };
                          }
                      };
                  };
              };
          };
      };
  };
  exports["readJSON"] = readJSON;
  exports["readInt"] = readInt;
  exports["readString"] = readString;
  exports["readArray"] = readArray;
  exports["readMaybe"] = readMaybe;
  exports["readRecord"] = readRecord;
  exports["readFieldsCons"] = readFieldsCons;
  exports["readFieldsNil"] = readFieldsNil;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Category.DecodeJSON"] = $PS["Model.Category.DecodeJSON"] || {};
  var exports = $PS["Model.Category.DecodeJSON"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Show = $PS["Data.Show"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Foreign = $PS["Foreign"];
  var Model_Category = $PS["Model.Category"];
  var Simple_JSON = $PS["Simple.JSON"];                
  var transformDecodeFmt = function (decodeFmt) {
      var makeRec = function (el) {
          return {
              id: el.id,
              desc: el.desc,
              title: el.title,
              children: Data_Maybe.fromMaybe([  ])(el.children)
          };
      };
      var transformCat = function (cat) {
          if (cat.nodetype === "root") {
              return Control_Applicative.pure(Data_Either.applicativeEither)(new Model_Category.Root(makeRec(cat)));
          };
          if (cat.nodetype === "branch") {
              return Control_Applicative.pure(Data_Either.applicativeEither)(new Model_Category.Branch(makeRec(cat)));
          };
          if (cat.nodetype === "leaf") {
              return Control_Applicative.pure(Data_Either.applicativeEither)(new Model_Category.Leaf({
                  id: cat.id,
                  desc: cat.desc,
                  title: cat.title
              }));
          };
          return new Data_Either.Left("Could not decode the category with a nodetype of " + cat.nodetype);
      };
      return Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Either.applicativeEither)(transformCat)(decodeFmt);
  };
  var makeCategories = function (json) {
      var v = Simple_JSON.readJSON(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "children";
          }
      })(Simple_JSON.readMaybe(Simple_JSON.readArray(Simple_JSON.readString)))(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "desc";
          }
      })(Simple_JSON.readString)(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "id";
          }
      })(Simple_JSON.readString)(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "nodetype";
          }
      })(Simple_JSON.readString)(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "title";
          }
      })(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())))(json);
      if (v instanceof Data_Either.Right) {
          return transformDecodeFmt(v.value0);
      };
      if (v instanceof Data_Either.Left) {
          return new Data_Either.Left("Could not decode the incoming categories: " + Data_Show.show(Foreign.showForeignError)(Data_List_NonEmpty.head(v.value0)));
      };
      throw new Error("Failed pattern match at Model.Category.DecodeJSON (line 62, column 3 - line 66, column 75): " + [ v.constructor.name ]);
  };
  exports["makeCategories"] = makeCategories;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.History"] = $PS["Model.History"] || {};
  var exports = $PS["Model.History"];
  var Correct = (function () {
      function Correct() {

      };
      Correct.value = new Correct();
      return Correct;
  })();
  var Incorrect = (function () {
      function Incorrect() {

      };
      Incorrect.value = new Incorrect();
      return Incorrect;
  })();
  var NotAnswered = (function () {
      function NotAnswered() {

      };
      NotAnswered.value = new NotAnswered();
      return NotAnswered;
  })();
  var resultFromString = function (v) {
      if (v === "c") {
          return Correct.value;
      };
      if (v === "i") {
          return Incorrect.value;
      };
      if (v === "n") {
          return NotAnswered.value;
      };
      return NotAnswered.value;
  };
  var jsToPurs = function (hItemJs) {
      return {
          variantId: hItemJs.variantId,
          result: resultFromString(hItemJs.result)
      };
  };
  var isCorrect = function (v) {
      if (v instanceof Correct) {
          return true;
      };
      return false;
  };
  exports["isCorrect"] = isCorrect;
  exports["jsToPurs"] = jsToPurs;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Item"] = $PS["Model.Item"] || {};
  var exports = $PS["Model.Item"];                 
  var MCSA = (function () {
      function MCSA() {

      };
      MCSA.value = new MCSA();
      return MCSA;
  })();
  var MCMA = (function () {
      function MCMA() {

      };
      MCMA.value = new MCMA();
      return MCMA;
  })();
  var ANSBOX = (function () {
      function ANSBOX() {

      };
      ANSBOX.value = new ANSBOX();
      return ANSBOX;
  })();
  var NOT_IMPLEMENTED = (function () {
      function NOT_IMPLEMENTED() {

      };
      NOT_IMPLEMENTED.value = new NOT_IMPLEMENTED();
      return NOT_IMPLEMENTED;
  })();
  exports["MCSA"] = MCSA;
  exports["MCMA"] = MCMA;
  exports["ANSBOX"] = ANSBOX;
  exports["NOT_IMPLEMENTED"] = NOT_IMPLEMENTED;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Item.DecodeJSON"] = $PS["Model.Item.DecodeJSON"] || {};
  var exports = $PS["Model.Item.DecodeJSON"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_CodePoints = $PS["Data.String.CodePoints"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Foreign = $PS["Foreign"];
  var Model_Item = $PS["Model.Item"];
  var Simple_JSON = $PS["Simple.JSON"];                
  var transformDecodeFmtToItemMetas = function (decodeFmt) {
      var makeItemURL = function (slug) {
          var noSlashSlug = Data_String_CodePoints.take(Data_String_CodePoints.length(slug) - 1 | 0)(slug);
          var itemURLTail = Data_String_Common.replace("/item/")("/item/entries/")(noSlashSlug);
          return "http://cms.smarterschool.com.au/admin/#/collections" + itemURLTail;
      };
      var transformItem = function (it) {
          if (it.qType === "Multiple Choice (One Answer)") {
              return Control_Applicative.pure(Data_Either.applicativeEither)({
                  answerSets: it.answersets,
                  questionType: Model_Item.MCSA.value,
                  slug: makeItemURL(it.slug),
                  smuid: it.smuid,
                  title: it.title
              });
          };
          if (it.qType === "Multiple Choice (Multiple Answer)") {
              return Control_Applicative.pure(Data_Either.applicativeEither)({
                  answerSets: it.answersets,
                  questionType: Model_Item.MCMA.value,
                  slug: makeItemURL(it.slug),
                  smuid: it.smuid,
                  title: it.title
              });
          };
          if (it.qType === "Answer Box") {
              return Control_Applicative.pure(Data_Either.applicativeEither)({
                  answerSets: it.answersets,
                  questionType: Model_Item.ANSBOX.value,
                  slug: makeItemURL(it.slug),
                  smuid: it.smuid,
                  title: it.title
              });
          };
          return new Data_Either.Left("Could not decode the item " + (it.title + (", " + (it.slug + (", with a question type of " + it.qType)))));
      };
      return Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Either.applicativeEither)(transformItem)(decodeFmt);
  };
  var makeItems = function (json) {
      var v = Simple_JSON.readJSON(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "answersets";
          }
      })(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "categories";
          }
      })(Simple_JSON.readArray(Simple_JSON.readString))(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "context";
          }
      })(Simple_JSON.readString)(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "difficulty";
          }
      })(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()())()())))(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "qType";
          }
      })(Simple_JSON.readString)(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "slug";
          }
      })(Simple_JSON.readString)(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "smuid";
          }
      })(Simple_JSON.readString)(Simple_JSON.readFieldsCons({
          reflectSymbol: function () {
              return "title";
          }
      })(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())))(json);
      if (v instanceof Data_Either.Right) {
          return transformDecodeFmtToItemMetas(v.value0);
      };
      if (v instanceof Data_Either.Left) {
          return new Data_Either.Left("Could not decode the incoming items: " + Data_Show.show(Foreign.showForeignError)(Data_List_NonEmpty.head(v.value0)));
      };
      throw new Error("Failed pattern match at Model.Item.DecodeJSON (line 84, column 3 - line 88, column 70): " + [ v.constructor.name ]);
  };
  exports["makeItems"] = makeItems;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Variant"] = $PS["Model.Variant"] || {};
  var exports = $PS["Model.Variant"];
  var Data_Array = $PS["Data.Array"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ring = $PS["Data.Ring"];
  var Model_Context = $PS["Model.Context"];                
  var MCSAMeta = (function () {
      function MCSAMeta(value0) {
          this.value0 = value0;
      };
      MCSAMeta.create = function (value0) {
          return new MCSAMeta(value0);
      };
      return MCSAMeta;
  })();
  var Novel = (function () {
      function Novel() {

      };
      Novel.value = new Novel();
      return Novel;
  })();
  var AlternateVariant = (function () {
      function AlternateVariant() {

      };
      AlternateVariant.value = new AlternateVariant();
      return AlternateVariant;
  })();
  var BeenSeen = (function () {
      function BeenSeen() {

      };
      BeenSeen.value = new BeenSeen();
      return BeenSeen;
  })();
  var showSelectionType = function (v) {
      if (v instanceof Novel) {
          return "Novel";
      };
      if (v instanceof AlternateVariant) {
          return "AlternateVariant";
      };
      if (v instanceof BeenSeen) {
          return "BeenSeen";
      };
      throw new Error("Failed pattern match at Model.Variant (line 68, column 1 - line 68, column 45): " + [ v.constructor.name ]);
  };
  var showSelectionType$prime = {
      show: showSelectionType
  };
  var getVariantDef = function (v) {
      return v.value0;
  };
  var getVariantMeta = function (variantMetas) {
      return function (variantId) {
          return Data_Array.find(function (el) {
              return Data_Eq.eq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                  reflectSymbol: function () {
                      return "vId";
                  }
              })(Data_Eq.eqInt))()({
                  reflectSymbol: function () {
                      return "smuid";
                  }
              })(Data_Eq.eqString)))(variantId)((getVariantDef(el)).vId);
          })(variantMetas);
      };
  };
  var isElem = function (variantId) {
      return function (variantMetas) {
          return Data_Maybe.isJust(getVariantMeta(variantMetas)(variantId));
      };
  };
  var sortWithTargetStrike = function (targetStrike) {
      return function (variantMetas) {
          return Data_Array.sortWith(Data_Ord.ordInt)(function (el) {
              return Data_Ord.abs(Data_Ord.ordInt)(Data_Ring.ringInt)(targetStrike - (getVariantDef(el)).diff | 0);
          })(variantMetas);
      };
  };
  var eqVariantMeta = function (v) {
      return function (v1) {
          return Data_Eq.eq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
              reflectSymbol: function () {
                  return "vId";
              }
          })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
              reflectSymbol: function () {
                  return "vId";
              }
          })(Data_Eq.eqInt))()({
              reflectSymbol: function () {
                  return "smuid";
              }
          })(Data_Eq.eqString))))()({
              reflectSymbol: function () {
                  return "diff";
              }
          })(Data_Eq.eqInt))()({
              reflectSymbol: function () {
                  return "ctx";
              }
          })(Model_Context["eqContext'"])))(v.value0)(v1.value0);
      };
  };
  var eqVariantMeta$prime = {
      eq: eqVariantMeta
  }; 
  var compareVariantMeta = function (v) {
      return function (v1) {
          return Data_Ord.compare(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
              reflectSymbol: function () {
                  return "vId";
              }
          })(Data_Ord.ordInt))()({
              reflectSymbol: function () {
                  return "smuid";
              }
          })(Data_Ord.ordString)))(v.value0.vId)(v1.value0.vId);
      };
  };
  var ordVariantMeta$prime = {
      compare: compareVariantMeta,
      Eq0: function () {
          return eqVariantMeta$prime;
      }
  };
  exports["Novel"] = Novel;
  exports["AlternateVariant"] = AlternateVariant;
  exports["BeenSeen"] = BeenSeen;
  exports["MCSAMeta"] = MCSAMeta;
  exports["getVariantDef"] = getVariantDef;
  exports["sortWithTargetStrike"] = sortWithTargetStrike;
  exports["isElem"] = isElem;
  exports["getVariantMeta"] = getVariantMeta;
  exports["showSelectionType'"] = showSelectionType$prime;
  exports["ordVariantMeta'"] = ordVariantMeta$prime;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Node"] = $PS["Model.Node"] || {};
  var exports = $PS["Model.Node"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Array = $PS["Data.Array"];
  var Data_EuclideanRing = $PS["Data.EuclideanRing"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Semiring = $PS["Data.Semiring"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Model_Variant = $PS["Model.Variant"];                
  var BranchNode = (function () {
      function BranchNode(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      BranchNode.create = function (value0) {
          return function (value1) {
              return new BranchNode(value0, value1);
          };
      };
      return BranchNode;
  })();
  var LeafNode = (function () {
      function LeafNode(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      LeafNode.create = function (value0) {
          return function (value1) {
              return new LeafNode(value0, value1);
          };
      };
      return LeafNode;
  })();
  var makeLeafNode = function (nodeInfo) {
      return function (variantMetas) {
          return new LeafNode(nodeInfo, variantMetas);
      };
  };
  var makeBranchNode = function (nodeInfo) {
      return function (childNodes) {
          return new BranchNode(nodeInfo, childNodes);
      };
  };
  var getVariantMetasBelowNode = function (v) {
      return function (v1) {
          if (v instanceof Data_Maybe.Nothing && v1 instanceof BranchNode) {
              return Data_Array.concatMap(getVariantMetasBelowNode(Data_Maybe.Nothing.value))(v1.value1);
          };
          if (v instanceof Data_Maybe.Just && v1 instanceof BranchNode) {
              return Model_Variant.sortWithTargetStrike(v.value0)(Data_Array.concatMap(getVariantMetasBelowNode(Data_Maybe.Nothing.value))(v1.value1));
          };
          if (v instanceof Data_Maybe.Nothing && v1 instanceof LeafNode) {
              return v1.value1;
          };
          if (v instanceof Data_Maybe.Just && v1 instanceof LeafNode) {
              return Model_Variant.sortWithTargetStrike(v.value0)(v1.value1);
          };
          throw new Error("Failed pattern match at Model.Node (line 191, column 1 - line 191, column 62): " + [ v.constructor.name, v1.constructor.name ]);
      };
  };
  var isVariantUnder = function (variantId) {
      return function (node) {
          return Model_Variant.isElem(variantId)(getVariantMetasBelowNode(Data_Maybe.Nothing.value)(node));
      };
  };
  var getNodeInfo = function (v) {
      if (v instanceof BranchNode) {
          return v.value0;
      };
      if (v instanceof LeafNode) {
          return v.value0;
      };
      throw new Error("Failed pattern match at Model.Node (line 275, column 1 - line 275, column 32): " + [ v.constructor.name ]);
  };
  var splitNode$prime = function (v) {
      return function (v1) {
          if (v1.value0 instanceof Data_Maybe.Just && (v1.value0.value0 instanceof BranchNode && v1.value1 instanceof Data_Maybe.Nothing)) {
              var v2 = Data_Array.partition(function (n) {
                  return (getNodeInfo(n)).id === v;
              })(v1.value0.value0.value1);
              var $51 = Data_Array["null"](v2.yes);
              if ($51) {
                  var childNodeArray = Data_Functor.map(Data_Functor.functorArray)(function (n) {
                      return splitNode$prime(v)(new Data_Tuple.Tuple(new Data_Maybe.Just(n), Data_Maybe.Nothing.value));
                  })(v2.no);
                  var newChildren = Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(childNodeArray));
                  var targetNodeMaybe = Data_Array.head(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd)(childNodeArray)));
                  return new Data_Tuple.Tuple(new Data_Maybe.Just(new BranchNode(v1.value0.value0.value0, newChildren)), targetNodeMaybe);
              };
              return new Data_Tuple.Tuple(new Data_Maybe.Just(new BranchNode(v1.value0.value0.value0, v2.no)), Data_Array.head(v2.yes));
          };
          return v1;
      };
  };
  var splitNode = function (targetNodeId) {
      return function (node) {
          return splitNode$prime(targetNodeId)(new Data_Tuple.Tuple(new Data_Maybe.Just(node), Data_Maybe.Nothing.value));
      };
  };
  var sortNodeToVariantMetas$prime = function (node) {
      return function (targetStrike) {
          return function (nodePercentages) {
              var reverseOrderedNodePercentages = Data_Array.reverse(Data_Array.sortWith(Data_Ord.ordInt)(function (v) {
                  return v.pc;
              })(nodePercentages));
              var v = Data_Array.uncons(reverseOrderedNodePercentages);
              if (v instanceof Data_Maybe.Just) {
                  var splitNodeTuple = splitNode(v.value0.head.nodeId)(node);
                  if (splitNodeTuple.value0 instanceof Data_Maybe.Just && splitNodeTuple.value1 instanceof Data_Maybe.Just) {
                      return Data_Semigroup.append(Data_Semigroup.semigroupArray)(getVariantMetasBelowNode(new Data_Maybe.Just(targetStrike))(splitNodeTuple.value1.value0))(sortNodeToVariantMetas$prime(splitNodeTuple.value0.value0)(targetStrike)(v.value0.tail));
                  };
                  if (splitNodeTuple.value0 instanceof Data_Maybe.Just && splitNodeTuple.value1 instanceof Data_Maybe.Nothing) {
                      return sortNodeToVariantMetas$prime(splitNodeTuple.value0.value0)(targetStrike)(v.value0.tail);
                  };
                  if (splitNodeTuple.value0 instanceof Data_Maybe.Nothing && splitNodeTuple.value1 instanceof Data_Maybe.Just) {
                      return getVariantMetasBelowNode(new Data_Maybe.Just(targetStrike))(splitNodeTuple.value1.value0);
                  };
                  if (splitNodeTuple.value0 instanceof Data_Maybe.Nothing && splitNodeTuple.value1 instanceof Data_Maybe.Nothing) {
                      return getVariantMetasBelowNode(new Data_Maybe.Just(targetStrike))(node);
                  };
                  throw new Error("Failed pattern match at Model.Node (line 368, column 8 - line 384, column 61): " + [ splitNodeTuple.constructor.name ]);
              };
              if (v instanceof Data_Maybe.Nothing) {
                  return getVariantMetasBelowNode(new Data_Maybe.Just(targetStrike))(node);
              };
              throw new Error("Failed pattern match at Model.Node (line 360, column 3 - line 387, column 56): " + [ v.constructor.name ]);
          };
      };
  };
  var sortNodeToVariantMetasDeduped = function (node) {
      return function (targetStrike) {
          return function (nodePercentages) {
              return Data_Array.nub(Model_Variant["ordVariantMeta'"])(sortNodeToVariantMetas$prime(node)(targetStrike)(nodePercentages));
          };
      };
  };
  var getNode = function (targetNodeId) {
      return function (v) {
          if (v instanceof BranchNode) {
              var $78 = v.value0.id === targetNodeId;
              if ($78) {
                  return new Data_Maybe.Just(new BranchNode(v.value0, v.value1));
              };
              return Data_Array.findMap(getNode(targetNodeId))(v.value1);
          };
          if (v instanceof LeafNode) {
              var $81 = v.value0.id === targetNodeId;
              if ($81) {
                  return new Data_Maybe.Just(new LeafNode(v.value0, v.value1));
              };
              return Data_Maybe.Nothing.value;
          };
          throw new Error("Failed pattern match at Model.Node (line 229, column 1 - line 229, column 40): " + [ targetNodeId.constructor.name, v.constructor.name ]);
      };
  };
  var isChildOf = function (rootNode) {
      return function (nodeId) {
          return function (variantId) {
              return Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(isVariantUnder(variantId))(getNode(nodeId)(rootNode)));
          };
      };
  };
  var filterVariantMetasBelowNode = function (f) {
      return function (v) {
          if (v instanceof BranchNode) {
              return new BranchNode(v.value0, Data_Functor.map(Data_Functor.functorArray)(filterVariantMetasBelowNode(f))(v.value1));
          };
          if (v instanceof LeafNode) {
              return new LeafNode(v.value0, Data_Array.filter(f)(v.value1));
          };
          throw new Error("Failed pattern match at Model.Node (line 170, column 1 - line 170, column 72): " + [ f.constructor.name, v.constructor.name ]);
      };
  };
  var filterSubTree = function (context) {
      return filterVariantMetasBelowNode(Data_Function["const"](true));
  };
  var adjustNodePercentages = function (rootNode) {
      return function (nodePercentages) {
          return function (hstry) {
              var sumTally = function (startNodeCounts) {
                  return function (variantId) {
                      return Data_Maybe.fromMaybe(startNodeCounts)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.findIndex(function (el) {
                          return isChildOf(rootNode)(el.nodeId)(variantId);
                      })(startNodeCounts))(function (el) {
                          return Data_Array.modifyAt(el)(function (v) {
                              return {
                                  nodeId: v.nodeId,
                                  count: v.count + 1 | 0
                              };
                          })(startNodeCounts);
                      }));
                  };
              };
              var scaledNodePercentages = function (adjNodePercentages$prime) {
                  var totalAdjPc = Data_Array.foldl(Data_Semiring.add(Data_Semiring.semiringInt))(0)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                      return v.pc;
                  })(adjNodePercentages$prime));
                  return Data_Functor.map(Data_Functor.functorArray)(function (v) {
                      return {
                          nodeId: v.nodeId,
                          pc: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v.pc * 100 | 0)(totalAdjPc)
                      };
                  })(adjNodePercentages$prime);
              };
              var createEmptyTally = function (v) {
                  return {
                      nodeId: v.nodeId,
                      count: 0
                  };
              };
              var adjNodePercentages = (function () {
                  var targetPcForNode = function (nodeId) {
                      return Data_Maybe.fromMaybe(0)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                          return v.pc;
                      })(Data_Array.find(function (el) {
                          return el.nodeId === nodeId;
                      })(nodePercentages)));
                  };
                  var emptyTally = Data_Functor.map(Data_Functor.functorArray)(createEmptyTally)(nodePercentages);
                  var sTally = Data_Array.foldl(sumTally)(emptyTally)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                      return v.variantId;
                  })(hstry));
                  var totalCountForNodeId = function (nodeId) {
                      return Data_Array.foldl(Data_Semiring.add(Data_Semiring.semiringInt))(0)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                          return v.count;
                      })(Data_Array.filter(function (el) {
                          return el.nodeId === nodeId;
                      })(sTally)));
                  };
                  return Data_Functor.map(Data_Functor.functorArray)(function (v) {
                      return {
                          nodeId: v.nodeId,
                          pc: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(targetPcForNode(v.nodeId))(totalCountForNodeId(v.nodeId) + 1 | 0)
                      };
                  })(sTally);
              })();
              return scaledNodePercentages(adjNodePercentages);
          };
      };
  };
  exports["makeBranchNode"] = makeBranchNode;
  exports["makeLeafNode"] = makeLeafNode;
  exports["filterSubTree"] = filterSubTree;
  exports["getVariantMetasBelowNode"] = getVariantMetasBelowNode;
  exports["getNode"] = getNode;
  exports["sortNodeToVariantMetasDeduped"] = sortNodeToVariantMetasDeduped;
  exports["adjustNodePercentages"] = adjustNodePercentages;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Question.DecodeJSON"] = $PS["Model.Question.DecodeJSON"] || {};
  var exports = $PS["Model.Question.DecodeJSON"];
  var Data_Either = $PS["Data.Either"];
  var Simple_JSON = $PS["Simple.JSON"];                
  var jsToPurs = function (json) {
      return Data_Either.hush(Simple_JSON.readJSON(Simple_JSON.readInt)(json));
  };
  exports["jsToPurs"] = jsToPurs;
})(PS);
(function(exports) {
  "use strict";

  exports.random = Math.random;
})(PS["Effect.Random"] = PS["Effect.Random"] || {});
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Effect.Random"] = $PS["Effect.Random"] || {};
  var exports = $PS["Effect.Random"];
  var $foreign = $PS["Effect.Random"];
  exports["random"] = $foreign.random;
})(PS);
(function($PS) {
  "use strict";
  $PS["Model.Question"] = $PS["Model.Question"] || {};
  var exports = $PS["Model.Question"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Reader_Class = $PS["Control.Monad.Reader.Class"];
  var Control_Monad_Reader_Trans = $PS["Control.Monad.Reader.Trans"];
  var Data_Array = $PS["Data.Array"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Model_History = $PS["Model.History"];
  var Model_Node = $PS["Model.Node"];
  var Model_Variant = $PS["Model.Variant"];                
  var pickWithRand = function (rand) {
      return function (subsetVariantMetas) {
          return Data_Array.index(subsetVariantMetas)(Data_Int.round(Data_Int.toNumber(Data_Array.length(subsetVariantMetas) - 1 | 0) * rand));
      };
  };
  var nextVFT = function (v) {
      if (v instanceof Model_Variant.Novel) {
          return Model_Variant.AlternateVariant.value;
      };
      if (v instanceof Model_Variant.AlternateVariant) {
          return Model_Variant.BeenSeen.value;
      };
      if (v instanceof Model_Variant.BeenSeen) {
          return Model_Variant.BeenSeen.value;
      };
      throw new Error("Failed pattern match at Model.Question (line 269, column 1 - line 269, column 50): " + [ v.constructor.name ]);
  };
  var nbrLastFourCorrect = function (hstry) {
      return Data_Array.length(Data_Array.filter(Model_History.isCorrect)(Data_Array.takeEnd(4)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
          return v.result;
      })(hstry))));
  };

  // Check if a question has not been asked before either exactly or in a similar variant of the question
  var isNovelForSession = function (hstry) {
      return function (variantMeta) {
          return Data_Array.notElem(Data_Eq.eqString)((Model_Variant.getVariantDef(variantMeta)).vId.smuid)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
              return v.variantId.smuid;
          })(hstry));
      };
  };
  var isFinalVFT = function (v) {
      if (v instanceof Model_Variant.BeenSeen) {
          return true;
      };
      return false;
  };

  // Check if this exact question has been previously asked
  var isBeenSeen = function (hstry) {
      return function (variantMeta) {
          return Data_Array.elem(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
              reflectSymbol: function () {
                  return "vId";
              }
          })(Data_Eq.eqInt))()({
              reflectSymbol: function () {
                  return "smuid";
              }
          })(Data_Eq.eqString)))((Model_Variant.getVariantDef(variantMeta)).vId)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
              return v.variantId;
          })(hstry));
      };
  };
  var initialVFT = Model_Variant.Novel.value;
  var getSessionHistory = function (sessionQNbr) {
      return function (hstry) {
          return Data_Array.takeEnd(sessionQNbr - 1 | 0)(hstry);
      };
  };
  var getPriorHistory = function (sessionQNbr) {
      return function (hstry) {
          return Data_Array.take(Data_Array.length(hstry) - (sessionQNbr - 1 | 0) | 0)(hstry);
      };
  };
  var getNextVariantSelection = function (initialTargetStrike) {
      return function (sortedVariantMetas) {
          return function (hstry) {
              return function (rand) {
                  return function (targetStrike) {
                      return function (variantFilterType) {
                          return function (currTolerance) {
                              var pickWithRand$prime = pickWithRand(rand);
                              var getNextVariantSelection$prime = getNextVariantSelection(initialTargetStrike)(sortedVariantMetas)(hstry)(rand)(targetStrike);
                              return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Data_Identity.monadIdentity)))(function (v) {
                                
                                  // _ = trace ("subsetVariantMetasInclAlreadyAskedi: " <> show subsetVariantMetasInclAlreadyAsked<> " with currTolerance: " <> show currTolerance) (\_ -> unit)
  var sessionHistory = getSessionHistory(v.sessionQNbr)(hstry);
                                
                                  //_ = trace ("sessionHistory: " <> show sessionHistory) (\_ -> unit)
  var priorHistory = getPriorHistory(v.sessionQNbr)(hstry);
                                
                                  // Notice that currently, we remove existing 
                                  // isInCurrToleranceBand :: VariantMeta -> Boolean
                                  // isInCurrToleranceBand variantMeta =
                                  //   let
                                  //     vDef = getVariantDef variantMeta
                                  //   in
                                  //     ( (vDef.diff <= targetStrike + currTolerance)
                                  //         -- && (vDef.diff >= targetStrike - (round $ (toNumber currTolerance) / 2.0)) -- &&
                                  //         && (vDef.diff >= targetStrike - currTolerance)
                                  //     )
  var isInToleranceBand = function (tolerance) {
                                      return function (variantMeta) {
                                          var vDef = Model_Variant.getVariantDef(variantMeta);
                                          return vDef.diff <= (targetStrike + tolerance | 0) && vDef.diff >= (targetStrike - tolerance | 0);
                                      };
                                  };
                                  var subsetVariantMetasInclAlreadyAsked = Data_Array.filter(isInToleranceBand(currTolerance))(sortedVariantMetas);
                                  var variantMetasFilteredByFilterType = (function () {
                                      if (variantFilterType instanceof Model_Variant.Novel) {
                                          return Data_Array.filter(Data_HeytingAlgebra.conj(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isNovelForSession(sessionHistory))(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(isBeenSeen)(priorHistory)))(subsetVariantMetasInclAlreadyAsked);
                                      };
                                      if (variantFilterType instanceof Model_Variant.AlternateVariant) {
                                          return Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(isBeenSeen)(sessionHistory))(subsetVariantMetasInclAlreadyAsked);
                                      };
                                      if (variantFilterType instanceof Model_Variant.BeenSeen) {
                                          return Data_Array.filter(isInToleranceBand(v.maxToleranceDistance))(sortedVariantMetas);
                                      };
                                      throw new Error("Failed pattern match at Model.Question (line 320, column 7 - line 326, column 87): " + [ variantFilterType.constructor.name ]);
                                  })();
                                
                                  // _ -> filter (isBeenSeen sessionHistory) subsetVariantMetasInclAlreadyAsked
                                  // _ = trace ("variantMetasFilteredByFilterType: " <> show variantMetasFilteredByFilterType) (\_ -> unit)
                                  // Note: Check if at least 2 questions in this current session else do not filter
  var validSubsetVariantMetas = (function () {
                                      var $28 = v.sessionQNbr > 2 && nbrLastFourCorrect(hstry) > 1;
                                      if ($28) {
                                          return Data_Array.filter(function (variantMeta) {
                                              return (function (v1) {
                                                  return v1.diff;
                                              })(Model_Variant.getVariantDef(variantMeta)) > initialTargetStrike;
                                          })(variantMetasFilteredByFilterType);
                                      };
                                      var $29 = v.sessionQNbr > 2 && nbrLastFourCorrect(hstry) < 1;
                                      if ($29) {
                                          return Data_Array.filter(function (variantMeta) {
                                              return (function (v1) {
                                                  return v1.diff;
                                              })(Model_Variant.getVariantDef(variantMeta)) < initialTargetStrike;
                                          })(variantMetasFilteredByFilterType);
                                      };
                                      return variantMetasFilteredByFilterType;
                                  })();
                                  var $30 = Data_Array["null"](validSubsetVariantMetas) && currTolerance <= v.maxToleranceDistance;
                                  if ($30) {
                                      return getNextVariantSelection$prime(variantFilterType)(currTolerance + v.toleranceIncrement | 0);
                                  };
                                  var $31 = Data_Array["null"](validSubsetVariantMetas) && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isFinalVFT)(variantFilterType);
                                  if ($31) {
                                      return getNextVariantSelection$prime(nextVFT(variantFilterType))(v.toleranceIncrement);
                                  };
                                
                                  // xxx still need to show nodesTraversed
  var variantDefMaybe = Data_Functor.map(Data_Maybe.functorMaybe)(Model_Variant.getVariantDef)(Control_Alt.alt(Data_Maybe.altMaybe)(Control_Alt.alt(Data_Maybe.altMaybe)(Control_Alt.alt(Data_Maybe.altMaybe)(pickWithRand$prime(validSubsetVariantMetas))(pickWithRand$prime(variantMetasFilteredByFilterType)))(pickWithRand$prime(subsetVariantMetasInclAlreadyAsked)))(Data_Array.head(sortedVariantMetas)));
                                  var selectionRationale = {
                                      targetStrike: initialTargetStrike,
                                      selectionStrike: 0,
                                      nodePercentages: [  ],
                                      selectionType: variantFilterType
                                  };
                                  return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))((function () {
                                      if (variantDefMaybe instanceof Data_Maybe.Nothing) {
                                          return new Data_Tuple.Tuple(selectionRationale, Data_Maybe.Nothing.value);
                                      };
                                      if (variantDefMaybe instanceof Data_Maybe.Just) {
                                        
                                          // update the variantFilterType for the selectionRationale based upon if the element selected was similar to a priorHistory variant
                                          // Novel means novel to THIS SESSION (not globally); where AlternateVariant means variant series not yet seen this session until this question
                                          // NOT ACCURANTE:  
  var newVariantFilterType2 = (function () {
                                              if (variantFilterType instanceof Model_Variant.Novel) {
                                                  var v1 = Data_Array.find(function (el) {
                                                      return el.variantId.smuid === variantDefMaybe.value0.vId.smuid;
                                                  })(sessionHistory);
                                                  if (v1 instanceof Data_Maybe.Just) {
                                                      return Model_Variant.AlternateVariant.value;
                                                  };
                                                  if (v1 instanceof Data_Maybe.Nothing) {
                                                      return Model_Variant.Novel.value;
                                                  };
                                                  throw new Error("Failed pattern match at Model.Question (line 379, column 28 - line 381, column 37): " + [ v1.constructor.name ]);
                                              };
                                              return variantFilterType;
                                          })();
                                        
                                          // Note: As at 2022-11-23, since we look only up or down in difficulty if 2 correct in a row, the BeenSeen must be double checked here to verify if is actually BeenSeen or AlternateVariant or Novel 
                                          // newVariantFilterType =
                                          //   if find (\el -> el.variantId == variantDef.vId) sessionHistory /= Nothing then
                                          //     BeenSeen
                                          //   else if find (\el -> el.variantId.smuid == variantDef.vId.smuid) sessionHistory /= Nothing then
                                          //     AlternateVariant
                                          //   else
                                          //     Novel
  var selectionRationale$prime = {
                                              selectionType: newVariantFilterType2,
                                              nodePercentages: selectionRationale.nodePercentages,
                                              selectionStrike: selectionRationale.selectionStrike,
                                              targetStrike: selectionRationale.targetStrike
                                          };
                                          return new Data_Tuple.Tuple({
                                              selectionStrike: variantDefMaybe.value0.diff,
                                              nodePercentages: selectionRationale$prime.nodePercentages,
                                              selectionType: selectionRationale$prime.selectionType,
                                              targetStrike: selectionRationale$prime.targetStrike
                                          }, new Data_Maybe.Just(variantDefMaybe.value0.vId));
                                      };
                                      throw new Error("Failed pattern match at Model.Question (line 371, column 11 - line 394, column 104): " + [ variantDefMaybe.constructor.name ]);
                                  })());
                              });
                          };
                      };
                  };
              };
          };
      };
  };
  var getNextVariantSelectionInit = function (initialTargetStrike) {
      return function (sortedVariantMetas) {
          return function (hstry) {
              return function (rand) {
                  return function (targetStrike) {
                      return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Data_Identity.monadIdentity)))(function (v) {
                          return getNextVariantSelection(initialTargetStrike)(sortedVariantMetas)(hstry)(rand)(targetStrike)(initialVFT)(v.toleranceIncrement);
                      });
                  };
              };
          };
      };
  };

  // NOTE: sessionQNbr is 1-based, ie the first question asked for is 1
  var filterHstryToVariantMetas = function (variantMetas) {
      return function (hstry) {
          var tnVIds = Data_Functor.map(Data_Functor.functorArray)(function ($69) {
              return (function (v) {
                  return v.vId;
              })(Model_Variant.getVariantDef($69));
          })(variantMetas);
          var filteredHstry = Data_Array.filter(function (el) {
              return Data_Array.elem(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                  reflectSymbol: function () {
                      return "vId";
                  }
              })(Data_Eq.eqInt))()({
                  reflectSymbol: function () {
                      return "smuid";
                  }
              })(Data_Eq.eqString)))(el.variantId)(tnVIds);
          })(hstry);
          return filteredHstry;
      };
  };

  // Go straight from elm to graphql for saving, and always pass externally loaded data into purescript.  This does mean in many cases that we have 2 sets of decode/encode in both elm and purescript
  var calcTargetStrike = function (node) {
      return function (v) {
          return function (hstry) {
              return function (initialDifficulty) {
                  return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Data_Identity.monadIdentity)))(function (v1) {
                      var variantMetas = Model_Node.getVariantMetasBelowNode(Data_Maybe.Nothing.value)(node);
                      var sessionHistory = getSessionHistory(v1.sessionQNbr)(hstry);
                    
                      // let
                      //   tnVIds = map (getVariantDef >>> _.vId) variantMetas -- Maybe vIds from targetNode variantDefs
                      //   filteredHstry = filter (\el -> elem el.variantId tnVIds) hstry -- History filtered by targetNode items!!
                      // in
                      //   filteredHstry
                      // maximumMaybe :: Maybe Int
                      // maximumMaybe = 
                      //   map _.variantId hstry' -- Array VariantId
                      //   # mapMaybe (getVariantMeta variantMetas) -- Array VariantMeta
                      //   # map (getVariantDef >>> _.diff) -- Array Int
                      //   # maximum -- Maybe Int
                      //    
                      // Note: strikeAdjustment is based soley on the history from the current session if it exists, else will be zero
  var strikeAdjustment = (function () {
                        
                          // ccccc would mean up 10
                          // cncic would mean up 2
                          // cnicc would mean up 4
                          // cccnn would mean down 4
                          // ccccn would mean down 2
  var revResults = Data_Array.reverse(Data_Array.takeEnd(5)(Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                              return v2.result;
                          })(sessionHistory)));
                          return Data_Maybe.fromMaybe(0)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.head(revResults))(function (head$prime) {
                              var isCorrect$prime = Model_History.isCorrect(head$prime);
                              var streak = Data_Array.takeWhile(function (el) {
                                  return Model_History.isCorrect(el) === isCorrect$prime;
                              })(revResults);
                              return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Data_Array.length(streak) * (function () {
                                  if (isCorrect$prime) {
                                      return v1.toleranceIncrement;
                                  };
                                  return -v1.toleranceIncrement | 0;
                              })() | 0);
                          }));
                      })();
                      var isSessionStart = v1.sessionQNbr === 1;
                    
                      // hstry3' :: History
                      // hstry3' =
                      //   -- filter history by targetNode items, and if none, then return full history
                      //   getNode targetNodeId node -- Maybe Node (target node)
                      //   # map (getVariantMetasBelowNode Nothing) -- Maybe VariantMetas narrowed to targetNode
                      //   # map (map (getVariantDef >>> _.vId)) -- Maybe vIds from targetNode variantDefs
                      //   # map (\vids -> filter (\hItem-> elem hItem.variantId vids) hstry) -- History filtered by targetNode items!!
                      //   # fromMaybe hstry
                      // hstry' :: History
                      // hstry' =
                      //   -- filter history by targetNode items, and if none, then return full history
                      //   do
                      //     (tNode :: Node) <- getNode targetNodeId node  -- Maybe Node (target node)
                      //     let tnVariantMetas = getVariantMetasBelowNode Nothing tNode -- Maybe VariantMetas narrowed to targetNode
                      //     let tnVIds = map (getVariantDef >>> _.vId) tnVariantMetas -- Maybe vIds from targetNode variantDefs
                      //     pure $ filter (\el-> elem el.variantId tnVIds) hstry -- History filtered by targetNode items!!
                      //   # fromMaybe hstry --History
                      //
                      // Note: hstry' is based on all (* maybe be from OTHER activities) hstry filtered down to the current target node OF this activity.
  var hstry$prime = filterHstryToVariantMetas(variantMetas)(hstry);
                      var lastStrikeMaybe = (function () {
                          var lastStrikeMaybe$prime = Data_Array.last(Data_Functor.map(Data_Functor.functorArray)(function ($70) {
                              return (function (v2) {
                                  return v2.diff;
                              })(Model_Variant.getVariantDef($70));
                          })(Data_Array.mapMaybe(Model_Variant.getVariantMeta(variantMetas))(Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                              return v2.variantId;
                          })(hstry$prime))));
                          return lastStrikeMaybe$prime;
                      })();
                    
                      // newTargetStrikeMaybe :: Maybe Int
                      // newTargetStrikeMaybe = map (\el -> el + strikeAdjustment) lastStrikeMaybe
  var calcAdjustedStrike = function (inStrike) {
                          if (isSessionStart) {
                              return Data_Ord.clamp(Data_Ord.ordInt)(initialDifficulty)(initialDifficulty + 90 | 0)(inStrike - v1.decrementFromPrevDiffAmt | 0);
                          };
                          return inStrike;
                      };
                      return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))((function () {
                          if (lastStrikeMaybe instanceof Data_Maybe.Nothing) {
                              return initialDifficulty;
                          };
                          if (lastStrikeMaybe instanceof Data_Maybe.Just) {
                              var $46 = nbrLastFourCorrect(hstry$prime) > 1;
                              if ($46) {
                                  return Data_Ord.max(Data_Ord.ordInt)(initialDifficulty)(calcAdjustedStrike(lastStrikeMaybe.value0 + strikeAdjustment | 0));
                              };
                              var $47 = nbrLastFourCorrect(hstry$prime) < 1;
                              if ($47) {
                                  return Data_Ord.min(Data_Ord.ordInt)(initialDifficulty)(calcAdjustedStrike(lastStrikeMaybe.value0 + strikeAdjustment | 0));
                              };
                              return initialDifficulty;
                          };
                          throw new Error("Failed pattern match at Model.Question (line 162, column 10 - line 171, column 26): " + [ lastStrikeMaybe.constructor.name ]);
                      })());
                  });
              };
          };
      };
  };

  // Tuple SelectionRationale (Maybe VariantId)
  // See https://docs.google.com/document/d/1SkWpDc1WwVzE2kkHFgF3Phl74cPxMs7g5f5R53hloLQ for an overview of this algorithm.
  // NOTE: Passed a nbr which is a randomly generated 0.0-1.0 number generated from a higher caller layer as an Effect
  var getVariantSelectionWithNbr = function (v) {
      return function (hstry) {
          return function (targetStrikeMaybe) {
              return function (rand) {
                  var chooseNextVariantSelection = function (tNode) {
                      return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Data_Identity.monadIdentity)))(function (v1) {
                          return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(Data_Maybe.maybe(calcTargetStrike(tNode)(v.context)(hstry)(v.initialDifficulty))(Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity)))(targetStrikeMaybe))(function (v2) {
                              var adjdNodePercentages = (function () {
                                  var $58 = Data_Array.length(v.nodePercentages) > 1;
                                  if ($58) {
                                      return Model_Node.adjustNodePercentages(tNode)(v.nodePercentages)(getSessionHistory(v1.sessionQNbr)(hstry));
                                  };
                                  return v.nodePercentages;
                              })();
                            
                              // Sort the variantMetas firstly by A) calculated node preference order based on node percentages and history; and secondarily by B) absolute distance from the targetStrike
                              // Note: duplicates are removed by sortNodeToVariantMetasDeduped
                              // Note: the variantMetas are filtered to just the category of the first non-nothing element/category ???? is this true?
  var sortedVariantMetas = Model_Node.sortNodeToVariantMetasDeduped(tNode)(v2)(adjdNodePercentages);
                              var nextVariantSelection = Data_Functor.map(Control_Monad_Reader_Trans.functorReaderT(Data_Identity.functorIdentity))(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorTuple)(function (varSel) {
                                  return {
                                      nodePercentages: adjdNodePercentages,
                                      selectionStrike: varSel.selectionStrike,
                                      selectionType: varSel.selectionType,
                                      targetStrike: varSel.targetStrike
                                  };
                              }))(getNextVariantSelectionInit(v2)(sortedVariantMetas)(hstry)(rand)(v2));
                              return nextVariantSelection;
                          });
                      });
                  };
                  return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Data_Identity.monadIdentity)))(function (v1) {
                      var v2 = Model_Node.getNode(v.targetNodeId)(Model_Node.filterSubTree(v.context)(v1.syllabusNode));
                      if (v2 instanceof Data_Maybe.Just) {
                          return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(chooseNextVariantSelection(v2.value0))(function (variantSelection) {
                              return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(variantSelection);
                          });
                      };
                      if (v2 instanceof Data_Maybe.Nothing) {
                          return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(new Data_Tuple.Tuple({
                              targetStrike: 0,
                              selectionStrike: 0,
                              nodePercentages: [  ],
                              selectionType: Model_Variant.Novel.value
                          }, Data_Maybe.Nothing.value));
                      };
                      throw new Error("Failed pattern match at Model.Question (line 206, column 3 - line 211, column 119): " + [ v2.constructor.name ]);
                  });
              };
          };
      };
  };
  exports["getVariantSelectionWithNbr"] = getVariantSelectionWithNbr;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Model.Question.Effectful"] = $PS["Model.Question.Effectful"] || {};
  var exports = $PS["Model.Question.Effectful"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Reader = $PS["Control.Monad.Reader"];
  var Control_Monad_Reader_Class = $PS["Control.Monad.Reader.Class"];
  var Control_Monad_Reader_Trans = $PS["Control.Monad.Reader.Trans"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Effect = $PS["Effect"];
  var Effect_Random = $PS["Effect.Random"];
  var $$Math = $PS["Math"];
  var Model_Question = $PS["Model.Question"];                
  var getVariantSelection = function (activityMeta) {
      return function (hstry) {
          return function (targetStrikeMaybe) {
              return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect)))(function (rConf) {
                  return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)(Effect.monadEffect)(Effect_Random.random))(function (rand) {
                      var probabilityRand = (function () {
                          var $0 = rConf.sessionQNbr <= 3;
                          if ($0) {
                              return rand;
                          };
                          return $$Math.pow(rand)(2.0) / 3.0;
                      })();
                      var variantSelection = Control_Monad_Reader.runReader(Model_Question.getVariantSelectionWithNbr(activityMeta)(hstry)(targetStrikeMaybe)(probabilityRand))(rConf);
                      return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Effect.applicativeEffect))(variantSelection);
                  });
              });
          };
      };
  };
  exports["getVariantSelection"] = getVariantSelection;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.9
  "use strict";
  $PS["Domain"] = $PS["Domain"] || {};
  var exports = $PS["Domain"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Monad_Reader_Trans = $PS["Control.Monad.Reader.Trans"];
  var Data_Array = $PS["Data.Array"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Show = $PS["Data.Show"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Class_Console = $PS["Effect.Class.Console"];
  var Model_Activity = $PS["Model.Activity"];
  var Model_Category = $PS["Model.Category"];
  var Model_Category_DecodeJSON = $PS["Model.Category.DecodeJSON"];
  var Model_Context = $PS["Model.Context"];
  var Model_History = $PS["Model.History"];
  var Model_Item = $PS["Model.Item"];
  var Model_Item_DecodeJSON = $PS["Model.Item.DecodeJSON"];
  var Model_Node = $PS["Model.Node"];
  var Model_Question_DecodeJSON = $PS["Model.Question.DecodeJSON"];
  var Model_Question_Effectful = $PS["Model.Question.Effectful"];
  var Model_Variant = $PS["Model.Variant"];                
  var psGetSyllabuses = function (jsonCategories) {
      var categoriesEither = Model_Category_DecodeJSON.makeCategories(jsonCategories);
      var rootsEither = Data_Functor.map(Data_Either.functorEither)(Model_Category.getRoots)(categoriesEither);
      return Control_Applicative.pure(Effect.applicativeEffect)(rootsEither);
  };
  var psGetAllVariantIdsForNode = function (node) {
      return Data_Array.nub(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
          reflectSymbol: function () {
              return "vId";
          }
      })(Data_Ord.ordInt))()({
          reflectSymbol: function () {
              return "smuid";
          }
      })(Data_Ord.ordString)))(Data_Functor.map(Data_Functor.functorArray)(function ($25) {
          return (function (v) {
              return v.vId;
          })(Model_Variant.getVariantDef($25));
      })(Model_Node.getVariantMetasBelowNode(Data_Maybe.Nothing.value)(node)));
  };
  var makeCatVariantMetas = function (itemMetas) {
      var makeVariantMetas = function (v) {
          var makeCatVariantMeta = function (diff) {
              return function (ctx) {
                  return function (idx) {
                      return function (catId) {
                          var vMeta = (function () {
                              if (v.questionType instanceof Model_Item.MCSA) {
                                  return new Model_Variant.MCSAMeta({
                                      vId: {
                                          smuid: v.smuid,
                                          vId: idx
                                      },
                                      ctx: ctx,
                                      diff: diff
                                  });
                              };
                              if (v.questionType instanceof Model_Item.MCMA) {
                                  return new Model_Variant.MCSAMeta({
                                      vId: {
                                          smuid: v.smuid,
                                          vId: idx
                                      },
                                      ctx: ctx,
                                      diff: diff
                                  });
                              };
                              if (v.questionType instanceof Model_Item.ANSBOX) {
                                  return new Model_Variant.MCSAMeta({
                                      vId: {
                                          smuid: v.smuid,
                                          vId: idx
                                      },
                                      ctx: ctx,
                                      diff: diff
                                  });
                              };
                              if (v.questionType instanceof Model_Item.NOT_IMPLEMENTED) {
                                  return new Model_Variant.MCSAMeta({
                                      vId: {
                                          smuid: v.smuid,
                                          vId: idx
                                      },
                                      ctx: ctx,
                                      diff: diff
                                  });
                              };
                              throw new Error("Failed pattern match at Domain (line 136, column 17 - line 141, column 85): " + [ v.questionType.constructor.name ]);
                          })();
                          return {
                              catId: catId,
                              variantMeta: vMeta
                          };
                      };
                  };
              };
          };
          var makeCatVariantMetasForAnswerSet = function (idx) {
              return function (v1) {
                  return Data_Functor.map(Data_Functor.functorArray)(makeCatVariantMeta(v1.difficulty)(Model_Context.fromString(v1.context))(idx))(v1.categories);
              };
          };
          return Data_Array.concat(Data_Array.mapWithIndex(makeCatVariantMetasForAnswerSet)(v.answerSets));
      };
      return Data_Array.concatMap(makeVariantMetas)(itemMetas);
  };
  var makeBranchNodeInfo = function (nd) {
      return {
          id: nd.id,
          title: nd.title,
          desc: nd.desc
      };
  };
  var getVariantMetas = function (catVariantMetas) {
      return function (leafCatId) {
          return Data_Functor.map(Data_Functor.functorArray)(function (v) {
              return v.variantMeta;
          })(Data_Array.filter((function () {
              var $26 = Data_Eq.eq(Data_Eq.eqString)(leafCatId);
              return function ($27) {
                  return $26((function (v) {
                      return v.catId;
                  })($27));
              };
          })())(catVariantMetas));
      };
  };
  var makeNode$prime = function (v) {
      return function (catVariantMetas) {
          return function (v1) {
              if (v1 instanceof Model_Category.Leaf) {
                  return Model_Node.makeLeafNode(v1.value0)(getVariantMetas(catVariantMetas)(v1.value0.id));
              };
              if (v1 instanceof Model_Category.Root) {
                  return Model_Node.makeBranchNode(makeBranchNodeInfo(v1.value0))(Data_Functor.map(Data_Functor.functorArray)(makeNode$prime(v)(catVariantMetas))(Data_Functor.map(Data_Functor.functorArray)(Model_Category.getNode(v))(v1.value0.children)));
              };
              if (v1 instanceof Model_Category.Branch) {
                  return Model_Node.makeBranchNode(makeBranchNodeInfo(v1.value0))(Data_Functor.map(Data_Functor.functorArray)(makeNode$prime(v)(catVariantMetas))(Data_Functor.map(Data_Functor.functorArray)(Model_Category.getNode(v))(v1.value0.children)));
              };
              throw new Error("Failed pattern match at Domain (line 156, column 1 - line 156, column 60): " + [ v.constructor.name, catVariantMetas.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var makeNode = function (syllabusId) {
      return function (catNodes) {
          return function (itemMetas) {
              var syllabusCatNode = Model_Category.getNode(catNodes)(syllabusId);
              var catVariantMetas = makeCatVariantMetas(itemMetas);
              return makeNode$prime(catNodes)(catVariantMetas)(syllabusCatNode);
          };
      };
  };
  var psMakeNode = function (syllabusId) {
      return function (jsonCategories) {
          return function (jsonItems) {
              var categoriesEither = Model_Category_DecodeJSON.makeCategories(jsonCategories);
              var itemsEither = Model_Item_DecodeJSON.makeItems(jsonItems);
              var nodeEither = Control_Apply.apply(Data_Either.applyEither)(Control_Apply.apply(Data_Either.applyEither)(Data_Functor.map(Data_Either.functorEither)(makeNode)(new Data_Either.Right(syllabusId)))(categoriesEither))(itemsEither);
              return Control_Applicative.pure(Effect.applicativeEffect)(nodeEither);
          };
      };
  };
  var debug = function (isDebug) {
      return function (str) {
          if (isDebug) {
              return Effect_Class_Console.log(Effect_Class.monadEffectEffect)(str);
          };
          return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
      };
  };
  var psGetVariantSelection$prime = function (rConf) {
      return function (activityMeta) {
          return function (history) {
              return function (targetStrikeMaybe) {
                  var dLog = debug(rConf.isDebug);
                  return function __do() {
                      var resp = Control_Monad_Reader_Trans.runReaderT(Model_Question_Effectful.getVariantSelection(activityMeta)(history)(targetStrikeMaybe))(rConf)();
                      dLog(Data_Show.show(Data_Maybe.showMaybe(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                          reflectSymbol: function () {
                              return "smuid";
                          }
                      })(Data_Show.showRecordFieldsCons({
                          reflectSymbol: function () {
                              return "vId";
                          }
                      })(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showString))))(Data_Tuple.snd(resp)))();
                      return resp;
                  };
              };
          };
      };
  };
  var psGetVariantSelection = function (rConf) {
      return function (activityMeta) {
          return function (history) {
              return function (targetStrikeIntOrNull) {
                  return function __do() {
                      var variantSelection = psGetVariantSelection$prime(rConf)(Model_Activity.jsToPurs(activityMeta))(Data_Functor.map(Data_Functor.functorArray)(Model_History.jsToPurs)(history))(Model_Question_DecodeJSON.jsToPurs(targetStrikeIntOrNull))();
                      return Data_Bifunctor.lmap(Data_Bifunctor.bifunctorTuple)(function (el) {
                          return {
                              selectionType: Data_Show.show(Model_Variant["showSelectionType'"])(el.selectionType),
                              nodePercentages: el.nodePercentages,
                              selectionStrike: el.selectionStrike,
                              targetStrike: el.targetStrike
                          };
                      })(variantSelection);
                  };
              };
          };
      };
  };
  exports["psMakeNode"] = psMakeNode;
  exports["psGetVariantSelection"] = psGetVariantSelection;
  exports["psGetAllVariantIdsForNode"] = psGetAllVariantIdsForNode;
  exports["psGetSyllabuses"] = psGetSyllabuses;
})(PS);
module.exports = PS["Domain"];
