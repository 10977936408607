'use strict';

if (ISDEBUG) { console.log("Me.js: Top of script"); }

import * as S from 'sanctuary';
import * as $ from 'sanctuary-def';

import {node, encase, encaseP, chain, map, fork, Future} from 'fluture/index.js'

// New imports to utilize smNapOnlBackend
//import awsconfig from '../aws-exports';
//Modify the test/dev cognito user pool settings in awsconfig to be the production user pool before configure AUTH
// awsconfig.aws_user_pools_id = "ap-southeast-2_CnELLRYZ9"
// awsconfig.aws_user_pools_web_client_id = "2giaqi5dijgvdevi6gimbea734"
// awsconfig.aws_cognito_identity_pool_id = "ap-southeast-2:376b7ddd-f137-47f5-9aa7-3a98e34293a7"

import API, { graphqlOperation } from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';

import { ERROR_TYPE, INTEROP_TAGS } from './Constants.js'



import { me } from 'sm-nap-onl-shared/queries'

//var Main = require("../../output/InteropPurs.Main/index.js");
//import { greet } from '../../output/InteropPurs.Main';


// ***********************************
// me
// ***********************************
export const getMe = sendToElmPayload => msg => {

    //console.log(greet("def"));
//    console.log(Main.greet("abcd"));


    
    const meFailure = rej => {
	//Extract code, and message if they exist in the rej(ection) else create a generic failure message.
	if(ISDEBUG) {console.log ('rejection!', rej)}


	//NOTE: rej is a string in this call **** So see other graphql return codes, and probably refactor the below

	
	let codeMaybe = S.get (S.is ($.String)) ('code') (rej) //Maybe String
	let messageMaybe = S.get (S.is ($.String)) ('message') (rej) //Maybe String
	let error = {
	    code: S.fromMaybe ("MeFailure") (codeMaybe),
	    severity: ERROR_TYPE.severity.Error,
	    message: S.fromMaybe ("Sorry but we were unable to retrieve your user details. " + rej.toString()) (messageMaybe),
	    details: JSON.stringify(msg)
	}
	sendToElmPayload (error)
    }
    
    const meSuccess = res => {

	console.log("res for meSuccess is", res);
	
	let payloadMaybe = S.gets (S.is ($.Object)) (['data', 'me']) (res) //data->me->id, sId, eData->sch/stu/tch only one of which will be complete, hstry[items]
        if(ISDEBUG) {console.log("payloadMaybe is", payloadMaybe)}

	let idMaybe = S.chain (S.get (S.is ($.String)) ('id')) (payloadMaybe) //Maybe String
	let sIdMaybe = S.chain (S.get (S.is ($.String)) ('sId')) (payloadMaybe) //Maybe String
	// let groups = S.pipe([
	//     S.chain (S.get (S.is ($.Unknown)) ('cognito:groups')),
	//     //S.fromMaybe (["Student"])
	//     S.fromMaybe ([])
	// ]) (payloadMaybe) //Array String
		
	//Return a payload of either an error structure OR a userDetails structure (without password)
	const buildPayload = payloadMaybe => {//idMaybe =>  sIdMaybe =>  {//groups => {
	    //let payloadMaybe = S.sequence (S.Maybe) (payloadMaybe) //Maybe payloadStruct

	    const invalidParamsToSignInPayload = () => ({
		code: "InvalidParameters",
		severity: ERROR_TYPE.severity.Fatal,
		message: "There is a problem with the application configuration that means we cannot log you in.  Please report this message to SmarterMaths (support@smartermaths.com.au) and we'll get it fixed asap!",
		details: `The amplify result from the graphQL function 'me' has id as ${S.fromMaybe ("Nothing") (idMaybe)} and the sId as ${S.fromMaybe ("Nothing") (sIdMaybe)} but both should be in the response!`
	    })
	    
	    //If Just payload then add the groups, else return error structure
	    let payload = S.pipe ([
		//S.map (S.concat ({groups: groups})),
		S.fromMaybe (invalidParamsToSignInPayload())
	    ]) (payloadMaybe)
		
	    return payload;
	}
	
	// resp from sendToElmPayload is undefined
	sendToElmPayload (buildPayload (payloadMaybe))//(idMaybe) (sIdMaybe))//; (groups)); 
	
	if (ISDEBUG) {console.log('NapOnlBackendAccess.js: payload to sendToElmPayload()', buildPayload (payloadMaybe))}
	if (ISDEBUG) {console.log('NapOnlBackendAccess.js: me', res)}
    }
    
    const tryMe = () =>
	  encaseP (el => API.graphql(graphqlOperation(me))) () 
    	  .pipe(
    	      fork
	      (meFailure)
    	      (meSuccess))

    tryMe()

    //sendToElmPayload ({id: "1234", sId: "nsw-school1"});

}

