/* eslint-disable */
// WAS COPIED FROM the auto generated file and then modified 2020-02-05 by mjd

// export const createStudent = `mutation CreateStudent(
//   $input: CreateCustomEntityInput!
//   $password: String!
//   $sId: ID
// ) {
//   createStudent(input: $input, password: $password, sId: $sId) {
//     success {
//       email
//       id
//     }
//     failure {
//       name
//       message
//       technicals
//     }
//   }
// }
// `;

import { LIMIT_HSTRY_LENGTH, LIMIT_SCHOOLS_LENGTH } from './Constants.js'

// added 2023-03-31 for School management
export const nbrUsersForSchoolId = `query BySId($sId: ID) {
  bySId(sId: $sId) { items {id, sId }}
}
`;

export const bySId = `query BySId($sId: ID) {
  bySId(sId: $sId, limit: ${LIMIT_SCHOOLS_LENGTH}) { items {id, sId, eData {sch {name, isCurrentSubscriber} }}}
}
`;


export const me = `query Me {
  me {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry(limit: ${LIMIT_HSTRY_LENGTH}) {
      items {
        qGId
        fTId
        tsS
        tsF
        aId
        wsT
        qs {
          a {
            mc
            sa
            mcma
            abox
          }
          qId
          r
          isMkd
          it { 
            ttl 
            vId
          }
        }
      }
      nextToken
    }
  }
}
`;



export const studentsWithHstry = `query Students {
  students {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry(limit: ${LIMIT_HSTRY_LENGTH}) {
      items {
        qGId
        fTId
        tsS
        tsF
        aId
        wsT
        qs {
          a {
            mc
            sa
            mcma
            abox
          }
          qId
          r
          isMkd
          it { 
            ttl 
            vId
          }
        }
      }
      nextToken
    }
  }
}
`;
export const studentsByTchIdWithHstry = `query StudentsByTchId($id: ID) {
  studentsByTchId(id: $id) {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry(limit: ${LIMIT_HSTRY_LENGTH}) {
      items {
        qGId
        fTId
        tsS
        tsF
        aId
        wsT
        qs {
          a {
            mc
            sa
            mcma
            abox
          }
          qId
          r
          isMkd
          it { 
            ttl 
            vId
          }
        }
      }
      nextToken
    }
  }
}
`;



// studentsByTchId(id: $id) {
//   id
//   sId
//   eData {
//     sch {
//       name
//       isCurrentSubscriber
//     }
//     tch {
//       firstName
//       lastName
//       email
//       isAdmin
//     }
//     stu {
//       firstName
//       lastName
//       email
//       cohort
//       group
//       schId
//     }
//   }
//   hstry {
//     items {
//       id
//       qGId
//       fTId
//       tsS
//       tsF
//     }
//     nextToken
//   }
// }


// Example response with one school:
// {
//   "data": {
//     "bySId": {
//       "items": [
//         {
//           "id": "nsw-school1",
//           "sId": "school",
//           "eData": {
//             "sch": {
//               "name": "School1",
//               "isCurrentSubscriber": true
//             }
//           }
//         }
//       ]
//     }
//   }
// }

//Copied from mutations.js but don't need any response other than id
export const createQuestionGroup = `mutation CreateQuestionGroup($input: CreateQuestionGroupInput!) {
  createQuestionGroup(input: $input) {
    id
  }
}
`;
// export const createQuestionGroup = `mutation CreateQuestionGroup($input: CreateQuestionGroupInput!) {
//   createQuestionGroup(input: $input) {
//     id
//     qGId
//     fTId
//     tsS
//     tsF
//     qs {
//       a {
//         mc
//         sa
//       }
//       qId
//       r
//       isMkd
//     }
//   }
// }
// `;



//Copied from mutations.js but don't need any response other than id
export const updateStudentRespondWithIdOnly = `mutation UpdateStudent($input: UpdateCustomEntityInput!) {
  updateStudent(input: $input) {
    id
  }
}
`;
