'use strict';

if (ISDEBUG) { console.log("SignInOut.js: Top of script"); }

import * as S from 'sanctuary';
import * as $ from 'sanctuary-def';

import {node, encase, encaseP, chain, map, fork, Future} from 'fluture/index.js'

// New imports to utilize smNapOnlBackend
//import awsconfig from '../aws-exports';
//Modify the test/dev cognito user pool settings in awsconfig to be the production user pool before configure AUTH
// awsconfig.aws_user_pools_id = "ap-southeast-2_CnELLRYZ9"
// awsconfig.aws_user_pools_web_client_id = "2giaqi5dijgvdevi6gimbea734"
// awsconfig.aws_cognito_identity_pool_id = "ap-southeast-2:376b7ddd-f137-47f5-9aa7-3a98e34293a7"

// import API, { graphqlOperation } from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
//import { Auth } from 'aws-amplify';


import { ERROR_TYPE, INTEROP_TAGS } from './Constants.js'




//For use by signIn and also checkCredentials
const authenticationSuccess = sendToElmPayload => res => {
    
    let payloadMaybe = S.gets (S.is ($.Object)) (['signInUserSession', 'idToken', 'payload']) (res) //Maybe Object
    if(ISDEBUG) {console.log("payloadMaybe is", payloadMaybe)}

    let emailMaybe = S.chain (S.get (S.is ($.String)) ('email')) (payloadMaybe) //Maybe String
    let usernameMaybe = S.chain (S.get (S.is ($.String)) ('sub')) (payloadMaybe) //Maybe String
    let groups = S.pipe([
	S.chain (S.get (S.is ($.Unknown)) ('cognito:groups')),
	//S.fromMaybe (["Student"])
	S.fromMaybe ([])
    ]) (payloadMaybe) //Array String
    
    //Return a payload of either an error structure OR a userDetails structure (without password)
    const buildPayload = emailMaybe =>  usernameMaybe =>  groups => {
	let payloadMaybe = S.sequence (S.Maybe) ({email: emailMaybe, username: usernameMaybe}) //Maybe payloadStruct

	const invalidParamsToSignInPayload = () => ({
	    code: "InvalidParameters",
	    severity: ERROR_TYPE.severity.Fatal,
	    message: "There is a problem with the application configuration that means we cannot log you in.  Please report this message to SmarterMaths (support@smartermaths.com.au) and we'll get it fixed asap!",
	    details: `The amplify result from Auth.signIn has email as ${S.fromMaybe ("Nothing") (emailMaybe)} and the username as ${S.fromMaybe ("Nothing") (usernameMaybe)} but both are required!`
	})
	
	//If Just payload then add the groups, else return error structure
	let payload = S.pipe ([
	    S.map (S.concat ({groups: groups})),
	    S.fromMaybe (invalidParamsToSignInPayload())
	]) (payloadMaybe)

	//console.log("authenticationSuccess payload to send to elm is", payload);
	return payload;
    }

    const payload = buildPayload (emailMaybe) (usernameMaybe) (groups);

    if (ISDEBUG) {console.log('NapOnlBackendAccess.js: signIn: Input res', res)} 
    if (ISDEBUG) {console.log('NapOnlBackendAccess.js: signIn: payload', payload)}
    if (ISDEBUG) {console.log('NapOnlBackendAccess.js: signIn: sendToElmPayload', sendToElmPayload)}
    
    const resp = sendToElmPayload (payload);
    if (ISDEBUG) {console.log('NapOnlBackendAccess.js: signIn: Output resp', resp)}

    
}



// ***********************************
// signIn
// ***********************************
export const signIn = sendToElmPayload => msg => {
    //Returns  signInUserSession.idToken.payload. cognito:groups -> ["NapAdmin", "Teacher"], and sub, and email), else if fail, sendToElm error as specified below?
    // if not valid params, then sendToElm "UserSignedIn" BUT with the error details as below "There is a misconfiguration in the application and we cannot continue.  Please report to SmarterMaths"

    //const sendToElmPayload = sendToElm(INTEROP_TAGS.JTE.SignInJTE);
    //console.log("sendToElmPayload is", sendToElmPayload);
    
    const signInFailure = rej => {
	//Extract code, and message if they exist in the rej(ection) else create a generic failure message.
	if(ISDEBUG) {console.log ('rejection!', rej)}
	
	let codeMaybe = S.get (S.is ($.String)) ('code') (rej) //Maybe String
	let messageMaybe = S.get (S.is ($.String)) ('message') (rej) //Maybe String
	let error = {
	    code: S.fromMaybe ("SignInFailure") (codeMaybe),
	    severity: ERROR_TYPE.severity.Error,
	    message: S.fromMaybe ("Sorry but we were unable to sign you in.") (messageMaybe),
	    details: JSON.stringify(msg)
	}
	sendToElmPayload (error)
    }
        
    const trySignIn = (msg) =>
	  encaseP (creds => Auth.signIn(creds)) (msg.payload) //({username: 'smadmin1@smartermaths.com.au', password: 'Smnaplan1!'})
    	  .pipe(
    	      fork
	      (signInFailure)
    	      (authenticationSuccess (sendToElmPayload)))

    trySignIn(msg)
}



// ***********************************
// forgotPassword
// ***********************************
export const forgotPassword = sendToElmPayload => msg => {
    //Returns  signInUserSession.idToken.payload. cognito:groups -> ["NapAdmin", "Teacher"], and sub, and email), else if fail, sendToElm error as specified below?
    // if not valid params, then sendToElm "UserSignedIn" BUT with the error details as below "There is a misconfiguration in the application and we cannot continue.  Please report to SmarterMaths"

    //const sendToElmPayload = sendToElm(INTEROP_TAGS.JTE.ForgotPasswordJTE);

    const forgotPasswordFailure = rej => {
	//Extract code, and message if they exist in the rej(ection) else create a generic failure message.
	if(ISDEBUG) {console.log ('rejection!', rej)}
	
	let codeMaybe = S.get (S.is ($.String)) ('code') (rej) //Maybe String
	let messageMaybe = S.get (S.is ($.String)) ('message') (rej) //Maybe String
	let error = {
	    code: S.fromMaybe ("ForgotPasswordFailure") (codeMaybe),
	    severity: ERROR_TYPE.severity.Error,
	    message: S.fromMaybe ("Sorry but we were unable to reqest the forgot password code in.") (messageMaybe),
	    details: JSON.stringify(msg)
	}
	sendToElmPayload (error)
    }
    
    const forgotPasswordSuccess = username => res => {

	let payloadMaybe = S.get (S.is ($.Object)) ('CodeDeliveryDetails') (res) //Maybe Object
        if(ISDEBUG) {console.log("payloadMaybe is", payloadMaybe)}

	let deliveryMediumMaybe = S.chain (S.get (S.is ($.String)) ('DeliveryMedium')) (payloadMaybe) //Maybe String
	let destinationMaybe = S.chain (S.get (S.is ($.String)) ('Destination')) (payloadMaybe) //Maybe String
	// let groups = S.pipe([
	//     S.chain (S.get (S.is ($.Unknown)) ('cognito:groups')),
	//     //S.fromMaybe (["Student"])
	//     S.fromMaybe ([])
	// ]) (payloadMaybe) //Array String
		
	//Return a payload of either an error structure OR a userDetails structure (without password)
	const buildPayload = deliveryMediumMaybe => destinationMaybe => {
	    let payloadMaybe = S.sequence (S.Maybe) ({username: S.Just(username), deliveryMedium: deliveryMediumMaybe, destination: destinationMaybe}) //Maybe payloadStruct

	    const invalidParamsToSignInPayload = () => ({
		code: "InvalidParameters",
		severity: ERROR_TYPE.severity.Fatal,
		message: "There is a problem with the application configuration that means we cannot log you in.  Please report this message to SmarterMaths (support@smartermaths.com.au) and we'll get it fixed asap!",
		details: `The amplify result from Auth.forgotPassword has deliveryMedium as ${S.fromMaybe ("Nothing") (deliveryMediumMaybe)} and the destination as ${S.fromMaybe ("Nothing") (destinationMaybe)} but both should be present.`
	    })
	    
	    //If Just payload then add the groups, else return error structure
	    let payload = S.fromMaybe (invalidParamsToSignInPayload()) (payloadMaybe)
		
	    return payload;
	}
		
	sendToElmPayload (buildPayload (deliveryMediumMaybe) (destinationMaybe));
	if (ISDEBUG) {console.log('NapOnlBackendAccess.js: forgotPassword', res)}
    }
    
    const tryForgotPassword = (msg_) =>
	  encaseP (uname => Auth.forgotPassword(uname)) (msg_.payload.username) //({username: 'smadmin1@smartermaths.com.au'})
    	  .pipe(
    	      fork
	      (forgotPasswordFailure)
    	      (forgotPasswordSuccess(msg_.payload.username)))

    const msgIsValidFormat = (msg_) => {
	let hasUsername = S.isJust (S.gets (S.is ($.String)) (['payload', 'username']) (msg_));
	return hasUsername;
    }

    const invalidMsgFields = (msg) => sendToElm (INTEROP_TAGS.JTE.InteropFailureJTE) ({
	code: "InvalidMessageFieldsPassedToJs",
	severity: ERROR_TYPE.severity.Fatal,
	message: "There is a problem with the application configuration that means we cannot log you in.  Please report this message to SmarterMaths (support@smartermaths.com.au) and we'll get it fixed asap!",
	details: `An invalid request was made from elm to js for the forgetPassword request which should contain a username but was called with the with the following message format [${JSON.stringify(msg)}]`
    });
    
    //The input msg params (should contain a tag and a data field) will be validated here once, so when call functions, can know msg.payload exists
    S.ifElse (msgIsValidFormat) (tryForgotPassword) (invalidMsgFields) (msg);
    //tryForgotPassword("matt@turtleroad.com")
}



// ***********************************
// forgotPasswordSubmit
// ***********************************
export const forgotPasswordSubmit = sendToElmPayload => msg => {
    //Returns  signInUserSession.idToken.payload. cognito:groups -> ["NapAdmin", "Teacher"], and sub, and email), else if fail, sendToElm error as specified below?
    // if not valid params, then sendToElm "UserSignedIn" BUT with the error details as below "There is a misconfiguration in the application and we cannot continue.  Please report to SmarterMaths"

    //const sendToElmPayload = sendToElm(INTEROP_TAGS.JTE.ForgotPasswordSubmitJTE);

    const forgotPasswordSubmitFailure = rej => {
	//Extract code, and message if they exist in the rej(ection) else create a generic failure message.
	if(ISDEBUG) {console.log ('rejection!', rej)}
	
	let codeMaybe = S.get (S.is ($.String)) ('code') (rej) //Maybe String
	let messageMaybe = S.get (S.is ($.String)) ('message') (rej) //Maybe String
	let error = {
	    code: S.fromMaybe ("ForgotPasswordSubmitFailure") (codeMaybe),
	    severity: ERROR_TYPE.severity.Error,
	    message: S.fromMaybe ("Sorry but we were unable to reqest the forgot password code in.") (messageMaybe),
	    details: JSON.stringify(msg)
	}
	sendToElmPayload (error)
    }
    
    const forgotPasswordSubmitSuccess = res => {
	sendToElmPayload ("Success");
	if (ISDEBUG) {console.log('NapOnlBackendAccess.js: forgotPasswordSubmit: [Note - Successful promise resolution res is void] so the string "Success" is sent back to elm', res)}		
    }
    
    const tryForgotPasswordSubmit = (msg_) =>
	  encaseP (p => Auth.forgotPasswordSubmit(p.username, p.code, p.password)) (msg_.payload) //({username: 'smadmin1@smartermaths.com.au'})
    	  .pipe(
    	      fork
	      (forgotPasswordSubmitFailure)
    	      (forgotPasswordSubmitSuccess))

    const msgIsValidFormat = (msg_) => {
	let hasUsername = S.isJust (S.gets (S.is ($.String)) (['payload', 'username']) (msg_));
	let hasCode = S.isJust (S.gets (S.is ($.String)) (['payload', 'code']) (msg_));
	let hasNewPassword = S.isJust (S.gets (S.is ($.String)) (['payload', 'password']) (msg_));

	return (S.all (S.equals (true)) ([hasUsername, hasCode, hasNewPassword]));
    }

    
    const invalidMsgFields = (msg) => sendToElm (INTEROP_TAGS.JTE.InteropFailureJTE) ({
	code: "InvalidMessageFieldsPassedToJs",
	severity: ERROR_TYPE.severity.Fatal,
	message: "There is a problem with the application configuration that means we cannot log you in.  Please report this message to SmarterMaths (support@smartermaths.com.au) and we'll get it fixed asap!",
	details: `An invalid request was made from elm to js for the forgetPasswordSubmit request which should contain a username but was called with the with the following message format [${JSON.stringify(msg)}]`
    });
    
    //The input msg params (should contain a tag and a data field) will be validated here once, so when call functions, can know msg.payload exists
    S.ifElse (msgIsValidFormat) (tryForgotPasswordSubmit) (invalidMsgFields) (msg);
    //tryForgotPassword("matt@turtleroad.com")
}




// ***********************************
// signOut
// ***********************************
export const signOut = sendToElmPayload => msg => {
    const signOutFailure = rej => {
	//Extract code, and message if they exist in the rej(ection) else create a generic failure message.
	if(ISDEBUG) {console.log ('rejection!', rej)}
	
	let codeMaybe = S.get (S.is ($.String)) ('code') (rej) //Maybe String
	let messageMaybe = S.get (S.is ($.String)) ('message') (rej) //Maybe String
	let error = {
	    code: S.fromMaybe ("SignOutFailure") (codeMaybe),
	    severity: ERROR_TYPE.severity.Error,
	    message: S.fromMaybe ("Sorry but we were unable to successfully sign you out.") (messageMaybe),
	    details: JSON.stringify(msg)
	}
	sendToElmPayload (error)
    }
    
    const signOutSuccess = res => {
	sendToElmPayload ("Success");
	if (ISDEBUG) {console.log('NapOnlBackendAccess.js: signOut: [Note - Successful promise resolution res is void] so the string "Success" is sent back to elm', res)}		
    }
    
    const trySignOut = () =>
	  encaseP (el => Auth.signOut()) () 
    	  .pipe(
    	      fork
	      (signOutFailure)
    	      (signOutSuccess))

    trySignOut()
}

// When browser is refreshed this is called to re-send login details if the user is still logged in.
export const handleRefreshIfAlreadyAuthenticated = (sendToElm) => {
    if (ISDEBUG) {console.log('SignInOut.js: handleRefreshIfAlreadyAuthenticated')}
    const sendToElmPayload = sendToElm (INTEROP_TAGS.SignInJTE);

    const tryRefresh = () =>
	  encaseP (el => Auth.currentAuthenticatedUser()) () //No params 
    	  .pipe(
    	      fork
	      (el => console.log("Not authenticated, so will show signIn page"))
    	      (authenticationSuccess (sendToElmPayload)))

    tryRefresh()
}

