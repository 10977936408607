'use strict';

if (ISDEBUG) { console.log("GraphQL.js: Top of script"); }

import * as S from 'sanctuary';
import * as $ from 'sanctuary-def';

import { encaseP, fork } from 'fluture/index.js'

// New imports to utilize smNapOnlBackend
//import awsconfig from '../aws-exports';
//Modify the test/dev cognito user pool settings in awsconfig to be the production user pool before configure AUTH
// awsconfig.aws_user_pools_id = "ap-southeast-2_CnELLRYZ9"
// awsconfig.aws_user_pools_web_client_id = "2giaqi5dijgvdevi6gimbea734"
// awsconfig.aws_cognito_identity_pool_id = "ap-southeast-2:376b7ddd-f137-47f5-9aa7-3a98e34293a7"

import API, { graphqlOperation } from '@aws-amplify/api';

//import { ERROR_TYPE, INTEROP_TAGS } from './Constants.js'
import { ERROR_TYPE } from './Constants.js'


//2020-03-06 as per https://github.com/aws/aws-sdk-js/issues/399
// AWS.config.update({
//     correctClockSkew: true,
//   });

//import { me } from 'sm-nap-onl-shared/queries'

//var Main = require("../../output/InteropPurs.Main/index.js");
//import { greet } from '../../output/InteropPurs.Main';

//infoForElm is the port endpoint with a single method "send" on it; msg is {query: "string of actual request for graphqlOperation creation", params : <any>, respTag: "MeJTE" for example}
export const handleGraphQLMsgsReceivedFromElm = infoForElm => msg => {
    //console.log("in handleGraphQLMsgsReceivedFromElm", infoForElm, msg);

    const graphqlFailure = rej => {
	//Extract code, and message if they exist in the rej(ection) else create a generic failure message.
	if(ISDEBUG) {console.log ('rejection!', rej)}
	//NOTE: rej is a string in this call **** So see other graphql return codes, and probably refactor the below
	
	let codeMaybe = S.get (S.is ($.String)) ('code') (rej) //Maybe String
	let messageMaybe = S.get (S.is ($.String)) ('message') (rej) //Maybe String
	let error = {
	    code: S.fromMaybe ("GraphQLFailure") (codeMaybe),
	    severity: ERROR_TYPE.severity.Error,
	    message: S.fromMaybe ("Sorry but we could not continue due to an error.  Please notify SmarterMaths at support@smartermaths.com.au and we'll get it fixed asap!") (messageMaybe),
	    details: "Request message details: " + JSON.stringify(msg) + " Rejection details: " + JSON.stringify(rej)
	}
	//console.log("error in GraphQL.js is:", error);
	infoForElm.send ({tag: msg.respTag, payload: {id: msg.params.id, error: error}})
    }
    
    const graphqlSuccess = res => {
	//console.log("res for graphqlSuccess is", res);
	
	// resp from sendToElmPayload is undefined
	infoForElm.send ({tag: msg.respTag, payload: res})
    }
    
    const tryMe = () =>
	  encaseP (el => API.graphql(graphqlOperation(msg.query, msg.params))) () // the query is a string, and the params should something like {id: 123, ...} for queries or {input: {id 123, ...}} for mutations
    	  .pipe(
    	      fork
	      (graphqlFailure)
    	      (graphqlSuccess))
    
    tryMe()

}
