'use strict';

//const d3 = require('d3');

var d3 = Object.assign({}, require('d3'), require('d3-array'));

//var d3 = require("d3");
//require("d3-array");

const margin = {top: 20, right: 0, bottom: 30, left: 65};
const width = 964 - margin.left - margin.right;
const height = 500 - margin.top - margin.bottom;


// for local development:
// d3.json("results-by-band.json").then(showChartByBandRecord => {
//     //console.log("root is", students);
//     showChartByBand(showChartByBandRecord);
// })


export const showChartByBand = showChartByBandRecord => {

    const d3Area = d3.select('#sm-d3-area')

    //Since this will be called from active click form the analystics list of graphs page, then remove any existing elements (else from a back / forward button, the update will just set setBelowElmDisplayNone True or False to show / hide any exisitng tables and charts.
    d3Area.selectAll("*").remove();
    
    d3Area
        .append('h1')
        .attr('class', 'title')
        .attr('style', 'color: white')
        .text('Results by band ')
        .append('i').attr('class', 'fa fa-question-circle').attr('id', 'helpAnchorOnPageBackground') //Note: #helpAnchorOnPageBackground used in tooltip.io HELP_RESULTSBYBAND_TOOLTIP
    
    const examListNode = d3Area
        .append('div')
        .attr('id', 'sm-exam-list')
        .attr('class', 'box')

    d3Area
        .append('div')
        .attr('id', 'sm-chart-area')
    
    d3Area
        .append('div')
        .attr('id', 'sm-student-list')
    
    //console.log("showChartByBand y892 with students are:", showChartByBandRecord)
    const resItemsDeep = showChartByBandRecord.studentResults.map(el => el.res)
    const resItemsFlat = d3.merge(resItemsDeep)

    //rollupMap2 – Map {21 => 2, 302 => 3, 401 => 1}
    const rollupMap2 = d3.rollup(resItemsFlat, v => v.length, d => d.qGroupId )
    //console.log("rollupMap2", rollupMap2)
   
    //Map {21 => [{qGroupId: 21, tsFinish: "1524535549.179", bnd: 9}, {qGroupId: 21, tsFinish: "1524535539.179", bnd: 8}], 302 => [{qGroupId: 302, tsFinish: "1524537549.245", bnd: 7}, {qGroupId: 302, tsFinish: "1524517549.245", bnd: 9}, {qGroupId: 302, tsFinish: "1524547549.245", bnd: 7}], 401 => [{qGroupId: 401, tsFinish: "1524549999.555", bnd: 6}]}
    //const groupMap2 = d3.group(resItemsFlat, d => d.qGroupId)
    //console.log(groupMap2)

    //Map {9 => 4, 7 => 1}
    //Note: for year 4/6/8 those students do nap exams 5/7/9 so adjust here.
    const totalStudentsByLevel = d3.rollup(showChartByBandRecord.studentResults
                                           , v => v.length
                                           , d => lvlForNapStyleExams(d.lvl)
                                          )
    //console.log(totalStudentsByLevel);


    //e.g. 
    // Array (3)
    // 0 {qGroupId: 21, Exam/Test Name: "Y9 Full Trial Exam 1", # Students Attempted: 2, # Students Not Attempted: 2, Action: "Select"}
    // 1 {qGroupId: 302, Exam/Test Name: "Y9 NUMBER Revision Test 2", # Students Attempted: 3, # Students Not Attempted: 1, Action: "Select"}
    // 2 {qGroupId: 401, Exam/Test Name: "Y7 Full Trial Exam 2", # Students Attempted: 1, # Students Not Attempted: 0, Action: "Select"}


    const validQGroupIds = showChartByBandRecord.qGroups.map(el => el.id)

    //console.log("before 25 a", rollupMap2, validQGroupIds);
    
    //Filter out any keys in rollupMap2 that do not have a corresponding qGroup (this could occur if the studentHistory has records of old exams/strands that are now defunct and have been removed from the qGroups appCfg definitions)
    //NOTE: Destructive (mutating) action on rollupMap2
    for (let k of rollupMap2.keys()) {
	if (!(validQGroupIds.includes(k))) {
	    rollupMap2.delete(k);
	}
    }

    const examTableData = Array.from(rollupMap2, ([key, value]) =>
                                     {
                                         const qGroup = showChartByBandRecord.qGroups.find(el => el.id === key)
                                         const name = qGroup.name
                                         const lvl = qGroup.lvl
                                         const nbrNotAttmpt = totalStudentsByLevel.get(lvl) - value
                                         return {"qGroupId": key, "Exam/Test Name": name, "# Students Attempted": value, "# Students Not Attempted": nbrNotAttmpt, "Action": "View Chart Below"};
                                     }
                                    ).sort((a, b) => {
					return a.qGroupId - b.qGroupId
				    });
    //console.log(examTableData);

    if (examTableData.length > 0) { 
	// show createExamListTable
	createExamListTable(examListNode, examTableData, ['Exam/Test Name', '# Students Attempted', '# Students Not Attempted', 'Action'], showChartByBandForExam(showChartByBandRecord));
    } else {
	examListNode
	    .append('p')
	    .text("Please ensure you've selected at least one student with results history, on which to perform results analysis.")
    }
} 

const lvlForNapStyleExams = rawLvl => [4, 6, 8].includes(rawLvl) ? rawLvl + 1 : rawLvl

// ****************************************
// extractStudentResultsForExam
// ****************************************
//
// Transform data from studentResults array (always has just the first attempt at each qGroupId in the res field)
// [
//      {
//          "usrSub": "ap-southeast-2:1aa6c156-07b4-4eaa-b476-1aef8353eba0",
//          "lvl": 9,
//          "fNam": "Scarlett",
//          "lNam": "Donovan",
//          "res": [
//              {
//                  "qGroupId": 21,
//                  "tsFinish": "1524535549.179",
//                  "bnd": 9
//              },
//              {
//                  "qGroupId": 302,
//                  "tsFinish": "1524537549.245",
//                  "bnd": 7
//              }
//          ]
//      },
// ...]
//
// => TO =>
// studentResultsForExam (where the tsFinish and bnd are for the qGroupId exam passed to this function (clicked on in the exam list table))
// [{ usrSub:"", lvl, fNam, lNam, tsFinish, bnd}, ...]
//
const extractStudentResultsForExam = (studentResults, qGroup) => {
    const retVal = studentResults
        .filter(el => lvlForNapStyleExams(el.lvl)  === qGroup.lvl)
        //.filter(el => el.res.some(el1 => el1.qGroupId === qGroup.id))
        .map(el => {
            const {usrSub, fNam, lNam} = el
            
            let retVal = {"usrSub": el.usrSub,
                          "fNam": el.fNam,
                          "lNam": el.lNam,
                          "tsFinish": null,
                          "bnd": null
                         }
            const res = el.res.find(el1 => el1.qGroupId === qGroup.id)
            
            if (res) {
                retVal.tsFinish = res.tsFinish;
                retVal.bnd = res.bnd;
            }
            
            return retVal
        })

    return retVal
}             

// Transition for band chart
const t = d3.transition().duration(500)


//Curried, as per https://medium.com/front-end-weekly/javascript-es6-curry-functions-with-practical-examples-6ba2ced003b1
const showChartByBandForExam = showChartByBandRecord => qGroupId => {

    const studentResults = showChartByBandRecord.studentResults
    //console.log("studentResults is here!", studentResults)
    
    const qGroup = showChartByBandRecord
          .qGroups
          .find(el => el.id === qGroupId)
    
    //const lvl = qGroup.lvl    
    //console.log('lvl is:', lvl);

    const examName = qGroup.name
    //console.log("examName = ", examName);

    const studentResultsForExam = extractStudentResultsForExam(studentResults, qGroup)
    //console.log("studentResultsForExam", studentResultsForExam)
              
    // Take studentResultsForExam of [{ usrSub:"", fNam, lNam, tsFinish, bnd}, ...]
    // => TO =>
    // groupedByBandMap :: Map {6 => [Object]}; 6 is an example band, and [Object] is an array of Students at that band for this qGroupId/Exam
    const groupedByBandMap = d3.group(studentResultsForExam, d => d.bnd)
    //console.log("groupedByBandMap", groupedByBandMap); 

    const minStudentsAtBand = 0
    const maxStudentsAtBand = d3.max(groupedByBandMap,
                                     ([bnd, studentsArray]) => {
                                         //console.log("bnd, studentsArray", bnd, studentsArray);
                                         return studentsArray.length;
                                     }
                                    )
    
    const extent = [minStudentsAtBand, maxStudentsAtBand]
  
    showBandChart(qGroup, extent, groupedByBandMap)
    
}

//Paint the svg band chart
const showBandChart = (qGroup, extent, groupedByBandMap) => {
    // And band data counts for chart bars
    const bandData = Array.from(groupedByBandMap)
          .map(([k, v]) => {
              const bndDesc = k === null ? "N/A" : "Band " + k;
              return {"bnd": k, "bndDesc": bndDesc, "nbrStudents": v.length}
          }
              )
    //console.log("bandData", bandData);
    
    const yScale = d3.scaleLinear()
          .domain(extent)
          .range([height, 0])

    const yAxis = d3.axisLeft(yScale)
	  //.tickFormat(d3.format("d")) // For small student #'s will 1.0, 1.5, 2.0 -> 1, 1, 2 so NOT what we want
          //.tickValues(d3.ticks(minBand, maxBand, maxBand - minBand))
          //.ticks(extent[1] - extent[0], "d")
          //.tickSizeOuter(10)

    const bandsExtent = d3.extent(
        Array.from(groupedByBandMap)
            .map(([k, v]) => k)
    )
    //console.log("bandsExtent", bandsExtent);
    
    let bandsDomain = d3.range(bandsExtent[0], bandsExtent[1] + 1);    
    //console.log("bandsDomain is: ", bandsDomain);
    
    //let xDomain = ["Band 5", "Band 6", "Band 7", "Band 8", "Band 9", "Band 10"];
    let xDomain = bandsDomain
        .map(el => "Band " + el)
    
    // Prepend to yield ["N/A"....]
    xDomain.unshift ("N/A")
    
    const xScale = d3.scaleBand()
        .domain(xDomain)
        .range([0, width])
        .padding(0.1);

    const xAxis = d3.axisBottom(xScale)

    const chartBodyG = createChartBody(qGroup, xAxis, yAxis)
    
    //Uses a key field to track the bars (and follow animations) by the band name e.g. "Band 6" or "N/A"
    const update = chartBodyG.selectAll('rect')
          .data(bandData, d => d.bndDesc)

    update.exit()
        .transition(t)
        .attr('y', height)
        .attr('height', 0)
        .remove();

    update
        .call(onBandClick, groupedByBandMap, qGroup.name, qGroup.id)
        .transition(t)
        //.delay(500)
        .attr('y', d => yScale(d.nbrStudents))
        .attr('height', d => height - yScale(d.nbrStudents))
        .attr('x', d => xScale(d.bndDesc))
        .attr('width', d => xScale.bandwidth())
    
    update.enter()
        .append('rect')
        .attr('class', d => 'sm-band-' + d.bnd)
        .call(onBandClick, groupedByBandMap, qGroup.name, qGroup.id)
        .attr('y', height)
        .attr('height', 0)
        .attr('x', d => xScale(d.bndDesc))
        .attr('width', d => xScale.bandwidth())
        .transition(t)
        //.delay(update.exit().size() ? 500 : 0) // THIS CAUSES INTERMITTENT PROBLEMS with d3 transition: [Error] Error: too late; already started
        .attr('y', d => yScale(d.nbrStudents))
        .attr('height', d => height - yScale(d.nbrStudents))

}


// ****************************************
// Prepare chartBody drawing (svg) area
// ****************************************
//Add class box to the chart area to make it visible the first time (repeats should not matter when click different exam/qGroupIds)
const createChartBody = (qGroup, xAxis, yAxis) => {
    d3.select('#sm-emtpy-students-article').classed('sm-display-none', false);
    
    const chartArea = d3.select("#sm-chart-area")
          .attr("class", "box")

    let chartTitle = chartArea.select("#sm-chart-title")
    let svg = chartArea.select("svg");
    let chartBodyG = svg.select("#chart-body-g");
    let chartXAxisG = svg.select("#sm-chart-x-axis-g");
    let chartYAxisG = svg.select("#sm-chart-y-axis-g");

    let studentListDiv = d3.select("#sm-student-list")
        .attr("class", 'box')
    
    //console.log("svg", svg);
    //console.log("chartBodyG", chartBodyG);
        
    if (svg.empty()) {
        chartTitle = chartArea
            .append("div")
            .attr('class', 'content')
            .append('h2')
            .attr('id', 'sm-chart-title')
        
        svg = chartArea
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)

        svg
            .append('text')
            .text('# Students')
            .attr("font-family", "sans-serif")
            .attr("font-size", "20px")
            .attr('transform', `translate(${margin.left/2},${(height + 100 + margin.top + margin.bottom)/2})rotate(-90)`)       
//          .attr('x', margin.left/2)
//          .attr('y', (height + margin.top + margin.bottom)/2)
        //.attr("fill", "red")
        
        chartBodyG = svg
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .attr("id", "chart-body-g")

        chartXAxisG = chartBodyG
            .append('g')
            .attr("id", "sm-chart-x-axis-g")
            .attr('transform', `translate(0, ${height})`)
        
        chartYAxisG = chartBodyG
            .append('g')
            .attr("id", "sm-chart-y-axis-g")

        studentListDiv
            .append('article')
            .attr('id', 'sm-emtpy-students-article')
            .attr('class', 'message')
            .append('div')
            .attr('class', 'message-body has-text-centered')
            .text('Click on a band bar in the chart above to show individual students')

    } //else chartBody will already be created from last time!


    //Set the chart title and x axis / y axis
    chartTitle
        .text(qGroup.name + ' (Click on any bar to view specific students)')
    
    chartXAxisG
        .transition(t)
        .call(xAxis)
    
    chartYAxisG
        .transition(t)
        .call(yAxis)

    return chartBodyG
}

//e.g. format: 22 May 2018 (6:20 pm)
const formatDate = d3.timeFormat("%d %b %Y (%-I:%M %p)");

const onBandClick = (selection, groupedByBandMap, examName, qGroupId) => {
    selection.on('click', (d, i, elements) => {
        //Select the student list element
        const studentList = d3.select('#sm-student-list')

        //Hide the empty message
        studentList.select('#sm-emtpy-students-article')
            .classed('sm-display-none', true);
        
        const students = groupedByBandMap.get(d.bnd)
        // Create table that redirects to something of format url.../usrSub/qGroupId/tsFinish  e.g. https://explore.smartermaths.com.au/#sm_session-results/ap-southeast-2:1aa6c156-07b4-4eaa-b476-1aef8353eba0/21/1524535549.179
        //WHERE N/A or NULL MEANS CANNOT LINK TO ANY DATA...but can list the students out
        //{"qGroupId": key, "Exam/Test Name": name, "# Students Attempted": value, "# Students Not Attempted": nbrNotAttmpt, "Action": "Select"}
        // [{ usrSub:"", fNam, lNam, tsFinish, bnd}, ...] with qGroupId

        const columns = ['First name', 'Last name', 'Attempt', 'Action'];
        let tbody = studentList.select('tbody');
        let studentListTitle = studentList.select("#sm-student-list-title")
        
        //Build static table element and header contents
        if(studentList.select('table').empty()) {
            studentListTitle = studentList
                .append("div")
                .attr('id', 'sm-student-list-title')
                .attr('class', 'content')

            studentListTitle
                .append('h3')

            tbody = createTableAndReturnBody(studentList, columns)
            
        } else {
            //Remove any existing tbody tr elements
            tbody.selectAll('tr').remove();
        }

        const titleBandText = d.bnd === null ? "Not attempted" : "Band " + d.bnd
        const titleNbr = d.nbrStudents > 1 ? d.nbrStudents + " students" : d.nbrStudents + " student"
        studentListTitle
            .select('h3')
            .text(examName + ' - ' + titleBandText + ' (' + titleNbr + ')')

        //Join data for table body
        // create a row for each object in the data
        const rowsUpdate = tbody.selectAll('tr')
            .data(students, d => d.usrSub)
            .enter()
            .append('tr')
            .on('click', (d, i, elements) => {
                //console.log(d, i, elements)
                if (d.tsFinish != null) {
                  //location.href = "/sm_session-results/" + d.usrSub + "/" + qGroupId + "/" + d.tsFinish;
                    //window.location = window.location + "#sm_session-results/" + d.usrSub + "/" + qGroupId + "/" + d.tsFinish

		    //let url = "http://localhost:3000/sm_session-results/" + d.usrSub + "/" + qGroupId + "/" + d.tsFinish;

		    window.pushUrl("/sm_session-results/" + d.usrSub + "/" + qGroupId + "/" + d.tsFinish);
    //history.pushState({url: url}, '', url);
    //if (ISDEBUG) { console.log("pushUrl received with:", url); }
    //if (ISDEBUG) { console.log("pushUrl location.href is::", location.href); }
//    app.ports.onUrlChange.send(location.href);


		}
            })
        
        // create a cell in each row for each column
        const cellsUpdate = rowsUpdate.selectAll('td')
            .data(row => {
                //console.log('row is', row)
                const retVal = columns.map(column => {
                    //return {column: column, value: row[column]};
                    let val;

                    const columnMapping = {"First name": "fNam", "Last name": "lNam", "Attempt": "tsFinish", "Action": "Action"}
                    
                    // Yields fNam, lNam, tsFinish, or (keeps) Action
                    const colName = columnMapping[column];

                    const isNoAttempt = row.tsFinish === null;
                    if (colName === "Action") {
                        val = isNoAttempt ? "N/A" : "View Results"
                    } else if (colName === "tsFinish") {
                        val = isNoAttempt ? "N/A" : formatDate(parseFloat(row.tsFinish*1000))
                    }  else {
                        val = row[colName] === null ? "N/A" : row[colName]
                    }
                    
                    return {column: column, value: val};
                })

                return retVal
            }
                 )

        cellsUpdate
            .enter()
            .append('td')
            .html(function (d) {
                let retVal;
                
                if (d.column === "Action") {
                    retVal = d.value === "N/A" ? "N/A" : `<div class='button is-primary is-small'>${d.value}</div>`
                } else {
                    retVal = d.value;
                }

                return retVal
            });
    })
}


const createTableAndReturnBody = (node, columns) => {

    const table = node
        .append('table')
        .attr('class', 'table is-hoverable is-striped is-fullwidth')
    const thead = table.append('thead')
    const tbody = table.append('tbody');
    
    // append the header row
    thead.append('tr')
        .selectAll('th')
        .data(columns).enter()
        .append('th')
        .text(column => column);

    return tbody;
}


//Derived from http://bl.ocks.org/jfreels/6734025
const createExamListTable = (node, data, columns, rowClickFunction) => {
    const tbody = createTableAndReturnBody(node, columns)
    
    // create a row for each object in the data
    const rows = tbody.selectAll('tr')
        .data(data)
        .enter()
        .append('tr')
        .on('click', function (d, i, elements) {
            //console.log(d, i, elements)
            //Remove any student list heading and table that may be rendered
            d3.select('#sm-student-list-title').remove();
            d3.select('#sm-student-list table').remove();
            
            d3.selectAll('#sm-exam-list td').classed('is-selected', false);
            d3.select(this).selectAll('td').classed('is-selected', true);
            rowClickFunction(d.qGroupId);
        })


    // create a cell in each row for each column
    const cells = rows.selectAll('td')
        .data(function (row) {
            return columns.map(function (column) {
                return {column: column, value: row[column]};
            });
        })
        .enter()
        .append('td')
        .html(function (d) {
            let retVal;
            
            if (d.column === "Action") {
                retVal = `<div class='button is-info is-small'>${d.value}</div>`
            } else {
                retVal = d.value;
            }

            return retVal
        });
}
