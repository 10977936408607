'use strict';

if (ISDEBUG) { console.log("PursBundleInterop.js: Top of script"); }

import * as S from 'sanctuary';
import * as $ from 'sanctuary-def';

import {node, encase, encaseP, chain, map, fork, Future} from 'fluture/index.js'

import { ERROR_TYPE, INTEROP_TAGS } from './Constants.js'

import { psMakeNode, psGetVariantSelection, psGetAllVariantIdsForNode, psGetSyllabuses } from 'sm-nap-onl-domain/index.js';


// 1. retrieve strings from endpoint in elm, then send here to constructNode for syllabus and return as D.Value and store in elm, then pass to getVariantSelection as a Value (already in purs Node format)

// Make calls in elm to endpoint for string, then implement psMakeNode for reals!
export const constructNodeForSyllabus = sendToElmPayload => msg => {
    //******* Note via msg which contains the target, this could be rationalized with getVariantSelection into a single accepting function.
    const pl = msg.payload
    const stringifiedCategories = JSON.stringify(pl.categories)
    const stringifiedItems = JSON.stringify(pl.items)
    //const node = psMakeNode(pl.syllabus)(pl.categories)(pl.items)()
    const node = psMakeNode(pl.syllabus)(stringifiedCategories)(stringifiedItems)()
    if (ISDEBUG) {console.log("In js constructNodeForSyllabus with payload and node", msg, node)};

    //structure from psMakeNode is Either String Node which will translate here to {valence:"Right|Left", value: Node|String} and extracted in elm as a Result String D.Value
    let resp = {value: node.value0}

    resp.valence = node.constructor.name === "Right" ? "Right" : "Left"
   
    if (ISDEBUG) {console.log("In js constructNodeForSyllabus about to return with ", resp)}
    sendToElmPayload(resp)
}

export const getSyllabuses = sendToElmPayload => msg => {
    //******* Note via msg which contains the target, this could be rationalized with getVariantSelection into a single accepting function.
    const pl = msg.payload
    
    //if (ISDEBUG) {console.log("In js getSyllabuses with msg and pl.categories before stringify", msg, pl.categories)};
    const stringifiedCategories = JSON.stringify(pl.categories)
    //if (ISDEBUG) {console.log("In js getSyllabuses with msg and stringifiedCategories", msg, stringifiedCategories)};
    const syllabuses = psGetSyllabuses(stringifiedCategories)()
    if (ISDEBUG) {console.log("In js getSyllabuses with payload and node", msg, syllabuses)};

    //structure from psGetSyllabuses is Either String (Array NodeDetail) which will translate here to {valence:"Right|Left", value: (Array NodeDetail)|String} and extracted in elm as a Result String D.Value
    let resp = {value: syllabuses.value0}

    resp.valence = syllabuses.constructor.name === "Right" ? "Right" : "Left"
   
    if (ISDEBUG) {console.log("In js getSyllabus about to return with ", resp)}
    sendToElmPayload(resp)
}


//This will call the psGetVariantSelection adaptive question selection engine with params activityMeta and history.
export const getVariantSelection = sendToElmPayload => msg => {

    // msg : {payload: {id: "1", targetNodeId: "s1" } , tag: "GetVariantSelectionETJ" }}
    //console.log("payload (msg) is", msg);

    let pl = msg.payload;

    const rConfig = { toleranceIncrement: 3
                      , maxToleranceDistance: 30 // 30 is best, but as at 2022-06-07 not enough questions to jump gaps in qstn difficulty for some subtopics.  CHANGED back to 30 as at 2022-11-22
                      , decrementFromPrevDiffAmt: 20
                      , syllabusNode: pl.node
                      , sessionQNbr: pl.sessionQNbr
                      , isDebug: ISDEBUG
                    }   

    
    // xxx make getVariantSelection process with sanctuary like getMe
    //let activityMeta = psMakeActivityMeta(am.id)(am.targetNodeId)(am.nodePercentages)(am.context)(am.focus);

    if (ISDEBUG) {console.log("History", pl.history)}
    
//    const history = [];

    // variantSelection is a (Tuple SelectionRationaleJsFormat (Maybe VariantId))
    let variantSelection = psGetVariantSelection(rConfig)(pl.activityMeta)(pl.history)(pl.targetStrike)()
    if (ISDEBUG) {console.log("variantSelection resp is", variantSelection)}
    
    sendToElmPayload(variantSelection)
}


//This will call the psGetVariantSelection adaptive question selection engine with params activityMeta and history.
export const getVariantIds = sendToElmPayload => msg => {

    // msg : {payload: {id: "1", targetNodeId: "s1" } , tag: "GetVariantSelectionETJ" }}
    //console.log("payload (msg) is", msg);

    let pl = msg.payload;

    // const rConfig = { toleranceIncrement: 2
    //                 ,maxToleranceDistance: 30
    //                 ,decrementFromPrevDiffAmt: 20
    //                 ,syllabusNode: pl.node}   

    
    // xxx make getVariantSelection process with sanctuary like getMe
    //let activityMeta = psMakeActivityMeta(am.id)(am.targetNodeId)(am.nodePercentages)(am.context)(am.focus);

    // variantSelection is a (Tuple SelectionRationaleJsFormat (Maybe VariantId))
    // let variantSelection = psGetVariantSelection(rConfig)(pl.activityMeta)(pl.history)()
    // if (ISDEBUG) {console.log("variantSelection resp is", variantSelection)}
    //console.log(psGetAllVariantIdsForNode(pl.node))
    
    //const allVariantIds=[{iTitle: "20057", vId: 0}, {iTitle: "20057", vId: 1},{iTitle: "20057", vId: 2},{iTitle: "20057", vId: 3},{iTitle: "20057", vId: 4}]
    sendToElmPayload(psGetAllVariantIdsForNode(pl.node))
}



// *********************** FODDER TO KEEP BELOW
// *********************** FODDER TO KEEP BELOW
// *********************** FODDER TO KEEP BELOW


    // if (psTest.constructor.name === "Right") {
    //     console.log("A right was returned with ", psTest.value0.value0, psTest.value0.value1)
    //     //RESET THE selectionType from purs class object to be a string (constructor name of Novel, AlternateVariant, BeenSeen)
        
    //     //console.log("psTest.value0.value1.selectionType.constructor.name", psTest.value0.value1.selectionType.constructor.value.constructor.name)
    //     //psTest.value0.value1.selectionType = selectionType
    // } else     if (psTest.constructor.name === "Left") {
    //     console.log("A left was returned with ", psTest.value0)
    // }



    // sendToElmPayload ({ code: "NodeCreationFailure"
    //                     , severity: ERROR_TYPE.severity.Fatal
    //                     , message : "Sorry but we were unable to create the correct question list for your syllabus."
    //                     , details : "details here from the left!"
    //                   })


// class RConfig {
//     constructor(){
//         if(! RConfig.instance){
//             //this._data = [];
//             const node = psMakeNode();
//             //console.log("node is (tbd: pass in json to make it, and possibly cache it in javascript singleton object?)", node);    

//             this._data = { toleranceIncrement: 2
//                     ,maxToleranceDistance: 30
//                     ,decrementFromPrevDiffAmt: 20
//                     ,syllabusNode: node}   
            
//             //console.log("RConfig constructor");
//             RConfig.instance = this;
//         }

//         return RConfig.instance;        
//   }

//   // add(item){
//   //   this._data.push(item);
//   // }

//   // get(id){
//   //   return this._data.find(d => d.id === id);
//     // }
//     getConfig() {
//         return this._data
//     }
// }


// *********************** FODDER TO DISCARD BELOW
// *********************** FODDER TO DISCARD BELOW
// *********************** FODDER TO DISCARD BELOW


import { me } from 'sm-nap-onl-shared/queries'

// ***********************************
// me
// ***********************************
export const getMe = sendToElmPayload => msg => {

    //console.log(greet("def"));
//    console.log(Main.greet("abcd"));


    
    const meFailure = rej => {
	//Extract code, and message if they exist in the rej(ection) else create a generic failure message.
	if(ISDEBUG) {console.log ('rejection!', rej)}


	//NOTE: rej is a string in this call **** So see other graphql return codes, and probably refactor the below

	
	let codeMaybe = S.get (S.is ($.String)) ('code') (rej) //Maybe String
	let messageMaybe = S.get (S.is ($.String)) ('message') (rej) //Maybe String
	let error = {
	    code: S.fromMaybe ("MeFailure") (codeMaybe),
	    severity: ERROR_TYPE.severity.Error,
	    message: S.fromMaybe ("Sorry but we were unable to retrieve your user details. " + rej.toString()) (messageMaybe),
	    details: JSON.stringify(msg)
	}
	sendToElmPayload (error)
    }
    
    const meSuccess = res => {

	console.log("res for meSuccess is", res);
	
	let payloadMaybe = S.gets (S.is ($.Object)) (['data', 'me']) (res) //data->me->id, sId, eData->sch/stu/tch only one of which will be complete, hstry[items]
        if(ISDEBUG) {console.log("payloadMaybe is", payloadMaybe)}

	let idMaybe = S.chain (S.get (S.is ($.String)) ('id')) (payloadMaybe) //Maybe String
	let sIdMaybe = S.chain (S.get (S.is ($.String)) ('sId')) (payloadMaybe) //Maybe String
	// let groups = S.pipe([
	//     S.chain (S.get (S.is ($.Unknown)) ('cognito:groups')),
	//     //S.fromMaybe (["Student"])
	//     S.fromMaybe ([])
	// ]) (payloadMaybe) //Array String
		
	//Return a payload of either an error structure OR a userDetails structure (without password)
	const buildPayload = payloadMaybe => {//idMaybe =>  sIdMaybe =>  {//groups => {
	    //let payloadMaybe = S.sequence (S.Maybe) (payloadMaybe) //Maybe payloadStruct

	    const invalidParamsToSignInPayload = () => ({
		code: "InvalidParameters",
		severity: ERROR_TYPE.severity.Fatal,
		message: "There is a problem with the application configuration that means we cannot log you in.  Please report this message to SmarterMaths (support@smartermaths.com.au) and we'll get it fixed asap!",
		details: `The amplify result from the graphQL function 'me' has id as ${S.fromMaybe ("Nothing") (idMaybe)} and the sId as ${S.fromMaybe ("Nothing") (sIdMaybe)} but both should be in the response!`
	    })
	    
	    //If Just payload then add the groups, else return error structure
	    let payload = S.pipe ([
		//S.map (S.concat ({groups: groups})),
		S.fromMaybe (invalidParamsToSignInPayload())
	    ]) (payloadMaybe)
		
	    return payload;
	}
	
	// resp from sendToElmPayload is undefined
	sendToElmPayload (buildPayload (payloadMaybe))//(idMaybe) (sIdMaybe))//; (groups)); 
	
	if (ISDEBUG) {console.log('NapOnlBackendAccess.js: payload to sendToElmPayload()', buildPayload (payloadMaybe))}
	if (ISDEBUG) {console.log('NapOnlBackendAccess.js: me', res)}
    }
    
    const tryMe = () =>
	  encaseP (el => API.graphql(graphqlOperation(me))) () 
    	  .pipe(
    	      fork
	      (meFailure)
    	      (meSuccess))

    tryMe()

    //sendToElmPayload ({id: "1234", sId: "nsw-school1"});

}

