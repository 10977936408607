// NOTE: ORIGINALLY GENERATED in naponlbackend  by AWS Amplify.
// But copied, and over-written here for client configs, and with the PRODUCTION VALUES for cognito.
// PROD VALUES ARE:
// awsconfig.aws_user_pools_id = "ap-southeast-2_CnELLRYZ9"
// awsconfig.aws_user_pools_web_client_id = "2giaqi5dijgvdevi6gimbea734"
// awsconfig.aws_cognito_identity_pool_id = "ap-southeast-2:376b7ddd-f137-47f5-9aa7-3a98e34293a7"




const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://vug2iyxe7vbpboppbn3mct6zyi.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-gc367mw4xbaxtfxpi5uw5uso7y",
    //"aws_cognito_identity_pool_id": "ap-southeast-2:ac3037d0-acac-4177-a87c-df794379cdaf",
    "aws_cognito_identity_pool_id": "ap-southeast-2:fac6480c-b812-4186-a614-d2d7021e4db0",
    //"aws_cognito_identity_pool_id": "ap-southeast-2:376b7ddd-f137-47f5-9aa7-3a98e34293a7",
    "aws_cognito_region": "ap-southeast-2",
    //"aws_user_pools_id": "ap-southeast-2_ZBQ76fuea",
    "aws_user_pools_id": "ap-southeast-2_CnELLRYZ9",
    //"aws_user_pools_id": "ap-southeast-2_71XJs23qZ",

    
    //"aws_user_pools_web_client_id": "4aljm259hcthdmhjpttp7sgsv0",
    "aws_user_pools_web_client_id": "2giaqi5dijgvdevi6gimbea734",
    //"aws_user_pools_web_client_id": "2gmtdk71c2pal2ng5igg4h2du1",

    
    //Note: see https://github.com/amazon-archives/amazon-cognito-identity-js/issues/253
    //"aws_user_pools_web_client_id": "8rqg6g6rh17nr3ihk7e2d3umg",
    
    "oauth": {
        "domain": "smnaponlbackend-prod.auth.ap-southeast-2.amazoncognito.com",
        //"domain": "explore.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    //Added 2020-03-10 as per https://aws-amplify.github.io/docs/js/storage (Note: @aws-amplify/storage added to package.json with manual config, but not added to amplify via cli)
    //"aws_user_files_s3_bucket" : "sm-explore-apd",
    //"aws_user_files_s3_bucket" : "sm-explore-apd2-prod",
    //"aws_user_files_s3_bucket_region": "ap-southeast-2"
    "aws_dynamodb_entity_table": "Entity-t7xudevnjrhd5hvgupir4ygzfq-prodtwo",
};


export default awsmobile;
