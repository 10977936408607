'use strict';

if (ISDEBUG) { console.log("index.js: Top of file"); };

import * as S from 'sanctuary';
import * as SD from 'sanctuary-def';

import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

import { getDataServiceFn } from './DataService';

import { INTEROP_TAGS } from './JsInterop/Constants'

import { handleRefreshIfAlreadyAuthenticated } from './JsInterop/SignInOut'

//Import GraphQL operations
import { teachers, students, studentsByTchId } from 'sm-nap-onl-shared/queries'
import { updateTeacher, updateStudent, deleteEntity, updateLoginUser, createTeacher, createStudent, deleteLoginUser, createEntity, deleteStudentHistory } from 'sm-nap-onl-shared/mutations'
import { me, bySId, nbrUsersForSchoolId, studentsWithHstry, studentsByTchIdWithHstry, createQuestionGroup, updateStudentRespondWithIdOnly } from './JsInterop/customGraphQLQueries'

//d3 analytics and graphs
import { subscribeAnalyticsPortFunctions } from './analytics/analytics';


// Required here to be included in build by webpack
require('font-awesome/css/font-awesome.css');
require('bulma/css/bulma.css');
require('bulma-accordion/dist/css/bulma-accordion.min.css');
require('bulma-tooltip/dist/css/bulma-tooltip.min.css');
require('./smia-styles.css');
//var intro = require('intro.js/minified/intro.min.js');
require('intro.js/minified/introjs.min.css');

//var AWS = require('aws-sdk');
var $ = require("jquery");
var jwtDecode = require('jwt-decode');

//2020-03-06 as per https://github.com/aws/aws-sdk-js/issues/399
// AWS.config.update({
//     correctClockSkew: true,
//   });

const CryptoJS = require("crypto-js");

// var AWSCognito = require('amazon-cognito-auth-js');
// var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
// xxx 2020-03-11 var AmazonCognitoIdentity = require('amazon-cognito-auth-js');
// xxx 2020-03-11 var CognitoAuth = AmazonCognitoIdentity.CognitoAuth;
// var jQuery = require("jquery");


if (ISDEBUG) { window.LOG_LEVEL = 'DEBUG' }

//import Amplify from 'aws-amplify';
//import '@aws-amplify/cache';
//import awsconfig from 'AWS_EXPORTS';
// see https://stackoverflow.com/questions/36367532/how-can-i-conditionally-import-an-es6-module for: const awsconfig = if etc.. require('prodxxx') else require ("staging");
import awsconfig from './aws-exports';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api'
//import Storage  from '@aws-amplify/storage';

//Amplify.configure(awsconfig);
API.configure(awsconfig);
Auth.configure(awsconfig);
//Storage.configure(awsconfig);


//import custom elements as per https://guide.elm-lang.org/interop/custom_elements.html 
// EXAMPLE Not used as at 2023-04-02
//import { schoolEditor } from './customElements/schoolEditor';


// Load the AWS SDK for Node.js
//var AWS = require('aws-sdk');
// Set the region 
//AWS.config.update({region: "ap-southeast-2"});
//AWS.config.update({region: 'REGION'});


//import DocumentClient from 'aws-sdk/clients/dynamodb';






// See amplify docs for storage
// Amplify.configure({
//     Auth: {
//         identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab', //REQUIRED - Amazon Cognito Identity Pool ID
//         region: 'XX-XXXX-X', // REQUIRED - Amazon Cognito Region
//         userPoolId: 'XX-XXXX-X_abcd1234', //OPTIONAL - Amazon Cognito User Pool ID
//         userPoolWebClientId: 'XX-XXXX-X_abcd1234', //OPTIONAL - Amazon Cognito Web Client ID
//     },
//     Storage: {
//         AWSS3: {
//             bucket: '', //REQUIRED -  Amazon S3 bucket
//             region: 'XX-XXXX-X', //OPTIONAL -  Amazon service region
//         }
//     }
// });

// Storage.configure({
//     Auth: {
//         identityPoolId: "ap-southeast-2:376b7ddd-f137-47f5-9aa7-3a98e34293a7",
//         region: "ap-southeast-2",
//         userPoolId: "ap-southeast-2_CnELLRYZ9",
//         userPoolWebClientId: "2giaqi5dijgvdevi6gimbea734",
//     },
//     Storage: {
//         AWSS3: {
//             bucket: "sm-explore-apd2-prod",
//             region: "ap-southeast-2",
//         }
//     }
// });



// var Main = require("../output/InteropPurs.Main/index.js");
// console.log(Main.greet("abc"));

//require('./Main.purs').main();
//require('./InteropPurs/Main.purs').main();
//require('./InteropPurs/Main.purs');

// if (module.hot) {
//   module.hot.accept();
// }


//var introguide = intro.introJs();

// mjd 2017-08-15 helper to render mathjax *** only used if refresh url - No longer needed, since mathJaxTypeset () port is called after retrieving each and every post
// require('./SMMathJaxHelper.js'); //NOT USED as at 2017-09-22


// Require index.html so it gets copied to dist
//2019-10-22 require('./index.html');

const SESSION_HAS_LOGGED_IN_FLAG = "1";
//const SESSION_CREDENTIALS_STR = "sessionCredentials";
const SESSION_STATE_STR = "2";
//const SESSION_ALREADY_RETRIED_LOGIN = "3";

//const serverEnv = (awsconfig.aws_appsync_graphqlEndpoint == "https://pj5jlhp74jdx5fds6ehxrfbwbu.appsync-api.ap-southeast-2.amazonaws.com/graphql") ? "prod" : "staging"
const serverEnv = (awsconfig.aws_appsync_graphqlEndpoint == "https://vug2iyxe7vbpboppbn3mct6zyi.appsync-api.ap-southeast-2.amazonaws.com/graphql") ? "prod" : "staging"



let app = Elm.Main.init({
    node: document.getElementById('app'),
    flags: {
        urlPrefixAppCfg: URLPREFIX_APPCFG,
        urlPrefixClientResources: URLPREFIX_CLIENT_RESOURCES,
        urlPosts: URL_POSTS,
        //loginUrl: "https://" + AWS_AppWebDomain + "/login?response_type=token&client_id=" + AWS_ClientId + "&redirect_uri=" + AWS_RedirectUriSignIn,
        version: VERSION,
        isDebug: ISDEBUG,
        serverEnv: serverEnv,
        //maxDiffRefreshMins: MAX_DIFF_REFRESH_MINS,
        initUrl: location.href, // as per https://github.com/elm/browser/blob/master/notes/navigation-in-elements.md
        // interopTagsJTE: S.values (INTEROP_TAGS.JTE),
        // interopTagsETJ: S.values (INTEROP_TAGS.ETJ)
        interopTags: S.values(INTEROP_TAGS),
        graphQLOps: {
            meGQL: me,
            teachersGQL: teachers,
            studentsGQL: students,
            studentsWithHstryGQL: studentsWithHstry,
            bySIdGQL: bySId,
            nbrUsersForSchoolIdGQL: nbrUsersForSchoolId,
            studentsByTchIdGQL: studentsByTchId,
            studentsByTchIdWithHstryGQL: studentsByTchIdWithHstry,
            updateTeacherGQL: updateTeacher,
            updateStudentGQL: updateStudent,
            updateStudentRespondWithIdOnlyGQL: updateStudentRespondWithIdOnly,
            updateLoginUserGQL: updateLoginUser,
            deleteLoginUserGQL: deleteLoginUser,
            deleteStudentHistoryGQL: deleteStudentHistory,
            createTeacherGQL: createTeacher,
            createStudentGQL: createStudent,
            createEntityGQL: createEntity,
            deleteSchoolGQL: deleteEntity,
            createQuestionGroupGQL: createQuestionGroup,

        }
    }
});


if (ISDEBUG) { console.log("app is now:", app); }



// Added for AWS Amplify NAPLAN Online version 2 as at 2019-11-26
import { initNapOnlBackendAccess } from './JsInterop/NapOnlBackendAccess';
initNapOnlBackendAccess(app);
//import './napOnlBackendAccess';


//import { initGraphQL2, pursHandleMsgsReceivedFromElm } from '../output/InteropPurs.GraphQL';
//const GraphQL = require ('../output/InteropPurs.GraphQL')

//console.log(GraphQL.initGraphQL("123"));
// MarriedTo2020-04-05 const sendToElm = tag => payload => () => Promise.resolve(app.ports.infoForElm.send({tag: tag, payload: payload }))


// const sendToElm = tag => payload => {
// 	// {tag: INTEROP_TAG.JTE.xyz, payload: ... }
// 	app.ports.infoForElm.send({tag: tag, payload: payload });
// }

// MarriedTo 2020-04-05 app.ports.infoForPurescript.subscribe(msg => {console.log("Before pursHandleMsgsReceivedFromElm"); (pursHandleMsgsReceivedFromElm (sendToElm) (msg))().then(a => console.log("After pursHandleMsgsReceivedFromElm"))});
//app.ports.infoForPurescript.subscribe(el => console.log('here'));
//initGraphQL2(sendToElm)().then(a => console.log(a))//.then(function(value) {console.log(value);})
//       {
//     // {tag: INTEROP_TAG.JTE.xyz, payload: ... }
//     return (el -> Promise.resolve(app.ports.infoForElm.send({tag: tag, payload: payload })));
// }

//console.log(res);
//res().then(ab => console.log("ran"));
//((res7, other ) => console.log("*********************** here is finished"))
//; //.then(res => console.log("promise res is", res))


import { handleGraphQLMsgsReceivedFromElm } from './JsInterop/GraphQL';
// const sendToElm2 = tag => payload => app.ports.infoForElm.send({tag: tag, payload: payload })
app.ports.infoForGraphQL.subscribe(msg => handleGraphQLMsgsReceivedFromElm(app.ports.infoForElm)(msg));


//2020-03-13 for new post access
const decryptPostContent = (ciphertext) => {

    const bytes = CryptoJS.AES.decrypt(ciphertext, String.fromCharCode(51) + '794826783725' + String.fromCharCode(54));
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    //console.log(originalText); // 'my message'
    app.ports.onDecodeContent.send(originalText);
    //return originalText;
}

app.ports.decodeContent.subscribe(decryptPostContent);


// import { sendGetPost } from './JsInterop/Storage';
// // const sendToElm2 = tag => payload => app.ports.infoForElm.send({tag: tag, payload: payload })
// app.ports.sendGetPost.subscribe(postId => sendGetPost (app.ports.onGetPostResponse) (postId));



// 2019-10-23 from https://github.com/elm/browser/blob/1.0.0/notes/navigation-in-elements.md
// var app = Elm.Main.init({
//     flags: location.href,
//     node: document.getElementById('elm-main')
// });

// Inform app of browser navigation (the BACK and FORWARD buttons)
// document.addEventListener("popstate", function () {
//     if (ISDEBUG) { console.log("location from popstate is: ", location); }
//     app.ports.onUrlChange.send(location.href);
// });

// window.onhashchange = function(event) {
//     console.log("hashchange:", event);
// }


window.onpopstate = function(event) {
    //alert("location: " + document.location + ", state: " + JSON.stringify(event.state));

    if (ISDEBUG) { console.log("window.onpopstate: location: ", location); }
    if (ISDEBUG) { console.log("window.onpopstate: document.referrer: ", document.referrer); }
    if (ISDEBUG) { console.log("window.onpopstate: event: ", event); }

    //if (document.referrer.includes('sm_showVariant')) {
    if (event.state && event.state.url && event.state.url.includes('sm_showVariant')) {
        // Note, this allows a single back in an exam since the StartActivity for showVariant pushes two sm_showVariant's to the pushState, so
        // the first one is passed onpopstate! (since the most recent one is already popped!)
        window.alert("\nSmarterMaths Warning!\n\nYou should not click back at this time.\n\nPlease click 'Home' instead (if it is visible), or if you really want to discard your work, click back again, or close the browser/tab.")
        // event.stopImmediatePropagation()
        // let url = location.origin + "/sm_showVariant"
        // history.pushState({url: url}, '', url);    
    } else {
        app.ports.onUrlChange.send(location.href);
    }
};


// Bound to window so can be called from within analytics/ByBand.js D3 document
window.pushUrl = function(url) {
    //Moving from elm 0.18 to elm 0.19 state was not used, so is currently left blank here as at 2019-10-24, but could now be used in future.
    history.pushState({ url: url }, '', url);
    if (ISDEBUG) { console.log("pushUrl received with:", url); }
    if (ISDEBUG) { console.log("pushUrl location.href is::", location.href); }
    app.ports.onUrlChange.send(location.href);
}


// Change the URL upon request, inform app of the change.
app.ports.pushUrl.subscribe(window.pushUrl);

// Make the browser go back
app.ports.browserBack.subscribe(function() {
    //Moving from elm 0.18 to elm 0.19 state was not used, so is currently left blank here as at 2019-10-24, but could now be used in future.
    history.back();
    if (ISDEBUG) { console.log("browserBack received"); }
    //Note: back will cause onpopstate to fire which will send: app.ports.onUrlChange.send(location.href); so DON'T do it here!
});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

let userSub;
let s3;
let docClient;

//AWS.config.region = AWS_Region;

//Create a local reference for the gtag Google Global Tag for Analytics
//let gtag; // Now defined in index.html

//let sendGetPost;
//let sendGetStudentHistory;
//let sendGetUserDataContents;
//let sendGetAssignees;
let sendGetSchoolName;
// let sendSaveStudentHistory;
//let sendSaveAssigneeDetail;
//let sendCreateUser;
//let sendUpdateUserGroup;
let sendGetHiddenExamGroups;
let sendPutHiddenExamGroups;
let sendDeleteHiddenExamGroups;

//Setup all analytics port functions
subscribeAnalyticsPortFunctions(app);

if (ISDEBUG) { console.log("index.js: After subscribeAnalyticsPortFunctions()"); };


const setupPortFunctions = (s3, docClient, apigClient) => {
    //console.log("index.js: setupPortFunctions()");
    //    sendGetPost = getDataServiceFn($, app, s3, docClient, userSub, apigClient, WEBPACK_isLocalObj, "sendGetPost");
    //sendGetStudentHistory = getDataServiceFn($, app, s3, docClient, userSub, apigClient, WEBPACK_isLocalObj, "sendGetStudentHistory");
    //sendGetUserDataContents = getDataServiceFn($, app, s3, docClient, userSub, apigClient, WEBPACK_isLocalObj, "sendGetUserDataContents");
    //sendGetAssignees = getDataServiceFn($, app, s3, docClient, userSub, apigClient, WEBPACK_isLocalObj, "sendGetAssignees");
    sendGetSchoolName = getDataServiceFn($, app, s3, docClient, userSub, apigClient, "sendGetSchoolName");
    // sendSaveStudentHistory = getDataServiceFn($, app, s3, docClient, userSub, apigClient, WEBPACK_isLocalObj, "sendSaveStudentHistory");
    //sendSaveAssigneeDetail = getDataServiceFn($, app, s3, docClient, userSub, apigClient, WEBPACK_isLocalObj, "sendSaveAssigneeDetail");
    //sendCreateUser = getDataServiceFn($, app, s3, docClient, userSub, apigClient, WEBPACK_isLocalObj, "sendCreateUser");
    //sendUpdateUserGroup = getDataServiceFn($, app, s3, docClient, userSub, apigClient, WEBPACK_isLocalObj, "sendUpdateUserGroup");
    sendGetHiddenExamGroups = getDataServiceFn($, app, s3, docClient, userSub, apigClient, "sendGetHiddenExamGroups");
    sendPutHiddenExamGroups = getDataServiceFn($, app, s3, docClient, userSub, apigClient, "sendPutHiddenExamGroups");
    sendDeleteHiddenExamGroups = getDataServiceFn($, app, s3, docClient, userSub, apigClient, "sendDeleteHiddenExamGroups");
};

const subscribePortFunctions = () => {
    //console.log("index.js: subscribePortFunctions()");
    //    app.ports.sendGetPost.subscribe(sendGetPost);
    //app.ports.sendGetStudentHistory.subscribe(sendGetStudentHistory);
    //app.ports.sendGetUserDataContents.subscribe(sendGetUserDataContents);
    //app.ports.sendGetAssignees.subscribe(sendGetAssignees);
    app.ports.sendGetSchoolName.subscribe(sendGetSchoolName);
    // app.ports.sendSaveStudentHistory.subscribe(sendSaveStudentHistory);
    //app.ports.sendSaveAssigneeDetail.subscribe(sendSaveAssigneeDetail);
    //app.ports.sendCreateUser.subscribe(sendCreateUser);
    //app.ports.sendUpdateUserGroup.subscribe(sendUpdateUserGroup);
    app.ports.sendGetHiddenExamGroups.subscribe(sendGetHiddenExamGroups);
    app.ports.sendPutHiddenExamGroups.subscribe(sendPutHiddenExamGroups);
    app.ports.sendDeleteHiddenExamGroups.subscribe(sendDeleteHiddenExamGroups);
};

const unsubscribePortFunctions = () => {
    //console.log("index.js: unsubscribePortFunctions()");
    //    app.ports.sendGetPost.unsubscribe(sendGetPost);
    //app.ports.sendGetStudentHistory.unsubscribe(sendGetStudentHistory);
    //app.ports.sendGetUserDataContents.unsubscribe(sendGetUserDataContents);
    //app.ports.sendGetAssignees.unsubscribe(sendGetAssignees);
    app.ports.sendGetSchoolName.unsubscribe(sendGetSchoolName);
    // app.ports.sendSaveStudentHistory.unsubscribe(sendSaveStudentHistory);
    //app.ports.sendSaveAssigneeDetail.unsubscribe(sendSaveAssigneeDetail);
    //app.ports.sendCreateUser.unsubscribe(sendCreateUser);
    //app.ports.sendUpdateUserGroup.unsubscribe(sendUpdateUserGroup);
    app.ports.sendGetHiddenExamGroups.unsubscribe(sendGetHiddenExamGroups);
    app.ports.sendPutHiddenExamGroups.unsubscribe(sendPutHiddenExamGroups);
    app.ports.sendDeleteHiddenExamGroups.unsubscribe(sendDeleteHiddenExamGroups);
};

//Given ["SessionsResultsRoute", "ap-southeast-2:26454300-b429-4259-ae96-eb2f958f9121", "11"]
// then send page_path as /SessionsResutlsRoute, and
// page_title as '["SessionsResultsRoute", "ap-southeast-2:26454300-b429-4259-ae96-eb2f958f9121", "11"]'
const sendGoogleAnalyticsUpdate = (strs) => {

    const pPath = strs.length == 0 ? "UndefinedPath" : strs[0].toString();

    if (ISDEBUG) { console.log("sendGoogleAnalyticsUpdate", JSON.stringify(strs)); };

    gtag('config', 'UA-63185717-4', {
        'page_path': "/" + pPath,
        'page_title': JSON.stringify(strs)
    });

    // gtag('event', pPath, {
    // 	'event_category' : 'UserAction',%
    // 	'event_label' : JSON.stringify(strs)
    // });
}

// Called from app after ...
const sendInitializeGoogleAnalytics = (params) => {
    // params is {userId, schoolName, userType, yearLvl} and types are {string, string, string, string}
    if (ISDEBUG) { console.log("sendInitializeGoogleAnalytics", JSON.stringify(params)); };

    gtag('config', 'UA-63185717-4', {
        'user_id': params.userId,
        'dimension1': params.schoolName,
        'dimension2': params.userType,
        'dimension3': params.yearLvl
    });

    //Check if session to know if this is a login or a refresh
    //WILL NOT WORK SINCE NOT CALLED UNTIL AFTER SESSION SET
    // if (getSessionStorage(SESSION_HAS_LOGGED_IN_FLAG)) {
    // 	sendGoogleAnalyticsUpdate(["refresh"]);
    // } else {
    // 	sendGoogleAnalyticsUpdate(["login"]);
    // }
}


const initializeGoogleAnalytics = (user_id, decodedIdToken) => {

    let sm_schoolName = "Undefined";
    let sm_userType = "Undefined";
    let sm_yearLevel = "N/A";

    if (decodedIdToken) {
        //Note:  The school name will need to be obtained via an aws lambda for validNonAdminTeachers and validStudents (will only show here for validAdminTeachers)
        if (decodedIdToken["custom:schoolName"]) { sm_schoolName = decodedIdToken["custom:schoolName"].toString(); };
        if (decodedIdToken["cognito:groups"] && decodedIdToken["cognito:groups"].length > 0) { sm_userType = decodedIdToken["cognito:groups"][0]; };
        if (decodedIdToken["custom:studentLevel"]) { sm_yearLevel = decodedIdToken["custom:studentLevel"].toString(); };
    }

    gtag('set', { 'user_id': user_id, 'dimension1': sm_schoolName, 'dimension2': sm_userType, 'dimension3': sm_yearLevel }); // Set the user ID using signed-in user_id.

    if (ISDEBUG) { console.log("AWS.config.credentials.data.IdentityId and analytics fields", user_id, sm_schoolName, sm_userType, sm_yearLevel); }

    // gtag('event', 'screen_view', {
    // 	'app_name': 'SmarterMaths NAPLAN Online',
    // 	'screen_name' : 'Home'
    // });

    sendGoogleAnalyticsUpdate(["login"]);
}


const logout = () => {
    // console.log("about to auth.signOut()");
    clearSessionStorage();
    auth.signOut();
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials = null;
};


const clearSessionStorage = (possibleItem) => {
    if (typeof (Storage) !== "undefined") {
        if (ISDEBUG) { console.log("clearSessionStorage") };

        if (possibleItem) {
            sessionStorage.removeItem(possibleItem);
        } else {
            sessionStorage.removeItem(SESSION_HAS_LOGGED_IN_FLAG);
            sessionStorage.removeItem(SESSION_STATE_STR);
        }
    }
}

const setSessionStorage = (tokenName, dataToStore) => {
    if (typeof (Storage) !== "undefined") {
        if (ISDEBUG) { console.log("tokenName", tokenName, "dataToStore", dataToStore) }

        //encrypt dataToStore before storing it
        const encryptedDataToStore = CryptoJS.AES.encrypt(JSON.stringify(dataToStore), '79245');

        sessionStorage[tokenName] = encryptedDataToStore.toString();
    }
}

const getSessionStorage = (tokenName) => {
    let retVal = null;

    if ((typeof (Storage) !== "undefined") && sessionStorage[tokenName]) {

        // retrieve encrypted dataFromStore
        const encryptedDataFromStore = sessionStorage[tokenName];

        try {
            // Decrypt
            const bytes = CryptoJS.AES.decrypt(encryptedDataFromStore, '79245');
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            if (ISDEBUG) { console.log("getSessionStorage for tokenName", tokenName, decryptedData) };

            retVal = decryptedData;
        } catch (err) {
            console.log("index.js: Error: could not decrypt session storage so supressing error, clearing storage, and returning nothing from storage. Err was:", err);
            clearSessionStorage();
        }
    }

    return retVal;
}


let auth;


// mjd 2017-09-22
// require('./SMQuestionAnswerHelper.js');
// 2017-09-22 Use delegated jquery events (see http://api.jquery.com/on/) to attach event handler to answer options after post content is rendered
// Note: the outer $(abc) runs once after page load for an SPA without refreshes as per https://api.jquery.com/ready/
// *******************
// index.js is included by webpack.config.common.js and this is the entrypoint of the entire application
$(
    (() => {

        const sendToElm = tag => payload => {
            // {tag: INTEROP_TAG.JTE.xyz, payload: ... }
            app.ports.infoForElm.send({ tag: tag, payload: payload });
        }

        //Auth.currentCredentials().then( credsSuccess(sendPutHiddenExamGroupsFn), credsFailure("XXX: failed creds"));
        //Auth.currentUserInfo().then(user => console.log("User INFO is:", user), e => console.log("user info failure:", e));
        //Auth.currentCredentials().then( ((el) => console.log("AAA: Succeeded Creds", el)), (() => console.log("XXX: Failed Creds")));	
        handleRefreshIfAlreadyAuthenticated(sendToElm);


        const sessionState = getSessionStorage(SESSION_STATE_STR);
        if (sessionState) {
            if (ISDEBUG) { console.log("index.js: $(): sessionState", sessionState); };
            app.ports.onRetrievePartialModelState.send(sessionState);
        }


        //2020-04-04 REMOVE THIS sessionHasLoggedInFlag ... replaced now in v3 with Auth.currentAuthenticatedUser().then(..etc.)


        //This can be changed to isInitialLogin as t/f since don't use the contents, nor transmit them to elm, only use as a flag for setupAWS, or just initCognitoSDK+getSession
        //	const sessionHasLoggedInFlag = getSessionStorage(SESSION_HAS_LOGGED_IN_FLAG);


        //	if (sessionHasLoggedInFlag) {
        //Clear only the tokens (not entire sessionStorage so can refresh >1 time in the middle of an exam without changing the question or page [and thus having session state be saved again])
        //	    sessionStorage.removeItem(SESSION_HAS_LOGGED_IN_FLAG);
        //clearSessionStorage();


        //if (ISDEBUG) { console.log("index.js: $(): sessionHasLoggedInFlag"); };


        //if (ISDEBUG) { console.log("index.js: $(): Before initCognitoSDK()"); };
        // xxx 2020-03-11 auth = initCognitoSDK();

        //if (ISDEBUG) { console.log("index.js: $(): Before auth.getSession()"); };
        // xxx 2020-03-11 auth.getSession();
        //if (ISDEBUG) { console.log("index.js: $(): After auth.getSession()"); };

        //retrieveCredentials(sessionHasLoggedInFlag.idToken);
        //	} else {
        //if (ISDEBUG) { console.log("index.js: $(): NOT logged in!"); };
        //if (ISDEBUG) { console.log("index.js: $(): Before setupAWS()"); };
        // xxx 2020-03-11 setupAWS();
        //	}

    })(),

    //console.log("here"),
    // Using key == "Enter" as per https://stackoverflow.com/questions/12955222/how-to-trigger-html-button-when-you-press-enter-in-textbox/24245592#24245592
    $(":root").on("keyup", event => {
        //if (ISDEBUG) { console.log("index.js: $(): Keyup event in the document (:root)"); };
        //console.log("A key was pressed");
        if (event.key == "Enter") {
            if ($("#sm-btn-next-qstn").length) {
                //console.log("Send next question event");
                app.ports.onActionTriggerRequest.send({ action: "ShowNextQuestion" });
            }
        }
    }),

    $("#main").on("click", ".sm-a-option", function() {
        let clickedOption = $(this);
        // console.log("clickedOption is: ", clickedOption);

        let clickedOptionElement = clickedOption[0];
        let allOptions = $(".sm-a-option");
        // var previouslySelected = jQuery(".sm-a-selected");
        // var previouslySelectedOptionIdx = null;

        // calculate the index of this element
        let clickedOptionIdx = allOptions.toArray().indexOf(clickedOptionElement);

        //smpid is the postId
        //let postId = $('#smpid').getAttribute("data-smpid"); <- use this in the browser console
        let postId = $('#smpid').attr("data-smpid");

        //Create the secret "83566" in a weak way!
        let s = String.fromCharCode(56) + String.fromCharCode(51) + "56" + String.fromCharCode(54);

        //c is the classname of the correct answer
        let c = "_" + CryptoJS.HmacSHA1(postId, s).toString();

        //app.ports.receiveMCIsAnswerCorrect.send([clickedOptionIdx, clickedOption.hasClass("sm-a-correct")]);
        app.ports.receiveMCIsAnswerCorrect.send([clickedOptionIdx, clickedOption.hasClass(c)]);
    }),

    $("#main").on("keypress", ".sm-red-box-a", function(ev) {
        //FILTER the keys able to be pressed in a sm-red-box-a
        //0-9 or . or -
        //See also update.elm filtering of expectedVal_
        //from https://stackoverflow.com/questions/469357/html-text-input-allows-only-numeric-input
        return ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 45 && event.charCode <= 46));
    }),

    $("#main").on("keyup", ".sm-red-box-a", function(ev) {

        // console.log('An .sm-red-box was typed into, and val is now: ', ev.target.value);

        //Assess if result is correct or not (answer is embedded in html)
        // var isCorrect = true;
        // console.log(ev);
        app.ports.receiveStringIsAnswerCorrect.send(ev.target.value);

    })
);


//mjd 2017-09-22 for mathJax typesetting after content has been rendered for each specific question
app.ports.mathJaxTypeset.subscribe(function() {
    // setTimeout(function() { MathJax.Hub.Queue(["Typeset", MathJax.Hub]); }, 100);

    // let checkExist2 = setInterval(function() {
    //     console.log("not yet");
    //     if ($('.katex').length) {
    //         renderMathInElement(document.body);
    //         //clearInterval(checkExist2);
    //     }
    // }, 1000); // check every x milliseconds

    let checkExist = setInterval(function() {
        // console.log("not yet");
        if ($('.sm-mjax').length) {
            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
            clearInterval(checkExist);
        }
    }, 20); // check every x milliseconds
});

// app.ports.sendGetSession.subscribe(function() {
//     console.log("about to auth.getSession()");
//     //auth.getSession();
// });

//app.ports.sendSignOut.subscribe(logout);

app.ports.sendGoogleAnalytics.subscribe(sendGoogleAnalyticsUpdate);
app.ports.sendInitializeGoogleAnalytics.subscribe(sendInitializeGoogleAnalytics)

app.ports.sendStorePartialModelState.subscribe(function(partialModelState) {
    if (ISDEBUG) { console.log("index.js: sendStorePartialModelState function with partialModelState of: ", partialModelState); };
    setSessionStorage(SESSION_STATE_STR, partialModelState);
});

//2019-10-23 commented out since probably not used?
// app.ports.sendClearPartialModelState.subscribe(function() {
//     if(ISDEBUG) { console.log("index.js: sendClearPartialModelState function"); };
//     clearSessionStorage (SESSION_STATE_STR);

// });


app.ports.sendRefreshCredentials.subscribe(function() {
    // console.log("about to sendRefreshCredentials()")
    // retrieveCredentials();
    // setupAWS();
    // AWS.config.credentials.clearCachedId()
    // initCognitoSDK();

    // setupAWS();
    //refreshCredentials();

    // console.log("after sendRefreshCredentials()")
});


// 2020-03-04 v2 enhancements
// as at 2020-03-04 used for posts
//   xxx  s3 = new AWS.S3({
//     apiVersion: '2006-03-01'
// });

//Used for hidden exam settings
//docClient = new DocumentClient();
//console.log("docClient new dynamodb is", docClient);

// Create the DynamoDB service object
//docClient = new AWS.DynamoDB.DocumentClient();
//console.log("docClient new dynamodb is", docClient);

// docClient = new AWS.SimpleDB({
// 	apiVersion: '2009-04-15'
// });

// In new v2, as at 2020-03-04 only used for getSchoolName lambda (purescript) which is only used for googleAnalytics, and reverted to unimplemented as at v2 2020-03-04
// let apigClient = apigClientFactory.newClient({
//     accessKey: AWS.config.credentials.accessKeyId,
//     secretKey: AWS.config.credentials.secretAccessKey,
//     sessionToken: AWS.config.credentials.sessionToken, //OPTIONAL: If you are using temporary credentials you must include the session token
//     region: AWS_Region // OPTIONAL: The region where the API is deployed, by default this parameter is set to us-east-1
// });


// *******************************************
//
// Svg encoding for elm consumption and decoding in localElmPackages/html-parser/src/Html/Parser/Util.elm
//
// *******************************************
const encodeSvg = (svgString) => {
    let retVal = document.createElement("p")
    retVal.className = "smsvg"
    retVal.innerText = btoa(svgString)
    return retVal
}

//Recusive
const processChildrenForSvg = (el) => {
    for (let i = 0; i < el.children.length; i++) {
        if (el.children[i].tagName === "svg") {
            el.replaceChild(encodeSvg(el.children[i].outerHTML), el.children[i])
        } else {
            el.replaceChild(processChildrenForSvg(el.children[i]), el.children[i])
        }
    }
    return el
}

// encodeSmSvg : String -> String
// encodeSmSvg "<p>...</p>" == "<p>...</p>"
// encodeSmSvg "<svg>...</svg>" == "<p class=''>...</p>"
const encodeSmSvg = (content) => {
    //if this string version of the node starts with <svg, then transform it for elm consumption
    // we createElement as a div and set it's innerHTML then return the innerHTML to use the parsing capabilities of createElement
    if (content.includes("<svg")) {
        const el = document.createElement('div');
        el.innerHTML = content;
        let processedContent = processChildrenForSvg(el)
        return processedContent.innerHTML
    } else {
        return content
    }

}


const makeSmSvgAnswers = qType => aSet => {
    const updateAnswers = (aSet_) => {
        return aSet_.map(
            el => {
                let retVal_ = el
                retVal_.answer = encodeSmSvg(el.answer)
                return retVal_
            }
        )
    }

    //Note: Not needed for Answer Box qType as of 2021-07-08
    const isToApplyInlineSvgPatch = qType_ => S.or(S.equals(qType_)("Multiple Choice (One Answer)"))(S.equals(qType_)("Multiple Choice (Multiple Answer)"))
    return (isToApplyInlineSvgPatch(qType)) ? updateAnswers(aSet) : aSet
}


//Setup generic decrypt ports used in new minstd course as at 2020-09-17
app.ports.sendDecryptItem.subscribe(function(decryptRequest) {

    //See src/Type/Decrypt.elm for types
    //if (ISDEBUG) { console.log("decryptRequest is", decryptRequest) }

    const bytes = CryptoJS.AES.decrypt(decryptRequest.ciphertext, decryptRequest.key);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    //const cleartext = JSON.parse(originalText);
    const decryptedObj = JSON.parse(originalText);

    if (ISDEBUG) { console.log("index.js: raw decryptedObj (before SmSvg parsing)", JSON.stringify(decryptedObj)) }

    //added 2021-05-11 to handle inline svg encoding for future chemistry and katex mchem elements
    decryptedObj.qContent = encodeSmSvg(decryptedObj.qContent)
    decryptedObj.wsContent = encodeSmSvg(decryptedObj.wsContent)

    decryptedObj.aSet = makeSmSvgAnswers(decryptedObj.qType)(decryptedObj.aSet)

    if (ISDEBUG) { console.log("index.js: about to reply to app.ports.onDecryptItemResponse", JSON.stringify(decryptedObj)) }

    app.ports.onDecryptItemResponse.send({ contentTag: decryptRequest.contentTag, responseHandler: decryptRequest.responseHandler, decrypted: decryptedObj });
});





//setupPortFunctions(s3, docClient, apigClient);
// Leave apigClient for lambda access unimplemented as at v2 2020-03-04 and will re-implement school name for google analytics later
setupPortFunctions(null, null, null);
subscribePortFunctions();

