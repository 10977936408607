'use strict';

import Auth  from '@aws-amplify/auth';
var AWS = require('aws-sdk');



const CryptoJS = require("crypto-js");

export const getDataServiceFn = ($, app, s3, docClient, userSub, apigClient, fnName) => {
    let f = null;

    //console.log("DataService.js: getDataServiceFn: about to ", fnName);

    switch (fnName) {
        // case "sendGetPost":
        //     {
        //         f = isLocalObj[fnName] ? sendGetPost_local($, app, userSub) : sendGetPost_S3(app, s3);
        //         break;
        //     }
        // case "sendGetStudentHistory":
        //     {
        //         // f = isLocalObj[fnName] ? sendGetStudentHistory_local($, app) : sendGetStudentHistory_S3(app, s3, userSub);
        //         f = isLocalObj[fnName] ? sendGetStudentHistory_local($, app, userSub) : sendGetStudentHistory_S3(app, s3, userSub);
        //         break;
        //     }
        // case "sendGetUserDataContents":
        //     {
        //         // f = isLocalObj[fnName] ? sendGetStudentHistory_local($, app) : sendGetStudentHistory_S3(app, s3, userSub);
        //         f = isLocalObj[fnName] ? sendGetUserDataContents_local($, app, userSub) : sendGetUserDataContents_S3(app, s3, userSub);
        //         break;
        //     }
        // case "sendGetAssignees":
        //     {
        //         f = isLocalObj[fnName] ? sendGetAssignees_local($, app, userSub) : sendGetAssignees_APIG(app, apigClient);
        //         break;
        //     }
        case "sendGetSchoolName":
            {
                f = sendGetSchoolName_APIG(app, apigClient);
                break;
            }
        // case "sendSaveStudentHistory":
        //     {
        //         f = isLocalObj[fnName] ? sendSaveStudentHistory_local($, app, userSub) : sendSaveStudentHistory_S3(app, s3, userSub);
        //         break;
        //     }
        // case "sendSaveAssigneeDetail":
        //     {
        //         f = isLocalObj[fnName] ? sendSaveAssigneeDetail_local($, app, userSub) : sendSaveAssigneeDetail_S3(app, s3, userSub);
        //         break;
        //     }
        // case "sendCreateUser":
        //     {
        //         f = isLocalObj[fnName] ? sendCreateUser_local($, app, userSub) : sendCreateUser_APIG(app, apigClient);
        //         break;
        //     }
        // case "sendUpdateUserGroup":
        //     {
        //         f = isLocalObj[fnName] ? sendUpdateUserGroup_local($, app, userSub) : sendUpdateUserGroup_APIG(app, apigClient);
        //         break;
        //     }
        case "sendGetHiddenExamGroups":
            {
                f = sendGetHiddenExamGroups_DynamoDB(app, docClient);
                break;
            }
        case "sendPutHiddenExamGroups":
            {
                f = sendPutHiddenExamGroups_DynamoDB(app, docClient);
                break;
            }
        case "sendDeleteHiddenExamGroups":
            {
                f = sendDeleteHiddenExamGroups_DynamoDB(app, docClient);
                break;
            }
        default:
            {
                alert("SmarterMaths says: \n\nOops!  We've misconfigured something, and the app will not work properly.  \n\nPlease notify us at (support@smartermaths.com.au) and we'll get it working for you ASAP!  \n\nMany thanks, SmarterMaths Support.");
                break;
            }
    }
    return f;

};

// *************************************************************************************************************************
// 
// sendGetPost
// ----------------
// 
// *************************************************************************************************************************


const decryptPostContent = (ciphertext) => {

    const bytes  = CryptoJS.AES.decrypt(ciphertext, String.fromCharCode(51) + '794826783725' + String.fromCharCode(54));
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    //console.log(originalText); // 'my message'

    return originalText;
}


// const sendGetPost_S3 = (app, s3) => {
//     if (ISDEBUG) {console.log("sendGetPost_S3", app, s3)};
//     return (postId) => {
//         if (ISDEBUG) {console.log("about to s3 getPost", postId);}

//         let params = {
//             Bucket: AWS_Bucket_Post,
//             Key: "posts/" + postId + ".json",
// 	    IfModifiedSince: 1546300800
//         };
//         s3.getObject(params, function(err, data) {
//             if (err) {
//                 console.log(err, err.code, err.message, err.stack); // an error occurred
//                 // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
//                 // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
//                 let msg = err.message.length === 0 ? "Unable to retrieve the question details." : err.message;
//                 app.ports.onGetPostResponse.send(msg);
//             } else {
//                 // console.log(data.Body.toString()); // successful response

// 		//Initially tempJson has content.rendered that is aes encrypted
// 		var tempJSON = JSON.parse(data.Body.toString());
// 		tempJSON.content.rendered = decryptPostContent(tempJSON.content.rendered);
		
//                 app.ports.onGetPostResponse.send(tempJSON);
//             }
//         });
//     };
// };

// const sendGetPost_local = ($, app, userSub) => {
//     return (postId) => {
//         console.log("about to locally!!! db.json getPost", postId);

//         $.ajax({
//             url: "http://localhost:4000/posts/" + postId,
//             dataType: 'json',
//             success: function(data) {
// 		// aes unencrypt the content rendered section
// 		//console.log("data is:", data);
		
// 		data.content.rendered = decryptPostContent(data.content.rendered);
//                 app.ports.onGetPostResponse.send(data);
//             },
//             error: function(msg) {
//                 app.ports.onGetPostResponse.send(msg);
//             }
//         });

//     };
// };


// *************************************************************************************************************************
// 
// sendGetUserDataContents
// ----------------
// 
// *************************************************************************************************************************


// const sendGetUserDataContents_S3 = (app, s3, userSub) => {
//     return () => {
//         // console.log("about to s3 getStudentHistory");

//         const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";

//         //console.log("Cognito Id is!!!!", cognitoId);

//         let params = {
//             Bucket: AWS_Bucket_UserData,
//             // Key: "users/" + userSub + "/studentHistory.json"
//             Key: cognitoId + "/userData.json"
//                 // Key: "ap-southeast-2:ecece4be-0283-4b0e-9deb-1c04c6a8a51a/studentHistory.json"

//             // Key: "users/studentHistory.json"
//         };
//         s3.getObject(params, function(err, data) {
//             if (err) {
//                 // console.log(err, err.code, err.message, err.stack); // an error occurred
//                 // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
//                 // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
//                 let msg = err.message.length === 0 ? "Unable to retrieve your user details." : err.message
//                 if (ISDEBUG) { console.log("DataService.js: sendGetUserDataContents_S3: Error", err.message); }
//                 app.ports.onGetUserDataContentsResponse.send(msg);
//             } else {
//                 if (ISDEBUG) { console.log("DataService.js: sendGetUserDataContents_S3", data.Body.toString()); }
//                 app.ports.onGetUserDataContentsResponse.send(JSON.parse(data.Body.toString()));
//             }
//         });

//     }
// }

// const sendGetUserDataContents_local = ($, app, userSub) => {
//     return () => {

//         app.ports.onGetUserDataResponse.send("Status: NOT IMPLEMENTED FOR LOCAL USAGE");

// 	// // const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";

//         // console.log("about to locally!!! db.json getUserDataContents", userSub);

//         // $.ajax({
//         //     url: "http://localhost:4000/users/" + userSub + "_studentHistory.json",
//         //     dataType: 'json',
//         //     success: function(data) {
//         //         app.ports.onGetStudentHistoryResponse.send(data);
//         //     },
//         //     error: function(msg) {
//         //         app.ports.onGetStudentHistoryResponse.send(msg);
//         //     }
//         // });

//     };
// }



// *************************************************************************************************************************
// 
// sendGetStudentHistory
// ----------------
// 
// *************************************************************************************************************************


// const sendGetStudentHistory_S3 = (app, s3, userSub) => {
//     return () => {
//         // console.log("about to s3 getStudentHistory");

//         const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";

//         //console.log("Cognito Id is!!!!", cognitoId);

//         let params = {
//             Bucket: AWS_Bucket_UserStudent,
//             // Key: "users/" + userSub + "/studentHistory.json"
//             Key: cognitoId + "/studentHistory.json"
//                 // Key: "ap-southeast-2:ecece4be-0283-4b0e-9deb-1c04c6a8a51a/studentHistory.json"

//             // Key: "users/studentHistory.json"
//         };
//         s3.getObject(params, function(err, data) {
//             if (err) {
//                 // console.log(err, err.code, err.message, err.stack); // an error occurred
//                 // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
//                 // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
//                 let msg = err.message.length === 0 ? "Unable to retrieve your user details." : err.message
//                 app.ports.onGetStudentHistoryResponse.send(msg);
//             } else {
//                 // console.log(data.Body.toString()); // successful response
//                 app.ports.onGetStudentHistoryResponse.send(JSON.parse(data.Body.toString()));
//             }
//         });

//     }
// }

// const sendGetStudentHistory_local = ($, app, userSub) => {
//     return () => {
// 	// const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";

//         //console.log("about to locally!!! db.json getStudentHistory", userSub);

//         $.ajax({
//             url: "http://localhost:4000/users/" + userSub + "_studentHistory.json",
//             dataType: 'json',
//             success: function(data) {
//                 app.ports.onGetStudentHistoryResponse.send(data);
//             },
//             error: function(msg) {
//                 app.ports.onGetStudentHistoryResponse.send(msg);
//             }
//         });

//     };
// }


// *************************************************************************************************************************
// 
// sendGetAssignees
// ----------------
// 
// *************************************************************************************************************************

// const sendGetAssignees_APIG = (app, apigClient) => {
//     return (teacherSub) => {
//         // console.log("About to sendGetAssignees_APIG", teacherSub);

//         // const makeStudent = (i) => { return '{ "usrSub": "' + i + 'new77789b12-ddbd-40ae-8040-cfd2b4f22527", "typ": "s", "lvl": 9, "fNam": "' + i + 'news1s3_f", "lNam": "' + i + 'news1s3_l", "email": "' + i + 'news1s3@smartermaths.com.au", ' + '"sSub": "' + teacherSub + '", "sts": "v", "grp": "9_' + ((i % 4) + 1) + '", "hstry": [] }'; }
//         // //OLDconst makeStudent = (i) => { return '{ "usrSub": "' + i + 'new77789b12-ddbd-40ae-8040-cfd2b4f22527", "typ": "s", "lvl": 9, "fNam": "' + i + 'news1s3_f", "lNam": "' + i + 'news1s3_l", "email": "' + i + 'news1s3@smartermaths.com.au", ' + '"sSub": "' + teacherSub + '", "sts": "v", "grp": ' + null + ', "hstry": [] }'; }


//         // // console.log("QWERQWERQWER", Array.from(Array(10).keys()).map(makeStudent));
//         // let studentString = Array.from(Array(100).keys()).map(makeStudent).reduce((a, b) => a + "," + b);

//         // const resp = JSON.parse(
//         //     '{ "data": { "isSuccess": true, "data": [' +
//         //     studentString +
//         //     '] }, "status": 200, "statusText": "", "headers": { "content-type": "application/json" }, "config": { "method": "GET", "headers": { "Accept": "application/json", "x-amz-date": "20180317T044939Z", "Authorization": "AWS4-HMAC-SHA256 Credential=ASIAISTDL54P73JIVTOQ/20180317/us-east-1/execute-api/aws4_request, SignedHeaders=accept;host;x-amz-date, Signature=91b5a0c6a55e19db0028a62549b4dea6aa10946fc015840dd26d56ce9385bc74", "x-amz-security-token": "AgoGb3JpZ2luEIH//////////wEaCXVzLWVhc3QtMSKAAkv8AlyJHqAQZTx6peKHYeC/44GzSlm2Hz6bQxbdNXlbKSJuD5lRkDUAJ8thtWUUAlV/GuNZwbd6X1VpGJgdJGHsCQyvg81QXnbPKqhDpF/fgbQh3fC1aga3w8evWWyw/A/bdXA8pri4yXDStsOvcQt4IH8j7sT2VyHKlipB76WL3mO31jTh+7bQFckRTi0u4Q4D5dEMccsUCQVHiX4NSOEH2OZtyBPRpo6wBEDhkDU/Phs11samFaoZ7xEpgZTpfqJp3aumD5RWJiEk/qwcXBjWVF9XfzpgZkULWWawcmyghE0C6h54EVIqjjdGF2ShK4LW4auAv8JT905J8OhNiRAqrwUIhv//////////ARAAGgw4NzYzNjMxODc2NjQiDD15UIa6VGkMbt1m9SqDBUCGWOP4uA+3JTnrqAPmTAhwNhyQI7Tk9B2ZK9sddu0L0deDUmO/R/jZF1WQhvkPK4YdGi5filiJVrk6qDD1jPwIMN2VdiSJo+mh2KKvMvQe5T0pNQZHIjqx4M9NNcPo7fW2HhxgoUuzqKZ5xjnZMLi2+KBvnwQ11U8tP+e5ELFuq9YO2+ZIQ+Uu+/fSWA8KdJujAF8t7gF+/5DXvgDFUDQ03mWwxGwjZF0kJXVhS+uMFVBYePMGmdDZ8rYayUrZgxm15iHrJP2z7LEtxQ2b3FdP4iSFNsCp1OeQmJQ1BzLm8SdnWyAzyWTQGjT0sJbbG6rtUAj3y48Vs6YaRY0ujzj+Uk/G5acqpufbQyIFfe0zZxpulT6zLMPA6VnXRsDnLvaT/UuLhlmsFLYX0UJEIToP3MjVfpHzZidPHz6iQ6sED+JSwAR7LkL8KLzqThUPo6yl/jubNm7f7EgMyyMj+f2yJqUJhclc5ybtlxHstQqpaQmIjucOQJuu7UJrj+XFYUOSWbyOgQe8sfPXqmHbcJtYO+eoA84DpzMEmK9/MftVDEE4DB4T1kAjxmc6SFgGnD6MjzglWYQ4RScsQKvBV6A2qvcQHUI7a7PHFKeWX8b7ththE79JlR1M8hXk3zxi/UtdJK+905LrqWXr063PKGYHQC/xihjmPBFZWC9pyj1c3gv9KxvW2pEGalkQuTcykvrUSzWnPd7ZzSn/e4PSvHoqGBQKb/hHoRyIpE5WP6F+BoMsZ4sV8Jv63buqQAH+taRQVhpJejBiqm9gH7aVk9jEkaAN0iRNSPBXEDPArVv4A4hFo0jJZ2cM5GFS8fiqLR7vzUrdn9bXZXjcNZ/QJLO91yYw47yy1QU=", "Content-Type": "application/json" }, "timeout": 0, "transformRequest": [null], "transformResponse": [null], "url": "https://7pfv0isiwl.execute-api.us-east-1.amazonaws.com/prod/me/assignees/list", "data": "" } }'
//         // );


//         let userSettings = {};
//         // const promise = teacherSub ? Promise.resolve(resp) : apigClient.meAssigneesListGet({}, userSettings, {});
//         const promise = teacherSub ? apigClient.userUsersubAssigneesListGet({ usersub: teacherSub }, userSettings, {}) : apigClient.meAssigneesListGet({}, userSettings, {});

//         promise.then(function(result) {
//             // console.log(" res is", JSON.stringify(result));
//             //Send back the results
//             // app.ports.onCreateUserResponse.send({ isSaved: true });

//             app.ports.onGetAssigneesResponse.send({ "teacherSubOfAssignees": teacherSub, "assignees": result });
//         }).catch(function(result) {
//             //This is where you would put an error callback
//             // console.log("status is", result.status);
//             // console.log("statusCode is", result.statusCode);
//             // console.log("failure res is", result);
//             app.ports.onGetAssigneesResponse.send(result);
//             // app.ports.onCreateUserResponse.send("Status:" + msg.status + " - " + msg.statusText + " - " + msg.responseText);
//             // app.ports.onGetAssigneesResponse.send(result);
//         });
//     };
// }

// const sendGetAssignees_local = ($, app, userSub) => {
//     return () => {
//         app.ports.onCreateUserResponse.send("Status: NOT IMPLEMENTED FOR LOCAL USAGE");
//     };
// }



// *************************************************************************************************************************
// 
// sendGetSchoolName
// -----------------
// 
// *************************************************************************************************************************

const sendGetSchoolName_APIG = (app, apigClient) => {
    if (ISDEBUG) { console.log("DataService.js: sendGetSchoolName_APIG: About to return function"); }
    return () => {
        let userSettings = {};
	if (ISDEBUG) { console.log("DataService.js: sendGetSchoolName_APIG: Invocated and at top of execution"); }

        // const promise = apigClient.meSchoolGet({}, userSettings, {});

        // promise.then(function(result) {
	//     if (ISDEBUG) { console.log("DataService.js: sendGetSchoolName_APIG", result); }
        //     app.ports.onGetSchoolNameResponse.send(result.data); // {"schoolName": "etc.."}
        // }).catch(function(result) {
        //     app.ports.onGetSchoolNameResponse.send(result.statusText);
        // });

	//REPLACE ABOVE TEMPORARILY as at 2020-03-04

        app.ports.onGetSchoolNameResponse.send({"schoolName": "to be implemented as at 2020-03-04"}); // {"schoolName": "etc.."}

    };
}

// const sendGetSchoolName_local = ($, app, userSub) => {
//     return () => {
// 	if (ISDEBUG) { console.log("DataService.js: sendGetSchoolName_local: Invocated and at top of execution"); }
//         app.ports.onGetSchoolNameResponse.send("Status: NOT IMPLEMENTED FOR LOCAL USAGE");
//     };
// }



// *************************************************************************************************************************
// 
// sendSaveStudentHistory
// ----------------
// 
// *************************************************************************************************************************

// const sendSaveStudentHistory_S3 = (app, s3, userSub) => {
//     return (studentData) => {

//         // console.log("about to s3 saveStudentHistory");

//         const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";

//         // console.log("Cognito Id is!!!!", cognitoId);

//         let params = {
//             Bucket: AWS_Bucket_UserStudent,
//             // Key: "users/" + userSub + "/studentHistory.json",
//             Key: cognitoId + "/studentHistory.json",
//             // Key: "users/studentHistory.json"
//             ContentType: 'application/json',
//             Body: JSON.stringify(studentData)
//         };

//         // console.log("studentData is", studentData);
//         s3.putObject(params, function(err, data) {
//             if (err) {
//                 console.log("In sendSaveStudentHistory_S3", err, err.code, err.message, err.stack); // an error occurred
//                 // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
//                 // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
//                 let msg = err.message.length === 0 ? "Unable to save your user details." : err.message
//                 app.ports.onSaveStudentHistoryResponse.send(msg);
//             } else {
//                 //Note: data will be {} if no etags or versioning enabled on bucket, etc..  Body is not in response => So send { isSaved: true }
//                 // see https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#putObject-property
//                 // console.log("sendSaveStudentHistory putObject response is: ", data); // successful response
//                 app.ports.onSaveStudentHistoryResponse.send({ isSaved: true });
//             }
//         });

//     };
// };




// const sendSaveStudentHistory_local = ($, app, userSub) => {
//     return (studentData) => {
//         // const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";


//         // let rawData = JSON.stringify(studentData);

//         //Trim off the first { and the last }
//         // let strippedData = rawData.substr(1, rawData.length - 2);
//         // JSON.stringify(a).substr(1, JSON.stringify(a).length-2)

//         console.log("Now about to locally!!! db.json save user data", userSub, studentData);


//         $.ajax({
//             method: "PUT",
//             url: "http://localhost:4000/users/" + userSub + "_studentHistory.json",
//             dataType: 'json',
//             data: JSON.stringify(studentData),
//             contentType: "application/json",
//             success: function(data) {
//                 app.ports.onSaveStudentHistoryResponse.send({ isSaved: true });
//             },
//             error: function(msg) {
//                 app.ports.onSaveStudentHistoryResponse.send(msg);
//             }
//         });

//     };
// };

// *************************************************************************************************************************
// 
// sendSaveAssigneeDetail
// ----------------
// 
// *************************************************************************************************************************

// const sendSaveAssigneeDetail_S3 = (app, s3, teacherSub) => {
//     return (assigneeBase) => {

//         //assigneeDetail is an AssigneeDetail with the 'base' field flattened out to yield the following (file format) [like this but encoded for json]
//         // { usrSub : String
//         //     , typ : UserType - "typ: s=student, t=nonAdminTeacher, *** admin teachers won't appear in the list of assignees since are assigned to SmarterMaths"
//         //     , lvl : Maybe Int -- null or 3/5/7/9
//         //     , fNam : String
//         //     , lNam : String
//         //     , email : String
//         //     , sts : UserStatus - p/v/i/
//         //     , grp : Maybe String
//         //     }
//         const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";
//         // console.log("about to sendSaveAssigneeDetail_S3 with cognitoId, assigneeBase", cognitoId, assigneeBase);

//         let params = {
//             Bucket: AWS_Bucket_UserSupervisor,
//             // Key: "users/" + userSub + "/studentHistory.json",
//             Key: cognitoId + "/" + assigneeBase.usrSub + ".json",
//             // Key: "users/studentHistory.json"
//             ContentType: 'application/json',
//             Body: JSON.stringify(assigneeBase)
//         };

//         // console.log("studentData is", studentData);
//         s3.putObject(params, function(err, data) {
//             if (err) {
//                 console.log("In sendSaveAssigneeDetail_S3", err, err.code, err.message, err.stack); // an error occurred
//                 // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
//                 // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
//                 let msg = err.message.length === 0 ? "Unable to save your user details." : err.message
//                 app.ports.onSaveAssigneeDetailResponse.send(msg);
//             } else {
//                 //Note: data will be {} if no etags or versioning enabled on bucket, etc..  Body is not in response => So send { isSaved: true }
//                 // see https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#putObject-property
//                 // console.log("sendSaveStudentHistory putObject response is: ", data); // successful response
//                 app.ports.onSaveAssigneeDetailResponse.send({ isSaved: true });
//             }
//         });

//     };
// };




// const sendSaveAssigneeDetail_local = ($, app, teacherSub) => {
//     return (studentData) => {
//         // const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";
//         app.ports.onSaveAssigneeDetailResponse.send("Status: NOT IMPLEMENTED FOR LOCAL USAGE");

//         // let rawData = JSON.stringify(studentData);

//         //Trim off the first { and the last }
//         // let strippedData = rawData.substr(1, rawData.length - 2);
//         // JSON.stringify(a).substr(1, JSON.stringify(a).length-2)

//         // console.log("Now about to locally!!! db.json save user data", userSub, studentData);


//         // $.ajax({
//         //     method: "PUT",
//         //     url: "http://localhost:4000/users/" + teacherSub + "_studentHistory.json",
//         //     dataType: 'json',
//         //     data: JSON.stringify(studentData),
//         //     contentType: "application/json",
//         //     success: function(data) {
//         //         app.ports.onSaveStudentHistoryResponse.send({ isSaved: true });
//         //     },
//         //     error: function(msg) {
//         //         app.ports.onSaveStudentHistoryResponse.send(msg);
//         //     }
//         // });

//     };
// };


// *************************************************************************************************************************
// 
// sendCreateUser
// ------------------
// 
// Parameters Are:
// 
// Parameter group
// createUser_toAdminEmail (if set then process this thread always for current users sub)
// createUser_fromAdminEmail (may be blank if setMePending_userType is also blank, else error)
// createUser_userLevel (0 if teacher, 7/9 if student else error)

// Parameter group
// setMePending_userType

// Parameter group
// changeGroup_userSub (if set then process this thread, and it then it can’t be blank and must be either a student, or teacher (error if it’s a supervisor))
// changeGroup_fromGroup (must be not null and either a pending (translated to pendingYYY based on userSub type), validYYY or invalidYYY)
// changeGroup_toGroup (must be not null, different from fromGroup, and either valid or invalid (translated to pendingYYY based on userSub type))

// Parameter group
// setMyUserAttr_schoolName (if SchoolAdministrator will be set to schoolName, else if not schoolAdministrator will be ignored)
// *************************************************************************************************************************

// const sendCreateUser_APIG = (app, apigClient) => {
//     return (regAppFields) => {

//         // console.log("About to sendCreateUser_APIG, with regAppFields", regAppFields);

//         // const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";

//         // console.log("Cognito Id is!!!!", cognitoId);

//         // let userSettings = {
//         //     "fromAdminEmail": null,
//         //     "toAdminEmail": "to@matt.com",
//         //     "studentYearLevel": 9,
//         //     "schoolName": null
//         // }

//         // let userSettings = {
//         //     "fromAdminEmail": null,
//         //     "toAdminEmail": null,
//         //     "studentYearLevel": null,
//         //     "schoolName": "Test School Hardcoded"
//         // }

//         // const cognitoId = AWS.config.credentials.identityId;

//         // apigClient.setMyAdministratorGet({ Authorization: AWS.config.credentials.identityId }, {}, { headers: { Authorization: AWS.config.credentials.identityId } }).then(function(result) {
//         //
//         // apigClient.userUsersubPatch({ usersub: decodedIdToken.sub }, userSettings, {}).then(function(result) {


//         // apigClient.mePatch({ usersub: decodedIdToken.sub }, userSettings, {}).then(function(result) {
//         apigClient.mePatch({}, regAppFields, {}).then(function(result) {

//             //This is where you would put a success callback
//             // console.log("success res is", result);
//             app.ports.onCreateUserResponse.send(result);
//         }).catch(function(result) {
//             //This is where you would put an error callback
//             // console.log("status is", result.status);
//             // console.log("statusCode is", result.statusCode);
//             // console.log("failure res is", result);

//             // e.g. includes:
//             // data : {
//             // developerMessage: "In na_setUserSettings lambda function with invalid input params combination: Cannot match input parameters to a sub-function. Body is {"fromAdminEmail":null,"toAdminEmail":"sdfsdf","studentYearLevel":null,"schoolName":null,"toAdminEmail":null,"studentYearLevel":null}"
//             //  ,isSuccess: false
//             // , userMessage: "We have an error in the system!  ...etc"

//             app.ports.onCreateUserResponse.send(result);

//         });



//         // let params = {
//         //     Bucket: AWS_Bucket_UserStudent,
//         //     Key: "users/" + cognitoId + "/studentHistory.json",
//         //     // Key: "users/studentHistory.json"
//         //     ContentType: 'application/json',
//         //     Body: JSON.stringify(studentData)
//         // };

//         // // console.log("studentData is", studentData);
//         // s3.putObject(params, function(err, data) {
//         //     if (err) {
//         //         console.log(err, err.code, err.message, err.stack); // an error occurred
//         //         // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
//         //         // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
//         //         let msg = err.message.length === 0 ? "Unable to save your user details." : err.message
//         //         app.ports.onSaveStudentHistoryResponse.send(msg);
//         //     } else {
//         //         //Note: data will be {} if no etags or versioning enabled on bucket, etc..  Body is not in response => So send { isSaved: true }
//         //         // see https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#putObject-property
//         //         // console.log("sendSaveStudentHistory putObject response is: ", data); // successful response
//         //         app.ports.onSaveStudentHistoryResponse.send({ isSaved: true });
//         //     }
//         // });

//     };
// };

// // 1. Validate the parameters passed to ensure will be a valid request (done in elm, and also in lambda function? - or share code/module between lambda and this js may be better.)
// // 2. Update cognito group membership
// // 3. Update studentHistory.json
// const sendCreateUser_local = ($, app, userSub) => {
//     return (fromAdminEmail) => {
//         app.ports.onCreateUserResponse.send("Status: NOT IMPLEMENTED FOR LOCAL USAGE");
//     };
// };

// *************************************************************************************************************************
// 
// sendUpdateUserGroup
// ------------------
// 
// Parameters Are:
// 
// Parameter group
// changeGroup_userSub (if set then process this thread, and it then it can’t be blank and must be either a student, or teacher (error if it’s a supervisor))
// changeGroup_fromGroup (must be not null and either a pending (translated to pendingYYY based on userSub type), validYYY or invalidYYY)
// changeGroup_toGroup (must be not null, different from fromGroup, and either valid or invalid (translated to pendingYYY based on userSub type))
// 
// *************************************************************************************************************************

// const sendUpdateUserGroup_APIG = (app, apigClient) => {
//     return (updateUserGroupFields) => {

//         //console.log("About to sendUpdateUserGroup_APIG, with updateUserGroupFields", updateUserGroupFields);

//         // const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";

//         // console.log("Cognito Id is!!!!", cognitoId);

//         // let userSettings = {
//         //     "fromGroup": "tbd",
//         //     "toGroup": "tbd"
//         // }
//         //userSubToChange gets put into in patch path

//         // apigClient.userUsersubPatch({ usersub: decodedIdToken.sub }, userSettings, {}).then(function(result) {
//         const usersub = updateUserGroupFields.usersub;

//         // apigClient.mePatch({ usersub: decodedIdToken.sub }, userSettings, {}).then(function(result) {
//         // const retVal = apigClient.userUsersubChangeGroupPatch({}, updateUserGroupFields, {}).then(function(result) {
//         apigClient.userUsersubChangeGroupPatch({ usersub: usersub }, updateUserGroupFields, {}).then(function(result) {
//             //This is where you would put a success callback
//             const resultIncludingReqFields = result;
//             resultIncludingReqFields.req = updateUserGroupFields;
//             // console.log("success res is", resultIncludingReqFields);
//             app.ports.onUpdateUserGroupResponse.send(resultIncludingReqFields);
//         }).catch(function(result) {
//             const resultIncludingReqFields = result;
//             resultIncludingReqFields.req = updateUserGroupFields;
//             // console.log("failure res is", resultIncludingReqFields);
//             // e.g. includes:
//             // data : {
//             // developerMessage: "In na_setUserSettings lambda function with invalid input params combination: Cannot match input parameters to a sub-function. Body is {"fromAdminEmail":null,"toAdminEmail":"sdfsdf","studentYearLevel":null,"schoolName":null,"toAdminEmail":null,"studentYearLevel":null}"
//             //  ,isSuccess: false
//             // , userMessage: "We have an error in the system!  ...etc"
//             app.ports.onUpdateUserGroupResponse.send(resultIncludingReqFields);
//         });

//         // console.log("sendUpdateUserGroup_APIG return value", retVal);
//     };
// };

// const sendUpdateUserGroup_local = ($, app, userSub) => {
//     return (fromAdminEmail) => {
//         app.ports.onCreateUserResponse.send("Status: NOT IMPLEMENTED FOR LOCAL USAGE");
//     };
// };



// *************************************************************************************************************************
// 
// sendPutHiddenExamGroups_DynamoDB
// ------------------
// 
// Parameters Are:
// 
// cognitoIdentityId_hiddenExamGroups_tuple
// 
// *************************************************************************************************************************

const sendPutHiddenExamGroups_DynamoDB = (app, docClientOLD) => {
    return (cognitoIdentityId_hiddenExamGroups_tuple) => {

        //console.log("About to sendPutHiddenExamGroups_DynamoDB, with cognitoIdentityId, hiddenExamGroups", cognitoIdentityId_hiddenExamGroups_tuple);

	// SHOULD BE ABLE TO make [attributePair] I think?

	// aws --output json --region ap-southeast-2 dynamodb put-item --table-name sm-explore-hidden-exams --item {"id": {"S": "ap-southeast-2:bd546121-ae7c-48c5-8fe5-ba93bceb47ab"}, "hids": {"M": {"9": {"SS": ["89","19"]}, "7": {"SS": ["55","123"]}, "5": {"SS": ["23","13"]} }}}
	// var params = {
	//     TableName :"sm-explore-hidden-exams",
	//     Item: {"id": "ap-southeast-2:bd546121-ae7c-48c5-8fe5-ba93bceb47ab",
	// 	   "hids": {"9": ["89","19"], "7": ["55","123"], "5": ["23","987"]} 
	// 	  }
	// };

	const sendPutHiddenExamGroupsFn = (docClient) => {
	    var params = {
		TableName :"sm-explore-hidden-exams",
		//ReturnValues: "ALL_NEW", doesn't work as at 2019-02-13 - see https://github.com/aws/aws-sdk-js/issues/803
		Item: {"id": cognitoIdentityId_hiddenExamGroups_tuple[0], //"ap-southeast-2:bd546121-ae7c-48c5-8fe5-ba93bceb47ab",
		       "hids": cognitoIdentityId_hiddenExamGroups_tuple[1] //{9: [89,19], 7: [55,123], 5: [23,987]} 
		      }
	    };
	    	
	    docClient.put(params, function(err, data) {
		if (err) {
		    console.log("In sendPutHiddenExamGroups_DynamoDB", JSON.stringify(err));
                    //let msg = err.message.length === 0 ? "Unable to update your exam hide/show selection." : err.message
                    //app.ports.onPutHiddenExamGroupsResponse.send({"hids":msg});
		    let userMessage = "Unable to update your exam hide/show selection."
                    let msg = err.message.length === 0 ? userMessage : err.message
		    //NOTE NOTE NOTE ***** NEEDS TO BE DONE: Error handling as at 2019-02-13 for now will REUSE the deleteHiddenExamGroupsResponse to show error to end user
                    app.ports.onDeleteHiddenExamGroupsResponse.send({data: { isSuccess: false, userMessage: userMessage, developerMessage: msg }});
		} else {
		    //console.log(JSON.stringify(data));
                    //Note: data will be {} if no etags or versioning enabled on bucket, etc..  Body is not in response => So send { isSaved: true }
		    //If success, then the input values were successfully updated, so return them (note: returnValues ALL_NEW does not work with put ... see https://github.com/aws/aws-sdk-js/issues/803)
                    app.ports.onPutHiddenExamGroupsResponse.send({"hids": cognitoIdentityId_hiddenExamGroups_tuple[1]});
		}
	    });
	}
	
	Auth.currentCredentials().then( credsSuccess(sendPutHiddenExamGroupsFn), credsFailure("sendPutHiddenExamGroups_DynamoDB"));
	
    };
};

// const sendPutHiddenExamGroups_local = ($, app, userSub) => {
//     return (fromAdminEmail) => {
//         app.ports.onPutHiddenExamGroupsResponse.send("Status: NOT IMPLEMENTED FOR LOCAL USAGE");
//     };
// };



// *************************************************************************************************************************
// 
// sendDeleteHiddenExamGroups_DynamoDB
// ------------------
// 
// Parameters Are:
// 
// Parameter group
// cognitoIdentityId
// 
// *************************************************************************************************************************

const sendDeleteHiddenExamGroups_DynamoDB = (app, docClientOLD) => {
    return (uNameInPool) => {

        //console.log("About to sendDeleteHiddenExamGroups_DynamoDB, with cognitoIdentityId", cognitoIdentityId);

	// SHOULD BE ABLE TO make [attributePair] I think?

	const sendDeleteHiddenExamGroupsFn = (docClient) => {
	    var params = {
		TableName :"sm-explore-hidden-exams",
		Key: {"id": uNameInPool}
	    };
	    docClient.delete(params, function(err, data) {
		if (err) {
		    console.log("In sendDeleteHiddenExamGroups_DynamoDB", JSON.stringify(err));
		    let userMessage = "Unable to update your exam hide/show selection. (Attempting to delete all selections)"
                    let msg = err.message.length === 0 ? userMessage : err.message
                    app.ports.onDeleteHiddenExamGroupsResponse.send({data: { isSuccess: false, userMessage: userMessage, developerMessage: msg }});
		} else {
		    //console.log(JSON.stringify(data));
                    //Note: data will be {} if no etags or versioning enabled on bucket, etc..  Body is not in response => So send { isSaved: true }
                    app.ports.onDeleteHiddenExamGroupsResponse.send({data: { isSuccess: true }});
		}
	    });
	}
	
	Auth.currentCredentials().then( credsSuccess(sendDeleteHiddenExamGroupsFn), credsFailure("sendDeleteHiddenExamGroups_DynamoDB"));

	

	// docClient.deleteAttributes(params, function(err, data) {
	//     if (err) console.log(err, err.stack); // an error occurred
	//     else     console.log(data);           // successful response



	//     if (err) {
        //         console.log(err, err.code, err.message, err.stack); // an error occurred
        //         // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
        //         // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
        //         let msg = err.message.length === 0 ? "Unable to update your exam hide/show selection." : err.message
        //         app.ports.onDeleteAttributesResponse.send(msg);
        //     } else {
        //         //Note: data will be {} if no etags or versioning enabled on bucket, etc..  Body is not in response => So send { isSaved: true }
        //         // see https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#putObject-property
        //         // console.log("sendSaveStudentHistory putObject response is: ", data); // successful response
        //         app.ports.onDeleteAttributesResponse.send({ isSaved: true });
        //     }
	// });
    };
};

// const sendDeleteHiddenExamGroups_local = ($, app, userSub) => {
//     return (fromAdminEmail) => {
//         app.ports.onDeleteHiddenExamGroupsResponse.send("Status: NOT IMPLEMENTED FOR LOCAL USAGE");
//     };
// };



// *************************************************************************************************************************
// 
// sendGetHiddenExamGroups_DynamoDB
// ------------------
// 
// Parameters Are:
// 
// cognitoIdentityId
// 
// *************************************************************************************************************************


const credsSuccess = hiddenExamGroupsFn => creds => {	    
    //console.log("***** credentials are", creds);
    var config = new AWS.Config({credentials: creds, region: creds._clientConfig.region});	    
    AWS.config.update(config);
    var docClient2 = new AWS.DynamoDB.DocumentClient();
    
    // var params = {
    // 	TableName :"sm-explore-hidden-exams",
    // 	Key: {"id": }
    // 	//Key: {"id": cognitoIdentityId}
    // };

    hiddenExamGroupsFn(docClient2);
    // 2020-03-11
    // docClient.get(params, function(err, data) {
    // 	if (err) {
    // 	    //NOTE NOTE **** CURRENTLY as at 2019-02-13 this will fail with a message to the console only (effectively silent to the end user)
    // 	    console.log("In sendGetHiddenExamGroups_DynamoDB", JSON.stringify(err));
    //         //let msg = err.message.length === 0 ? "Unable to retrieve suppressed exam ids." : err.message
    //         //app.ports.onGetHiddenExamGroupsResponse.send(msg);
    // 	} else {
    // 	    //console.log("in sendGetHiddenExamGroups_DynamoDB");
    // 	    //console.log(JSON.stringify(data), data);
    //         //app.ports.onGetAttributesResponse.send(JSON.parse(data.Item.toString()));
    //         app.ports.onGetHiddenExamGroupsResponse.send(data.Item);
    // 	}
    // });
}

const credsFailure = fnName => err => console.log("Failed to successfully execute Auth.currentCredentials() as precusor to " + fnName + JSON.stringify(err))


const sendGetHiddenExamGroups_DynamoDB = (app, docClientOLD) => {
    return (uNameInPool) => {

	//NOTE: input parameter cognitoIdentityId no longer used as at 2020-03-16 - rather it is now retrieved from the credentials
        //console.log("About to sendGetHiddenExamGroups_DynamoDB, with cognitoIdentityId", cognitoIdentityId);
	// SHOULD BE ABLE TO make [attributePair] I think?

	const sendGetHiddenExamGroupsFn = (docClient) => {
	    var params = {
		TableName :"sm-explore-hidden-exams",
		Key: {"id": uNameInPool}
	    };
	    
	    docClient.get(params, function(err, data) {
		if (err) {
		    //NOTE NOTE **** CURRENTLY as at 2019-02-13 this will fail with a message to the console only (effectively silent to the end user)
		    console.log("In sendGetHiddenExamGroups_DynamoDB", JSON.stringify(err));
                    //let msg = err.message.length === 0 ? "Unable to retrieve suppressed exam ids." : err.message
                    //app.ports.onGetHiddenExamGroupsResponse.send(msg);
		} else {
		    //console.log("in sendGetHiddenExamGroups_DynamoDB");
		    //console.log(JSON.stringify(data), data); 
                    //app.ports.onGetAttributesResponse.send(JSON.parse(data.Item.toString()));
                    app.ports.onGetHiddenExamGroupsResponse.send(data.Item);
		}
	    });
	}
	
	Auth.currentCredentials().then( credsSuccess(sendGetHiddenExamGroupsFn), credsFailure("sendGetHiddenExamGroups_DynamoDB"));

	// docClient.deleteAttributes(params, function(err, data) {
	//     if (err) console.log(err, err.stack); // an error occurred
	//     else     console.log(data);           // successful response



	//     if (err) {
        //         console.log(err, err.code, err.message, err.stack); // an error occurred
        //         // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
        //         // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
        //         let msg = err.message.length === 0 ? "Unable to update your exam hide/show selection." : err.message
        //         app.ports.onDeleteAttributesResponse.send(msg);
        //     } else {
        //         //Note: data will be {} if no etags or versioning enabled on bucket, etc..  Body is not in response => So send { isSaved: true }
        //         // see https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#putObject-property
        //         // console.log("sendSaveStudentHistory putObject response is: ", data); // successful response
        //         app.ports.onDeleteAttributesResponse.send({ isSaved: true });
        //     }
	// });
    };
};

// const sendGetHiddenExamGroups_local = ($, app, userSub) => {
//     return (fromAdminEmail) => {
//         app.ports.onGetHiddenExamGroupsResponse.send("Status: NOT IMPLEMENTED FOR LOCAL USAGE");
//     };
// };



// *************************************************************************************************************************
// 
// FODDER BELOW
// ------------------
// 
// *************************************************************************************************************************



// app.ports.sendGetStudentHistory.subscribe(function() {
//     // console.log("about to s3 getStudentHistory");

//     const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";

//     // console.log("Cognito Id is!!!!", cognitoId);

//     let params = {
//         Bucket: AWS_Bucket_UserStudent,
//         Key: "users/" + cognitoId + "/studentHistory.json"
//             // Key: "users/studentHistory.json"
//     };
//     s3.getObject(params, function(err, data) {
//         if (err) {
//             console.log(err, err.code, err.message, err.stack); // an error occurred
//             // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
//             // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
//             let msg = err.message.length === 0 ? "Unable to retrieve your user details." : err.message
//             app.ports.onGetStudentHistoryResponse.send(msg);
//         } else {
//             // console.log(data.Body.toString()); // successful response
//             app.ports.onGetStudentHistoryResponse.send(JSON.parse(data.Body.toString()));
//         }
//     });

// })


// app.ports.sendSaveStudentHistory.subscribe(function(studentData) {
//     console.log("about to s3 saveStudentHistory");

//     const cognitoId = AWS.config.credentials.identityId; //"us-east-1:b5fb8c7c-137e-4761-b81c-ba93fcf0f25b";

//     // console.log("Cognito Id is!!!!", cognitoId);

//     let params = {
//         Bucket: AWS_Bucket_UserStudent,
//         Key: "users/" + cognitoId + "/studentHistory.json",
//         // Key: "users/studentHistory.json"
//         ContentType: 'application/json',
//         Body: JSON.stringify(studentData)
//     };

//     // console.log("studentData is", studentData);
//     s3.putObject(params, function(err, data) {
//         if (err) {
//             console.log(err, err.code, err.message, err.stack); // an error occurred
//             // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
//             // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
//             let msg = err.message.length === 0 ? "Unable to save your user details." : err.message
//             app.ports.onSaveStudentHistoryResponse.send(msg);
//         } else {
//             //Note: data will be {} if no etags or versioning enabled on bucket, etc..  Body is not in response => So send { isSaved: true }
//             // see https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#putObject-property
//             // console.log("sendSaveStudentHistory putObject response is: ", data); // successful response
//             app.ports.onSaveStudentHistoryResponse.send({ isSaved: true });
//         }
//     });

// })


// app.ports.sendGetAppCfg.subscribe(function() {
//     console.log("about to s3 getAppCfg");

//     var params = {
//         Bucket: AWS_Bucket,
//         Key: "appCfg.json"
//     };
//     s3.getObject(params, function(err, data) {
//         if (err) {
//             console.log(err, err.code, err.message, err.stack); // an error occurred
//             // app.ports.onGetPostResponse.send("FORCE FAIL: Note - This message will never be decoded by the decoder, and will define a Result.Err with text after attempting to decode.");
//             // err.message sent below will be intercepted in the decoder, and will be sent to the update method for additional info display for the user.
//             let msg = err.message.length === 0 ? "Unable to retrieve the application configurations details." : err.message
//             app.ports.onGetAppCfgResponse.send(msg);
//         } else {
//             console.log(data.Body.toString()); // successful response
//             app.ports.onGetAppCfgResponse.send(JSON.parse(data.Body.toString()));
//         }
//     });

// })
