'use strict';
const d3 = require('d3');

//const byband = require('byband');
import { showChartByBand } from './ByBand'; 

//console.log("d3 in analytics.js", d3);





const margin = {top: 20, right: 0, bottom: 30, left: 40};
const width = 964;
const height = 500;

const showChartByQuestion = (data) => {
    //console.log("in showChartByQuestion with ", data);

    let svg = d3.select("#sm-d3-area").select("svg");
    //console.log("svg", svg);
    
    if (svg.empty()) {
	svg = d3.select("#sm-d3-area")
	      .append("svg")
              .attr("class", "box")
	      .attr("width", "100%")
	      .attr("height", 600);
    } 

    let circles = svg.selectAll("circle")
	  .data(data.results);

    //console.log("circles", circles);

    //Remove any items (via index) that are no longer on the screen
    circles.exit().remove();

    //Update the attributes of existing (d3 circles object _groups) items
    circles.attr("r",  d => d);

    //Add in any new (via index) items
    circles.enter()
	.append("circle")
	.attr("cx", (d, i) => i * 50 + 25)
	  .attr("cy", 200)
	  .attr("r",  d => d)
	  .attr("fill", "red");

};

const createCircle = () => {
    console.log("in createCircle");

    const svg = d3.select("#sm-d3-area")
	  .append("svg")
          .attr("class", "box")
	  .attr("width", "100%")
	  .attr("height", 600);
    
    const circle = svg.append("circle")
	  .attr("cx", 200)
	  .attr("cy", 200)
	  .attr("r", 100)
	  .attr("fill", "green");

};







const setBelowElmDisplayNone = (isDisplayNone) => {
    //console.log("setBelowElmDisplayNone", isDisplayNone);
    //d3.select("#sm-d3-area").selectAll("*").remove();
    //d3.select("#below-elm").attr("display", "none");
    d3.select('#below-elm').classed('sm-display-none', isDisplayNone)
};


const subscribeAnalyticsPortFunctions = (app) => {
    //console.log("in subscribeAnalyticsPortFunctions");

    app.ports.setBelowElmDisplayNone.subscribe(setBelowElmDisplayNone);
    
    app.ports.showChartByBand.subscribe(showChartByBand);
    //app.ports.showChartByQuestion.subscribe(showChartByQuestion);
};


export { subscribeAnalyticsPortFunctions };
