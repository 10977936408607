/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const meOld = `query MeOld {
  meOLD {
    Username
    UserAttributes {
      Name
      Value
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    MFAOptions {
      DeliveryMedium
      AttributeName
    }
    PreferredMfaSetting
    UserMFASettingList
  }
}
`;
export const me = `query Me {
  me {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry {
      items {
        id
        qGId
        fTId
        tsS
        tsF
      }
      nextToken
    }
  }
}
`;
export const echo1 = `query Echo1($msg: String) {
  echo1(msg: $msg)
}
`;
export const teachers = `query Teachers {
  teachers {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry {
      items {
        id
        qGId
        fTId
        tsS
        tsF
      }
      nextToken
    }
  }
}
`;
export const students = `query Students {
  students {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry {
      items {
        id
        qGId
        fTId
        tsS
        tsF
      }
      nextToken
    }
  }
}
`;
export const studentsByTchId = `query StudentsByTchId($id: ID) {
  studentsByTchId(id: $id) {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry {
      items {
        id
        qGId
        fTId
        tsS
        tsF
      }
      nextToken
    }
  }
}
`;
export const getQuestionGroup = `query GetQuestionGroup($id: ID!, $tsF: String!) {
  getQuestionGroup(id: $id, tsF: $tsF) {
    id
    qGId
    fTId
    tsS
    tsF
    qs {
      a {
        mc
        sa
      }
      qId
      r
      isMkd
    }
  }
}
`;
export const listQuestionGroups = `query ListQuestionGroups(
  $id: ID
  $tsF: ModelStringKeyConditionInput
  $filter: ModelQuestionGroupFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listQuestionGroups(
    id: $id
    tsF: $tsF
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      qGId
      fTId
      tsS
      tsF
      qs {
        qId
        r
        isMkd
      }
    }
    nextToken
  }
}
`;
export const getEntity = `query GetEntity($id: ID!) {
  getEntity(id: $id) {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry {
      items {
        id
        qGId
        fTId
        tsS
        tsF
      }
      nextToken
    }
  }
}
`;
export const listEntitys = `query ListEntitys(
  $id: ID
  $filter: ModelEntityFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listEntitys(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sId
      hstry {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const bySId = `query BySId(
  $sId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelEntityFilterInput
  $limit: Int
  $nextToken: String
) {
  bySId(
    sId: $sId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sId
      hstry {
        nextToken
      }
    }
    nextToken
  }
}
`;
