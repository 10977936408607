/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStudent = `mutation CreateStudent(
  $input: CreateCustomEntityInput!
  $password: String!
  $sId: ID
) {
  createStudent(input: $input, password: $password, sId: $sId) {
    success {
      email
      id
    }
    failure {
      name
      message
      technicals
    }
  }
}
`;
export const createTeacher = `mutation CreateTeacher(
  $input: CreateCustomEntityInput!
  $password: String!
  $sId: ID
) {
  createTeacher(input: $input, password: $password, sId: $sId) {
    success {
      email
      id
    }
    failure {
      name
      message
      technicals
    }
  }
}
`;
export const updateLoginUser = `mutation UpdateLoginUser($id: ID!, $email: String, $password: String) {
  updateLoginUser(id: $id, email: $email, password: $password) {
    success
    failure {
      name
      message
      technicals
    }
  }
}
`;
export const updateTeacher = `mutation UpdateTeacher($input: UpdateCustomEntityInput!) {
  updateTeacher(input: $input) {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry {
      items {
        id
        qGId
        fTId
        tsS
        tsF
      }
      nextToken
    }
  }
}
`;
export const updateStudent = `mutation UpdateStudent($input: UpdateCustomEntityInput!) {
  updateStudent(input: $input) {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry {
      items {
        id
        qGId
        fTId
        tsS
        tsF
      }
      nextToken
    }
  }
}
`;
export const deleteLoginUser = `mutation DeleteLoginUser($id: ID!) {
  deleteLoginUser(id: $id) {
    success
    failure {
      name
      message
      technicals
    }
  }
}
`;
export const deleteStudentHistory = `mutation DeleteStudentHistory($id: ID!) {
  deleteStudentHistory(id: $id) {
    success
    failure {
      name
      message
      technicals
    }
  }
}
`;
export const createQuestionGroup = `mutation CreateQuestionGroup($input: CreateQuestionGroupInput!) {
  createQuestionGroup(input: $input) {
    id
    qGId
    fTId
    tsS
    tsF
    qs {
      a {
        mc
        sa
      }
      qId
      r
      isMkd
    }
  }
}
`;
export const deleteQuestionGroup = `mutation DeleteQuestionGroup($input: DeleteQuestionGroupInput!) {
  deleteQuestionGroup(input: $input) {
    id
    qGId
    fTId
    tsS
    tsF
    qs {
      a {
        mc
        sa
      }
      qId
      r
      isMkd
    }
  }
}
`;
export const createEntity = `mutation CreateEntity($input: CreateEntityInput!) {
  createEntity(input: $input) {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry {
      items {
        id
        qGId
        fTId
        tsS
        tsF
      }
      nextToken
    }
  }
}
`;
export const updateEntity = `mutation UpdateEntity($input: UpdateEntityInput!) {
  updateEntity(input: $input) {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry {
      items {
        id
        qGId
        fTId
        tsS
        tsF
      }
      nextToken
    }
  }
}
`;
export const deleteEntity = `mutation DeleteEntity($input: DeleteEntityInput!) {
  deleteEntity(input: $input) {
    id
    sId
    eData {
      sch {
        name
        isCurrentSubscriber
      }
      tch {
        firstName
        lastName
        email
        isAdmin
      }
      stu {
        firstName
        lastName
        email
        cohort
        group
        schId
      }
    }
    hstry {
      items {
        id
        qGId
        fTId
        tsS
        tsF
      }
      nextToken
    }
  }
}
`;
